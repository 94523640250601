import React from 'react';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import Api from '../../../Api';
import Slide from "@material-ui/core/Slide";
import TabsComponent from '../../../Component/TabsComponent';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import { theme } from "../../../App";

const FeaturedResource = (props) => {

    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isTall, setIsTall] = React.useState(true);
    const [organisms, setOrganisms] = React.useState([]);
    const [organismActive, setOrganismActive] = React.useState(1);
    const [formationGroups, setFormationGroups] = React.useState([]);

    React.useEffect(() => {

        setBreadcrumbs({
            title: 'Resources à la une',
            context: 'Gestion marketing',
            description: 'Apreçu apprenant.'
        });

        Api.get({
            route: 'select_organisms',
        },
            (response) => {
                if (response && response.data) {
                    setOrganisms(response.data);
                }
            });

        Api.get({
            route: 'select_formation_groups',
        },
            (response) => {
                if (response && response.data) {
                    setFormationGroups(response.data);
                }
            });


        setTimeout(() => {
            setReadyContent(true);
        }, 1500);

    }, []);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    const getTabsFormationGroups = (formationGroups) => {
        let tabs = [];
        if (formationGroups) {
            for (let i = 0; i < formationGroups.length; i++) {
                tabs.push({ id: formationGroups[i].label, label: formationGroups[i].label },)
            }
            return tabs;
        }
    };

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <br />
                    <div style={{
                        display: 'flex',
                        flexDirection: screenReducer.screen === 'XS' ? 'column' : 'row',
                        justifyContent: 'space-evenly', alignItems: 'center',
                        background: '#fff', padding: 20, borderBottom: '1px solid #bcbfc2', 
                    }}>
                        {organisms &&
                            organisms.map((organism) => (
                                <ShadowBoxComponent
                                    key={organism.value}
                                    className={screenReducer.screen === 'XS' ? classes.btnOrganismXS : classes.btnOrganism}
                                    style={{
                                        background: organismActive === organism.value ? theme.colorPrimary : '#fff',
                                        color: organismActive === organism.value ? '#fff' : '#000'
                                    }}
                                    onClick={() => { setOrganismActive(organism.value) }}>
                                    <p>{organism.label}</p>
                                </ShadowBoxComponent>
                            ))
                        }
                    </div>
                    {formationGroups &&
                        <TabsComponent
                            style={{ boxShadow: 'none' }}
                            tabDefault={'Dentiste'}
                            tabs={getTabsFormationGroups(formationGroups)}
                        >
                            {formationGroups.map((formationGroup) => (
                                <div key={formationGroup.label}>
                                    <br />
                                    <FeaturedResourceComponent
                                        organism={organismActive}
                                        formationGroup={formationGroup.value}
                                        preview={true}
                                    />
                                </div>
                            ))}
                        </TabsComponent>}
                </div>
            </Slide>
            <br />
        </ContentViewComponent>
    );
};

const useStyles = makeStyles({
    btnOrganism: {
        width: 190,
        textAlign: 'center',
        verticalAlign: 'center',
        fontSize: 14,
        cursor: 'pointer',
        borderRadius: 15,
        '&:hover': {
            opacity: 0.6
        }
    },
    btnOrganismXS: {
        padding: 0,
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'center',
        fontSize: 14,
        cursor: 'pointer',
        borderRadius: 15,
        margin: 5,
        '&:hover': {
            opacity: 0.6
        }
    }
});

export default FeaturedResource;