import React, { useEffect } from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import { inArray } from "../../../Tool/ArrayCommon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit, Tune } from "@material-ui/icons";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";
import SelectComponent from "../../../Component/SelectComponent";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { getRoutePathname } from "../../../Config/Route";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import SweetAlert from "sweetalert-react";
import { TableReload } from "../../../Action/TableAction";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch, useSelector } from "react-redux";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import { SnackbarOpen } from "../../../Action/SnackbarAction";

let VirtualClassChecked = [];
function VirtualClassroomList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionProspectStates, setOptionProspectStates] = React.useState([]);
    const [optionFormer, setOptionFormer] = React.useState([]);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "formerName",
            label: "Formateur",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAtText",
            label: "Debut de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAtText",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "meetingAtText",
            label: "Date classe virtuelle",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "registrations",
            label: "Participants",
            options: {filter: false, sort: false}
        },
        {
            name: "active",
            label: "Activer",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false, }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('content_manager_virtual_classroom', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        formationName: {
            name: 'formationName',
            label: 'Formation',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].formationName ? cacheTableServer['content_manager_virtual_classroom_list'].formationName : 'all',
            options: {}
        },
        formerName: {
            name: 'formerName',
            label: 'Formateurs',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].formerName ? cacheTableServer['content_manager_virtual_classroom_list'].formerName : 'all',
            options: {}
        },
        sessionStartAtText: {
            name: 'sessionStartAtText',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].sessionStartAtText ? cacheTableServer['content_manager_virtual_classroom_list'].sessionStartAtText : '',
            options: {}
        },
        sessionEndAtText: {
            name: 'sessionEndAtText',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].sessionEndAtText ? cacheTableServer['content_manager_virtual_classroom_list'].sessionEndAtText : '',
            options: {}
        },
        meetingAtText: {
            name: 'meetingAtText',
            label: 'Date de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].meetingAtText ? cacheTableServer['content_manager_virtual_classroom_list'].meetingAtText : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].year ? cacheTableServer['content_manager_virtual_classroom_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].financeType ? cacheTableServer['content_manager_virtual_classroom_list'].financeType : 'all',
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['content_manager_virtual_classroom_list'] = 0;
        cacheTableServer['content_manager_virtual_classroom_list'].page = 0;
        cacheTableServer['content_manager_virtual_classroom_list'][index] = value;
        dispatch(TableReload('content_manager_virtual_classroom_list'))
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des classes virtuelles',
            context: 'Gestion de contenu',
            description: ''
        });

        // Year
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        Api.get({
            route: 'select_formers'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormer(data);
                }
            });

        Api.get({
            route: 'select_formations'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormation(data);
                }
            });

        Api.get({
            route: 'select_finance_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFinanceType(data);
                }
            });

        setReadyContent(true);
    }, []);

    const save = (id, checked) => {
        Api.post({
            route: 'content_manager_virtual_classroom_active',
            params: { id: id },
            data: { active: checked }
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Classe virtuelle modifiée.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    const getActionCheck = (data, checked) => {

        return (
            <Checkbox
                key={data + '-' + new Date().getTime()}
                checked={checked}
                onChange={() => {
                    if (inArray(data, VirtualClassChecked)) {
                        let index = VirtualClassChecked.indexOf(data);
                        if (index > -1) {
                            VirtualClassChecked.splice(index, 1);
                            save(data, false)
                            dispatch(TableReload('content_manager_virtual_classroom_list'));
                        }
                    } else {
                        VirtualClassChecked.push(data);
                        save(data, true);
                        dispatch(TableReload('content_manager_virtual_classroom_list'));
                    }
                }}
            />
        );
    };
    const getDatas = (resolve, filters) => {
        console.log(filters);
        Api.get({
            route: 'content_manager_virtual_classroom_list', data: filters
        },
            (response) => {
                let data = response.data.data;
                for (let index in data) {

                    data[index].formerName = data[index].formerLastname + "  " + data[index].formerFirstname;
                    data[index].sessionStartAtText = moment(data[index].sessionStartAt).format('DD/MM/YYYY');
                    data[index].sessionEndAtText = moment(data[index].sessionEndAt).format('DD/MM/YYYY');
                    data[index].meetingAtText = moment(data[index].meetingAt).format('DD/MM/YYYY');
                    data[index].action = getAction(data[index]);

                    if (data[index].TrueFalse === true) VirtualClassChecked.push(data[index].id);
                    data[index].active = getActionCheck(data[index].id, data[index].TrueFalse);

                }
                resolve(data, response.data.count);
            });
    }

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'sessionStartAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionStartAtText', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'sessionEndAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionEndAtText', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'meetingAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('meetingAtText', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'formerName'} handler={handlerSearch} options={optionFormer} onChange={(val) => inputSearchChange('formerName', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'formationName'} handler={handlerSearch} options={optionFormation} onChange={(val) => inputSearchChange('formationName', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceType} onChange={(val) => inputSearchChange('financeType', val)} />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br />
            <TableComponent
                id={'content_manager_virtual_classroom_list'}
                title={'Classes virtuelles'}
                columns={columns}
                /*actionFirst={{
                    label: 'Ajouter une classe virtuelle',
                    onClick: () => props.history.push(getRoutePathname('content_manager_virtual_classroom', { id: null }))
                }}*/
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        formerName: (datas.formerName === 'all') ? '' : datas.formerName,
                        sessionStartAt: datas.sessionStartAtText ?? '',
                        sessionEndAt: datas.sessionEndAtText ?? '',
                        meetingAt: datas.meetingAtText ?? '',
                        formationName: (datas.formationName === 'all') ? '' : datas.formationName,
                        financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                    };
                    getDatas(resolve, filters)
                }}
                search={false}
            />
            <br />
            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={actionRow.firstname + ' ' + actionRow.lastname + '\nÊtes-vous sur de vouloir supprimer cet classe virtuelle ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_virtual_classroom_delete',
                        params: { id: actionRow.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Classe virtuelle supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_virtual_classroom_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>
    );
}
const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});
export default VirtualClassroomList;
