import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { TableReload } from "../../../Action/TableAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Edit, Delete } from "@material-ui/icons";
import SweetAlert from "sweetalert-react";
import CheckboxComponent from "../../../Component/CheckboxComponent";

export default function QuestionComment(props = {}) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    // FORM
    const [form, setForm] = React.useState({
        order: {
            name: 'order',
            label: 'Ordre',
            textHelper: 'Saisissez l\'ordre du commentaire dans la question.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            id:'',
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un Commentaire.',
            type: 'text',
            options: { validation: ['required'] },
            defaultValue: ''
        },
        active: {
            name: 'active',
            label: 'Afficher le commentaire',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {
                switchLabelRight: 'Activer',
                switchLabelLeft: 'Désactiver'
            }
        },
    });
    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);
    const save = (id) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_question_question_comment_edit',
                    data: datas,
                    params: { question: props.match.params.id, id: id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Commentaire modifiée.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('content_manager_question_question_comment_list'));
                        setDialog(false);
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'content_manager_question_question_comment_add',
                    params: { question: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Commentaire enregistré.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('content_manager_question_question_comment_list'));
                        setDialog(false);
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'order',
            label: 'Ordre',
            options: { filter: true, sort: true }
        },
        {
            name: 'comment',
            label: 'Commentaire',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: 'Date de création',
            options: { filter: true, sort: true }
        },
        {
            name: 'active',
            label: 'Afficher',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    React.useEffect(handler.start, []);

    // ACTION
    const editAction = (id) => {
        setReadyContent(false);
        if (id) {
            setIsEdition(true);
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'content_manager_question_question_comment_get',
                params: { id: id, question: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data[0]);
                        handler.form.comment.id = data[0].id;
                    } else if (response.status === 404) {
                        //props.history.push(getRoutePathname('content_manager_question_question_comment_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else {
            setIsEdition(false);
            setReadyContent(true);
        }
    }
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Édite'} placement="left">
                    <IconButton onClick={() => {
                        editAction(row.id);
                        handler.reset();
                        setDialog(true);
                        setIsEdition(true);

                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'order', direction: 'desc' }}
                id={'content_manager_question_question_comment_list'}
                title={'Question lié au Commentaire suivants : '}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un Commentaire',
                    onClick: () => {
                        handler.reset();
                        setDialog(true);
                        setIsEdition(false);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_question_question_comment_list',
                            params: { question: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    if (data[index].trueORfalse === false) { data[index].trueORfalse = 'FAUX'; }
                                    else if (data[index].trueORfalse === true) { data[index].trueORfalse = 'VRAI'; };
                                    if (data[index].active === false) { data[index].active = 'Non'; }
                                    else if (data[index].active === true) { data[index].active = 'Oui'; };
                                    data[index].createdAt = moment(data[index].createdAt).format('lll');
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog loading={!isReadyContent} open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15 }}>{isEdition ? <>Édite le commentaire</> : <>Ajouter un commentaire</>}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'order'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'comment'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CheckboxComponent name={'active'} handler={handler} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save(handler.form.comment.id)} disabled={loading} />
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette liaison ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_question_question_comment_delete',
                        params: { id: actionRow.id, question: props.match.params.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Commentaire supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_question_question_comment_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
