import React from 'react';
import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import moment from "moment";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Card from "./Component/Card";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Api from "../../../Api";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {inArray} from "../../../Tool/ArrayCommon";
import SelectComponent from "../../../Component/SelectComponent";

const useStyles = makeStyles({
    td: {width: '8%', fontSize: 12, padding: 12},
    th: {width: '8%', fontWeight: 900, fontSize: 12, padding: 12},
    thSize: {width: '8%', fontWeight: 900, fontSize: 12, padding: 12},
    up: {fontSize: 16, verticalAlign: 'bottom', color: '#008000'},
    upText: {fontSize: 11, verticalAlign: 'bottom', color: '#008000'},
    down: {fontSize: 16, verticalAlign: 'bottom', color: '#dc3545'},
    downText: {fontSize: 11, verticalAlign: 'bottom', color: '#dc3545'},
    equalText: {fontSize: 9, verticalAlign: 'middle', color: '#000'},
    textSearchSlider: { fontSize: 16, textAlign: 'center', height: 40, lineHeight: '40px', textTransform: 'capitalize'},
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    select: {
        '& select': {
            padding: 10
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    linearProgress: {
        position: 'absolute',
        top: 75,
        width: '100vw',
        height: 2,
    }

});

const ExpandableTableRow = ({children, expandComponent, ...otherProps}) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                {children}
            </TableRow>
            {isExpanded && (
                <>
                    {expandComponent}
                </>
            )}
        </>
    );
};

function Reporting(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [organism, setOrganism] = React.useState(0);
    const [financeType, setFinanceType] = React.useState(0);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFinanceTypes, setOptionFinanceTypes] = React.useState([]);
    const [textSearchSlider, setTextSearchSlider] = React.useState(moment().year());
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [numberRegistrations, setNumberRegistrations] = React.useState({});
    const [startAt, setStartAt] = React.useState(moment());

    const formationGroups = [
        {index: 'med', text: 'Médecin'},
        {index: 'inf', text: 'Infirmière'},
        {index: 'dent', text: 'Dentiste'},
        {index: 'kin', text: 'Kinésithérpeute'},
        {index: 'orth', text: 'Orthophoniste'},
        {index: 'diver', text: 'Divers'},
    ];

    const changeSearchSlider = (direction = null) => {
        let newStartAt;

        if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
        else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
        else newStartAt = startAt;

        newStartAt = moment(newStartAt.startOf('year').format());

        setTextSearchSlider(newStartAt.format('YYYY'));

        setStartAt(newStartAt);
    };

    const getInfo = () => {
        if (startAt) {
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                    route: 'crm_reporting_info',
                    data: {year: startAt.format('YYYY'), 
                    organism: (organism === 'all') ? '' : organism , 
                    financeType: (financeType === 'all') ? '' : financeType}
                },
                (response) => {
                    if (response && response.data) {
                        setNumberRegistrations(response.data.numberRegistrations ? response.data.numberRegistrations : {});
                    }
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());
                });
        }
    };

    React.useEffect(() => {
        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                let data = [];
                if (response && response.data) {
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });
        Api.get({
                route: 'select_finance_types'
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        if (inArray(parseInt(response.data[index].value), authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionFinanceTypes(data);
                }
            });

    }, []);
    React.useEffect(changeSearchSlider, []);
    React.useEffect(getInfo, [startAt, organism, financeType]);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Reporting commercial',
            context: 'CRM',
            description: 'Statistiques.'
        });
    }, []);


    const renderPole = () => {
        return Object.keys(numberRegistrations).map((id, index) => {
            return <Zoom in={isReadyContent}>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Card
                        title={numberRegistrations[id].name}
                        number={numberRegistrations[id].total}
                        percentage={numberRegistrations[id].totalPercentage}
                        turnover={numberRegistrations[id].total}
                        color={'#000000'}
                        label={'Nombre d\'inscriptions cette année'}
                        labelEmpty={'Aucun inscription cette année'}
                    />
                </Grid>
            </Zoom>;
        })
    }
    const renderGroup = (id, idUser) => {
        return (
            <>
                {formationGroups.map((formationGroup, index) => (
                    <TableRow key={index}>
                        <TableCell padding="checkbox"/>
                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['01'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['02'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['03'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['04'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['05'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['06'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['07'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['08'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['09'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['10'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['11'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['12'][formationGroup['index']]}</TableCell>
                    </TableRow>
                ))}
            </>
        )
    }
    const renderTable = Object.keys(numberRegistrations).map((id, index) => {
        let renderTableRow = Object.keys(numberRegistrations[id]['users']).map((idUser) => {
            let RenderCell = Object.keys(numberRegistrations[id]['users'][idUser]['year']).sort((a, b) => a < b ? -1 : 1).map((idMonth) => {
                return (
                    <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year'][idMonth]['total']}</TableCell>
                )
            });

            return (
                <>
                    <ExpandableTableRow
                        key={index}
                        expandComponent={renderGroup(id, idUser)}
                    >
                        <TableCell className={classes.thSize}>{numberRegistrations[id]['users'][idUser]['name']}</TableCell>
                        {RenderCell}
                    </ExpandableTableRow>

                </>
            );
        });

        return (
            <>
                <ShadowBoxComponent>
                    <TitleComponent title={numberRegistrations[id].name + ' Nombre d\'inscriptions par commercial'}/>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox"/>
                                    <TableCell className={classes.thSize}>Commercial</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderTableRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>
                <br/>
            </>
        );
    });

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl variant="outlined" style={{width: '100%'}}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Organisme</InputLabel>
                                    <Select
                                        native
                                        value={organism}
                                        onChange={(event) => setOrganism(event.target.value)}
                                        label="Organisme"
                                        inputProps={{name: 'Organisme', id: 'outlined-age-native-simple'}}
                                        className={classes.select}
                                    >
                                        <option value={'all'}>Tous</option>
                                        {
                                            optionOrganisms && optionOrganisms.map((organism) => {
                                                return <option key={organism.value} value={organism.value}>{organism.label}</option>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl variant="outlined" style={{width: '100%'}}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Type de financement</InputLabel>
                                    <Select
                                        native
                                        value={financeType}
                                        onChange={(event) => setFinanceType(event.target.value)}
                                        label="Type de financement"
                                        inputProps={{name: 'Type de financement', id: 'outlined-age-native-simple'}}
                                        className={classes.select}
                                    >
                                        <option value={'all'}>Tous</option>
                                        {
                                            optionFinanceTypes && optionFinanceTypes.map((financeType) => {
                                                return <option key={financeType.value} value={financeType.value}>{financeType.label}</option>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} style={{position: 'relative'}}>
                                <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')}/>
                                <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                                <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')}/>
                            </Grid>

                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>
            <br/>

            {(numberRegistrations) && <>
                <Grid container spacing={3}>
                    {renderPole()}
                </Grid>

                <br/>

                {renderTable}
            </>}

        </ContentViewComponent>
    );
}

export default Reporting;
