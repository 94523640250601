import React from 'react';
import {makeStyles} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import UseAnimations from "react-useanimations";
import menu from "react-useanimations/lib/menu";
import LinearProgress from "./LinearProgress";
import {AppBar as AppBarMUI} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {DrawerMainLayoutClose, DrawerMainLayoutOpen} from "../../../Action/DrawerMainLayoutAction";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Api from "../../../Api";
import {AuthenticationLogout} from "../../../Action/AuthenticationAction";
import {theme} from "../../../App";

export default function AppBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const drawerMainLayoutReducer = useSelector(state => state.DrawerMainLayoutReducer);

    const changeDrawer = (isOpenDrawer) => {
        if (isOpenDrawer) dispatch(DrawerMainLayoutOpen());
        else dispatch(DrawerMainLayoutClose());
    };
    const logout = () => {
        dispatch(AuthenticationLogout());
        Api.get({route: 'authentication_logout'}, () => {});
    };

    return (
        <AppBarMUI position="fixed" className={`${classes.appBar}`}>
            <Toolbar>

                <UseAnimations
                    className={classes.appBarButton}
                    animation={menu}
                    size={27}
                    onClick={() => changeDrawer(!drawerMainLayoutReducer.isOpen)}
                    reverse={!drawerMainLayoutReducer.isOpen}
                />

                <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.appBarLogo}/>

                <Tooltip title="Déconnexion" aria-label="Déconnexion">
                    <IconButton component="span" className={classes.appBarLogout} onClick={logout}>
                        <PowerSettingsNewIcon className={classes.appBarLogoutIcon}/>
                    </IconButton>
                </Tooltip>

            </Toolbar>
            <LinearProgress/>
        </AppBarMUI>
    );
}

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: 2,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.5)',
        height: 58,
        '& .MuiToolbar-regular': {
            height: 58,
            minHeight: '58px !important',
            paddingLeft: 23,
            paddingRight: 23,
        }
    },
    appBarButton: {
        marginRight: 24,
        padding: 0,
        cursor: 'pointer'
    },
    appBarLogo: {
        height: 35,
        paddingLeft: 26,
        float: 'right'
    },
    appBarLogout: {
        right: 0,
        top: 0,
        position: 'absolute',
        padding: 10,
        margin: 8,
        marginRight: 12,
        color: '#5E6E82'
    },
    appBarLogoutIcon: {
        fontSize: 22
    }
}));
