import React from 'react';
import {Breadcrumbs as BreadcrumbsMUI, makeStyles} from "@material-ui/core";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import ShadowBoxComponent from "./ShadowBoxComponent";
import {Home, NavigateNext} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {useSelector} from "react-redux";
import {getPathDefault} from "../Config/Route";
import Fade from "@material-ui/core/Fade";
import {theme} from "../App";

function ContentViewComponent(props = {
    loading: false,
    breadcrumbs: {title: '', context: '', description: '', links: []}
}) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <div className={`${classes.contentView} ${isSmallView && classes.contentViewSmall}`} style={isSmallView ? {padding: '0 10px'} : {}}>
            {
                props.loading ?
                    (
                        <Fade in={true} {...{timeout: 1000}}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35}/>
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                    )
                    :
                    <>
                        <div className={`${classes.breadcrumbs} ${isSmallView && classes.breadcrumbsSmall}`}>
                            <BreadcrumbsMUI separator={<NavigateNext className={'icon-bc'} style={{margin: 0, cursor: 'default'}}/>} aria-label="breadcrumb">
                                <Typography>
                                    <Home className={'icon-bc'} onClick={() => props.history.push(getPathDefault())}/>
                                    {props.breadcrumbs.context}
                                </Typography>
                                {
                                    props.breadcrumbs.links && props.breadcrumbs.links.map((link, key) => {
                                        return (
                                            <Typography key={key} onClick={() => props.history.push(link.path)} style={{cursor: 'pointer'}}>
                                                {link.label}
                                            </Typography>
                                        )
                                    })
                                }
                            </BreadcrumbsMUI>
                            <h2>{props.breadcrumbs.title}</h2>
                            <p className={'description'}>{props.breadcrumbs.description}</p>
                        </div>
                        {props.children}
                    </>
            }
        </div>
    );
}

const useStyles = makeStyles({
    contentView: {
        padding: '0 25px',
        position: 'relative'
    },
    contentViewSmall: {
        padding: '0 5px',
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    breadcrumbs: {
        color: '#5E6E82',
        width: '100%',
        padding: '15px 40px',
        height: 105,
        boxSizing: 'border-box',
        '& h2': {
            margin: 0,
            fontSize: 22,
            fontWeight: 500
        },
        '& p': {
            margin: 0,
            marginTop: 3,
            fontSize: 13,
            fontWeight: 300
        },
        '& .icon-bc': {
            color: '#5E6E82',
            fontSize: 18,
            verticalAlign: 'sub',
            fontWeight: 400,
            margin: 0,
            marginRight: 10,
            cursor: 'pointer'
        },
        '& .MuiBreadcrumbs-root': {
            color: '#5E6E82',
            marginLeft: -30,
            marginBottom: 8
        },
        '& svg': {
            color: "#5E6E82",
            fontSize: 18
        }
    },
    breadcrumbsSmall: {
        padding: '10px 45px',
        height: 82,
        '& h2': {
            margin: 0,
            fontSize: 16,
            fontWeight: 500
        },
        '& p': {
            margin: 0,
            marginTop: 3,
            fontSize: 10,
            fontWeight: 300
        },
        '& .icon-bc': {
            color: '#5E6E82',
            fontSize: 16,
            verticalAlign: 'sub',
            fontWeight: 400,
            margin: 0,
            marginRight: 10,
            cursor: 'pointer'
        },
        '& .MuiBreadcrumbs-root': {
            color: '#5E6E82',
            marginLeft: -30,
            marginBottom: 8
        },
        '& svg': {
            color: "#5E6E82",
            fontSize: 18
        }
    }
});

export default ContentViewComponent;
