import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import {TableReload} from "../../../Action/TableAction";
import {cacheTablePage, cacheTableServer} from "../../../Cache";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {inArray} from "../../../Tool/ArrayCommon";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import {Info, PhoneForwarded, Mail, CancelScheduleSend} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ButtonComponent from "../../../Component/ButtonComponent";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import DateTimePickerComponent from "../../../Component/DateTimePickerComponent";
import {hasRight} from "../../../Tool/UserTool";
import Button from "@material-ui/core/Button";

function LinearProgressWithLabel(props) {

    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        }
        else if (value >= 33 && value < 66) {
            return '#db770a';
        }
        else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': {backgroundColor: getColor(props.value)}
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function RelaunchList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionFinanceTypes, setFinanceTypes] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionRegistrationOrigins, setOptionRegistrationOrigins] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);
    const [idEdit, setIdEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loadDownload, setLoadDownload] = React.useState(false);

    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "groupFormationName",
            label: "Groupe de formation",
            options: {filter: false, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "phone",
            label: "Numéro de téléphone",
            options: {filter: true, sort: true}
        },
        {
            name: "formationName",
            label: "Formation",
            options: {filter: true, sort: true}
        },
        {
            name: "commercialName",
            label: "Commercial",
            options: {filter: true, sort: true}
        },
        {
            name: "financeTypeName",
            label: "Type de financement",
            options: {filter: true, sort: true}
        },
        {
            name: "stateText",
            label: "État",
            options: {filter: false, sort: false}
        },
        {
            name: "stateRule",
            label: "État régule",
            options: {filter: false, sort: false}
        },
        {
            name: "lastRelaunchAt",
            label: "Relance",
            options: {filter: true, sort: true}
        },
        {
            name: "completion",
            label: "Complétion",
            options: {filter: true, sort: true}
        },
        {
            name: "origin",
            label: "Origine de la registration",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <div style={{minWidth: 90}}>
                <Tooltip title={'Relance téléphonique'} placement="left">
                    <IconButton onClick={() => {
                        handler.reset();
                        handler.setValue('relaunchAt', moment())
                        setIdEdit(row.id);
                    }}>
                        <PhoneForwarded/>
                    </IconButton>
                </Tooltip>
                {
                    (row.state === 1 || row.state === 2 || row.state === 3 || row.state === 4 || row.state === 7) ?
                        <Tooltip title={'Mail relance désactiver'} placement="left">
                            <IconButton>
                                <CancelScheduleSend/>
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title={'Mail relance'} placement="left">
                            <IconButton onClick={() => {
                                dispatch(LinearProgressMainLayoutActivate());
                                Api.post({
                                        route: 'crm_relaunch_registration_mail',
                                        params: {id: row.id}
                                    },
                                    (response) => {
                                        dispatch(LinearProgressMainLayoutDeactivate());
                                        dispatch(TableReload('crm_relaunch_registration_list'));
                                        handler.setFormLoading(false);
                                        setLoading(false);
                                        if (response.status === 200) {
                                            dispatch(
                                                SnackbarOpen({
                                                    text: 'Mail Envoyé.',
                                                    variant: 'success',
                                                })
                                            );
                                        }
                                        else {
                                            dispatch(
                                                SnackbarOpen({
                                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                    variant: 'error',
                                                })
                                            );
                                        }
                                    });
                            }}>
                                <Mail/>
                            </IconButton>
                        </Tooltip>
                }
            </div>
        );
    };

    // Form
    const [form, setForm] = React.useState({
        relaunchAt: {
            name: 'relaunchAt',
            label: 'Date de la relance',
            textHelper: 'Choisissez une date de la relance.',
            type: 'datetime',
            defaultValue: '',
            options: {validation: ['required']}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Ajouter un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            let data = handler.getData();
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            Api.post({
                    route: 'crm_relaunch_registration_phone',
                    data: {relaunchAt: data['relaunchAt'], comment: data['comment']},
                    params: {id: idEdit}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('crm_relaunch_registration_list'));
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        setIdEdit(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Relance enregistré.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].commercial ? cacheTableServer['crm_prospect_list'].commercial : 'all',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].organism ? cacheTableServer['crm_relaunch_registration_list'].organism : 'all',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].formationGroup ? cacheTableServer['crm_relaunch_registration_list'].formationGroup : 'all',
            options: {}
        },
        pole: {
            name: 'pole',
            label: 'Pôle',
            textHelper: 'Trier par pôle.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].pole ? cacheTableServer['crm_prospect_list'].pole : 'all',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].startAt ? cacheTableServer['crm_relaunch_registration_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].endAt ? cacheTableServer['crm_relaunch_registration_list'].endAt : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].year ? cacheTableServer['crm_relaunch_registration_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].financeType ? cacheTableServer['crm_relaunch_registration_list'].financeType : 'all',
            options: {}
        },
        andpc: {
            name: 'andpc',
            label: 'Code andpc',
            textHelper: 'Saisissez un code andpc.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].andpc ? cacheTableServer['crm_relaunch_registration_list'].andpc : '',
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez un email.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].email ? cacheTableServer['crm_relaunch_registration_list'].email : '',
            options: {}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez un prénom.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].firstname ? cacheTableServer['crm_relaunch_registration_list'].firstname : '',
            options: {}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez un nom.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].lastname ? cacheTableServer['crm_relaunch_registration_list'].lastname : '',
            options: {}
        },
        origin: {
            name: 'origin',
            label: 'Origine',
            textHelper: 'Trier par origine.',
            type: 'text',
            defaultValue: cacheTableServer['crm_relaunch_registration_list'] && cacheTableServer['crm_relaunch_registration_list'].origin ? cacheTableServer['crm_relaunch_registration_list'].origin : 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['crm_relaunch_registration_list'] = 0;
        cacheTableServer['crm_relaunch_registration_list'].page = 0;
        cacheTableServer['crm_relaunch_registration_list'][index] = value;
        dispatch(TableReload('crm_relaunch_registration_list'))
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Inscriptions',
            context: 'Suivi des relances',
            description: 'Liste des inscriptions.'
        });

        // Year
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({label: i, value: i});
        }
        setOptionYears(yearsTMP);

        Api.get({
                route: 'crm_commercial_list'
            },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([...[{value: 'all', label: 'Tous'}, {value: 0, label: 'Aucun'}], ...response.data]);
                }
            });
        Api.get({
                route: 'select_poles'
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionPoles(data);
                }
            });
        Api.get({
                route: 'select_finance_types',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(parseInt(response.data[index].value), authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setFinanceTypes(data);
                }
            });
        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });

        Api.get({
                route: 'select_formation_groups'
            },
            (response) => {
                if (response && response.data) {
                    let data = [{value: 'all', label: 'Tous'}, ...response.data];
                    setOptionFormationGroup(data);
                }
            });

            Api.get({
                route: 'select_registration_origins'
            },
            (response) => {
                if (response && response.data) {
                    let data = [{value: 'all', label: 'Tous'}, ...response.data];
                    setOptionRegistrationOrigins(data);
                }
            });

        setReadyContent(true);
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextFieldComponent name={'andpc'} handler={handlerSearch} onChange={(val) => inputSearchChange('andpc', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceTypes} onChange={(val) => inputSearchChange('financeType', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <SelectComponent name={'pole'} handler={handlerSearch} options={optionPoles} onChange={(val) => inputSearchChange('pole', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <SelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercials} onChange={(val) => inputSearchChange('commercial', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextFieldComponent name={'email'} handler={handlerSearch} onChange={(val) => inputSearchChange('email', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextFieldComponent name={'firstname'} handler={handlerSearch} onChange={(val) => inputSearchChange('firstname', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextFieldComponent name={'lastname'} handler={handlerSearch} onChange={(val) => inputSearchChange('lastname', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <SelectComponent name={'origin'} handler={handlerSearch} options={optionRegistrationOrigins} onChange={(val) => inputSearchChange('origin', val)}/>
                            </Grid>
                        </Grid>
                        <div style={isTall ? {height: 35, marginTop: 10} : {marginTop: 10}}>
                            {(hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_ADMIN')) && <Button
                                variant="contained"
                                disabled={loadDownload}
                                color="primary"
                                style={
                                    isTall ?
                                        {float: 'right', marginLeft: 10, fontSize: 12, opacity: 0.9} :
                                        {width: 'calc(100% - 20px)', marginLeft: 10, fontSize: 12, opacity: 0.9, marginTop: 10}
                                } onClick={() => {
                                let data = handlerSearch.getData();
                                setLoadDownload(true);
                                Api.getDownload({
                                    route: 'crm_relaunch_registration_list', filename: 'export_liste_relance.xls', data: {
                                        andpc: data.andpc ? data.andpc : '',
                                        commercial: (data.commercial === 'all' || !data.commercial) ? '' : data.commercial,
                                        email: data.email ? data.email : '',
                                        firstname: data.firstname ?? '',
                                        lastname: data.lastname ?? '',
                                        endAt: data.endAt ? data.endAt : '',
                                        formationGroup: (data.formationGroup === 'all') ? '' : data.formationGroup,
                                        organism: (data.organism === 'all') ? '' : data.organism,
                                        pole: (data.pole === 'all') ? '' : data.pole,
                                        financeType: (data.financeType === 'all') ? '' : data.financeType,
                                        startAt: data.startAt ? data.startAt : '',
                                        year: data.year ? data.year : 2022,
                                        origin: (data.origin === 'all') ? '' : data.origin,
                                    }
                                }, (response) => {
                                    if (response.status === 200) {
                                        setLoadDownload(false)
                                    }
                                })
                            }}>
                                Télécharger au format Excel
                            </Button>}
                        </div>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br/>

            <TableComponent
                id={'crm_relaunch_registration_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Inscriptions'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();

                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        startAt: datas.startAt ?? '',
                        andpc: datas.andpc ?? '',
                        endAt: datas.endAt ?? '',
                        commercial: (datas.commercial === 'all' || !datas.commercial) ? '' : datas.commercial,
                        pole: (formSearch.pole.value === 'all') ? '' : formSearch.pole.value,
                        organism: (datas.organism === 'all') ? '' : datas.organism,
                        formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                        financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                        email: datas.email ?? '',
                        firstname: datas.firstname ?? '',
                        lastname: datas.lastname ?? '',
                        origin: (datas.origin === 'all') ? '' : datas.origin,
                    };
                    Api.get({
                            route: 'crm_relaunch_registration_list',
                            data: filters
                        },
                        (response) => { // TODO LIST RELAUNCH
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].completion = <div style={{width: 150}}><LinearProgressWithLabel value={data[index].completion}/></div>;
                                data[index].lastRelaunchAt = data[index].relaunchs.length > 0 ? <div style={{width: 140}}>
                                    <Tooltip title={
                                        <span>{data[index].relaunchs.map((relaunch) =>
                                            <>
                                                <p>{relaunch.by} : Relance {relaunch.type} le {moment(relaunch.date).format('lll')}</p>
                                                {relaunch.comment && <p>Commentaire : {relaunch.comment}</p>}
                                            </>)}
                                        </span>} placement="left">
                                        <Info style={{display: 'inline-block', verticalAlign: 'bottom', margin: '0 5px', color: '#bfc5cb'}}/>
                                    </Tooltip>
                                    <span>{data[index].lastRelaunchAt ? moment(data[index].lastRelaunchAt).format('lll') : ''}</span>
                                </div> : '-';
                                switch (data[index]['state']) {
                                    case 1:
                                        data[index]['stateText'] = <span style={{color: '#28A745'}}>Validée</span>;
                                        break;
                                    case 2:
                                        data[index]['stateText'] = <span style={{color: '#AD732C'}}>Annulée</span>;
                                        break;
                                    case 3:
                                        data[index]['stateText'] = <span style={{color: '#DC3545'}}>Supprimée</span>;
                                        break;
                                    case 4:
                                        data[index]['stateText'] = <span style={{color: '#35A2EB'}}>Facturée</span>;
                                        break;
                                    case 5:
                                        data[index]['stateText'] = <span style={{color: '#000000'}}>Non terminée</span>;
                                        break;
                                    case 6:
                                        data[index]['stateText'] = <span style={{color: '#67327b'}}>Temps manquant</span>;
                                        break;
                                    case 7:
                                        data[index]['stateText'] = <span style={{color: '#000000'}}>Pré-inscription</span>;
                                        break;
                                }
                                data[index].action = getAction(data[index]);
                            }

                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br/>

            <Dialog open={idEdit}>
                <DialogTitle style={{fontSize: 15}}>Inscription - {idEdit}</DialogTitle>
                <DialogContent>
                    <div>
                        <DateTimePickerComponent name={'relaunchAt'} handler={handler}/>
                        <TextFieldComponent name={'comment'} handler={handler} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setIdEdit(null)} color={'#5E6E82'} label={'Annuler'}/>
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});

export default RelaunchList;
