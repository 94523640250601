import React from 'react';
import {makeStyles} from "@material-ui/core";

function ShadowBoxComponent(props = {className: '', style: {}}) {
    const classes = useStyles();

    return (
        <div
            className={`${classes.content} ${props.disabled ? classes.disabled : ''} ${props.className ? props.className : ''}`}
            style={props.style}
            onMouseEnter={props.onMouseEnter && props.onMouseEnter}
            onMouseLeave={props.onMouseLeave && props.onMouseLeave}
            onClick={props.onClick && props.onClick}
        >
            {props.children}
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
        margin: 0,
        padding: 12,
        boxSizing: 'border-box',
        position: 'relative',
        color: '#5E6E82',
        borderRadius: 5
    },
    disabled: {
        boxShadow: 'inset 0px 0px 30px 0px rgba(0,0,0,0.25);',
    }
});

export default ShadowBoxComponent;
