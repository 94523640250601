import {makeStyles} from '@material-ui/core/styles';
import React from 'react'
import {Slide} from '@material-ui/core';
import {theme} from '../App';
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import ButtonComponent from "./ButtonComponent";

export default function EventBoxComponent(props = {
    title: null,
    content: null,
    open: false,
    labelButton: null,
    onClickButton: null,
    icon: null
}) {
    const classes = useStyles(theme)();
    const [checked, setChecked] = React.useState(props.open);

    const handleQuit = () => {
        setChecked((prev) => !prev);
    };

    return (
        <Slide in={checked} direction='up' {...{timeout: 1000}}>
            <div className={classes.box}>
                <IconButton aria-label="quit" size='small' style={{position: 'absolute', right: 5, top: 5}} onClick={handleQuit}>
                    <ClearIcon fontSize='small'/>
                </IconButton>

                <div className={classes.title}>{props.title && props.title}</div>

                {props.icon && <div className={classes.icon}>{props.icon}</div>}

                <div className={classes.content}>
                    {props.content && props.content}
                    {props.labelButton &&
                    <ButtonComponent
                        style={{margin: 10, backgroundColor: theme.colorPrimary, fontSize: 11, fontWeight: 600}}
                        onClick={props.onClickButton}
                        label={props.labelButton}
                    />}
                </div>

                <div className={classes.footer}>Acceder à notre site web <br/><a target='_blank' href={theme.website}>{theme.website}</a></div>
            </div>
        </Slide>
    )
}


const useStyles = (theme) => makeStyles(({
    box: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        zIndex: 10,
        width: 275,
        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
    },
    title: {
        fontSize: 13,
        color: '#000',
        fontWeight: 700,
        textAlign: 'center',
        backgroundColor: '#FFF',
        padding: 10,
        zIndex: 12,
    },
    icon: {
        backgroundColor: '#FFF',
        textAlign: 'center',
        padding: '0 10px 10px',
        '& > svg': {
            fontSize: 45,
            color: theme.colorPrimary
        },
        zIndex: 11,
    },
    content: {
        fontSize: 12,
        color: '#000',
        textAlign: 'center',
        backgroundColor: '#F6F6F7',
        padding: 5,
        zIndex: 10,
    },
    footer: {
        fontSize: 10,
        color: '#000',
        textAlign: 'center',
        backgroundColor: '#FFF',
        padding: 10
    }
}));







