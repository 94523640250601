import React from 'react';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import {useDispatch, useSelector} from "react-redux";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {makeStyles} from '@material-ui/core/styles';
import {getRoutePathname} from "../../../Config/Route";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {theme} from "../../../App";
import MultipleCheckboxComponent from "../../../Component/MultipleCheckboxComponent";
import {cloneJson} from "../../../Tool/CloneCommon";
import TextFieldComponent from "../../../Component/TextFieldComponent";

function RegistrationSurveyPrePost(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isCurrentSurveyCompleted, setCurrentSurveyCompleted] = React.useState(false);
    const [isPreSurveyCompleted, setPreSurveyCompleted] = React.useState(false);
    const [currentForm, setCurrentForm] = React.useState();
    const [prevForm, setPrevForm] = React.useState();
    const [responses, setResponses] = React.useState();

    const goBack = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
        }, 600);
    };
    const handler = formHandlerInit(currentForm, setCurrentForm);
    const prevHandler = formHandlerInit(prevForm, setPrevForm);


    React.useEffect(handler.start, []);
    React.useEffect(prevHandler.start, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            let data = handler.getData();

            Api.post({
                route: 'learner_registration_survey_pre_post_add',
                data: {"response" : data, "type": props.match.params.type},
                params: {registration: props.match.params.registration}
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'L\'enregistrement du questionnaire a été un succès.',
                            variant: 'success',
                        })
                    );
                    setCurrentSurveyCompleted(true);
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
                route: 'learner_registration_survey_pre_post_get',
                params: {registration: props.match.params.registration, type: props.match.params.type}
            },
            (response) => {
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let dataReceive = response.data;
                    let data = cloneJson(dataReceive);
                    let dataClone = cloneJson(dataReceive);
                    setCurrentForm(data.questions);
                    setPrevForm(dataClone.questions);
                    setPreSurveyCompleted(data.isPreSurveyCompleted)
                    setCurrentSurveyCompleted(data.isCurrentSurveyCompleted);
                    setResponses(data.responses)

                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }, []);


    React.useEffect(() => {
        if (!loading) {
            handler.setDataApi(getResponsesByType(props.match.params.type, responses));
            prevHandler.setDataApi(getResponsesByType("pre", responses));
        }
    }, [responses]);

    const getResponsesByType = (type, responses) => {
        for (let index in responses) {
            if (responses[index].type === type) {
                return responses[index]['responses'];
            }
        }
    }

    return (
        <div style={{textAlign: 'center', maxWidth: 1040, margin: 'auto'}}>
            {
                loading ?
                    <Fade in={true} {...{timeout: 1000}}>
                        <div className={classes.loader}>
                            <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                <ShadowBoxComponent>
                                    <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                    <div className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={35}/>
                                    </div>
                                </ShadowBoxComponent>
                            </div>
                        </div>
                    </Fade>
                    :
                    <div>
                        <Fade in={show} {...{timeout: 1000}}>
                            <div>
                                <ShadowBoxComponent className={classes.shadowBoxBack} onClick={goBack} style={isSmallView ? {width: 'calc(100% - 30px)', marginLeft: 15} : {}}>
                                    <ArrowLeftIcon className={classes.shadowBoxBackIcon}/>
                                    <span className={classes.shadowBoxBackText}>Revenir au sommaire</span>
                                </ShadowBoxComponent>
                                <ShadowBoxComponent className={classes.shadowBox}>
                                    {
                                        isPreSurveyCompleted && isCurrentSurveyCompleted ?
                                            <>
                                                <TitleComponent title={'Synthèse questionnaire-pre / questionnaire-post'}/>
                                                <p style={{backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px'}}>Ce questionnaire d’évaluation des connaissances a pour objectif de mesurer l’efficacité de la formation ainsi que votre progression.<br/> <b>À titre d'information, les réponses exactes seront surlignées en vert.</b>
                                                </p>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        {
                                                            Object.keys(prevHandler.form).map((id, index) => (
                                                                <Grid key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px'}}>
                                                                    {
                                                                        prevHandler.form[id].component === 'checkBox' ?
                                                                            <MultipleCheckboxComponent key={id} name={prevHandler.form[id].name} options={prevHandler.form[id].optionsCheckBox} handler={prevHandler} hasFeedback={true} disabled={isCurrentSurveyCompleted}/>
                                                                            : prevHandler.form[id].component === 'textField' ? <TextFieldComponent key={id} name={prevHandler.form[id].name} multiline={true} nullable={true} disabled={isCurrentSurveyCompleted} handler={prevHandler}/> :
                                                                                ""
                                                                    }
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid >
                                                    <Grid item xs={6}>
                                                        {
                                                            Object.keys(handler.form).map((id, index) => (
                                                                <Grid key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px'}}>
                                                                    {
                                                                        handler.form[id].component === 'checkBox' ?
                                                                            <MultipleCheckboxComponent key={id} name={handler.form[id].name} options={handler.form[id].optionsCheckBox} handler={handler} hasFeedback={true} disabled={isCurrentSurveyCompleted}/>
                                                                            : handler.form[id].component === 'textField' ? <TextFieldComponent key={id} name={handler.form[id].name} multiline={true} nullable={true} disabled={isCurrentSurveyCompleted} handler={handler}/> :
                                                                                ""
                                                                    }
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid >
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <TitleComponent title={'Questionnaire-' + props.match.params.type + ' formation'}/>
                                                <p style={{backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px'}}>Ce questionnaire d’évaluation des connaissances a pour objectif de mesurer l’efficacité de la formation ainsi que votre progression.
                                                    {
                                                        props.match.params.type === 'pre' && <span>le même questionnaire vous sera soumis en fin de formation pour vous permettre de comparer vos réponses.</span>
                                                    }
                                                </p>
                                                {
                                                    isCurrentSurveyCompleted && <p style={{textAlign: 'center'}}><b>Vous avez déjà répondu à ce questionnaire.</b><br/><b>Vous pouvez uniquement le consulter à nouveau.</b></p>
                                                }
                                                <Grid container>
                                                    {
                                                        Object.keys(handler.form).map((id, index) => (
                                                            <Grid key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px'}}>
                                                                {

                                                                    handler.form[id].component === 'checkBox' ?
                                                                        <MultipleCheckboxComponent  key={id} name={handler.form[id].name} options={handler.form[id].optionsCheckBox} handler={handler} disabled={isCurrentSurveyCompleted}/>
                                                                        : handler.form[id].component === 'textField' ? <TextFieldComponent  name={handler.form[id].name} multiline={true} nullable={true} disabled={isCurrentSurveyCompleted} handler={handler}/> :
                                                                            ""
                                                                }
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid >
                                            </>
                                    }
                                    <div className={classes.buttonGroup}>
                                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={isCurrentSurveyCompleted}/>
                                    </div>
                                </ShadowBoxComponent>
                            </div>
                        </Fade>
                    </div>
            }
        </div>
    );
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
            {
                margin: 'auto',
                width: '15% !important'
            }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    button: {
        float: 'right'
    },
});

export default RegistrationSurveyPrePost;
