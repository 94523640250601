import {makeStyles} from '@material-ui/core/styles';
import React from 'react'
import MUIDataTable from "mui-datatables";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Refresh} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import Fade from "@material-ui/core/Fade";
import {cacheTableData, cacheTableFilter, cacheTablePage, cacheTableServer, cacheTableOrderBy, cacheTableRowsPerPage} from "../Cache";
import ButtonComponent from "./ButtonComponent";
import {theme} from "../App";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../Action/LinearProgressMainLayoutAction";

export default function TableComponent(props = {
    title: '',
    id: '',
    columns: [],
    promiseData: null,
    promiseServerData: null,
    height: '',
    noCache: false,
    actionFirst: {},
    actionSecond: {},
    actionThird: {},
    sortOrder: {},
    actionSelection: {},
    onBeforeRefresh: false,
    search: true
}) {
    const dispatch = useDispatch();
    const classes = useStyles(theme, props.height, props.minHeight, props.small)();
    const reloadTable = useSelector(state => state.TableReducer);

    const [data, setData] = React.useState(cacheTableData[props.id] ?? []);
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    const loadData = () => {
        if (props.promiseData) {
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            props.promiseData((data) => {
                setData([...data]);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (!props.noCache) cacheTableData[props.id] = [...data];
            });
        }
        else if (props.promiseServerData) {
            onTable('reload');
        }
    };
    const onTable = (action = '', tableState = {}) => {
        if (props.promiseServerData) {
            if (action === 'tableInitialized') {
                if (!cacheTableServer[props.id]) {
                    cacheTableServer[props.id] = {
                        rowsPerPage: tableState.rowsPerPage,
                        page: tableState.page,
                        searchText: '',
                        sortOrder: tableState.sortOrder ? {
                            name: tableState.sortOrder.name,
                            direction: tableState.sortOrder.direction
                        } : {},
                    };
                }
                else {
                    cacheTableServer[props.id].searchText = '';
                }
            }

            if (
                action === 'reload' ||
                action === 'search' ||
                action === 'sort' ||
                action === 'filterChange' ||
                action === 'changePage' ||
                action === 'changeRowsPerPage'
            ) {

                if (action !== 'reload') {
                    cacheTableServer[props.id]['rowsPerPage'] = tableState.rowsPerPage;
                    cacheTableServer[props.id]['page'] = tableState.page;
                    cacheTableServer[props.id]['searchText'] = tableState.searchText;
                    cacheTableServer[props.id]['sortOrder'] = tableState.sortOrder ? {
                        name: tableState.sortOrder.name,
                        direction: tableState.sortOrder.direction
                    } : {};
                }

                setLoading(true);
                dispatch(LinearProgressMainLayoutActivate());
                props.promiseServerData((data, count) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    setData([...data]);
                    setCount(count);
                }, cacheTableServer[props.id]);
            }
        }
    };

    const options = {
        responsive: 'vertical',

        // Action
        download: false,
        search: props.search !== false,
        filter: false,
        print: false,
        customToolbar: () => {
            return (
                <>
                    <Tooltip title={"Recharger la liste"}>
                        <IconButton onClick={() => {
                            if (props.onBeforeRefresh) props.onBeforeRefresh();
                            loadData();
                        }}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                    {props.actionThird && <ButtonComponent color={'#28a745'} className={classes.buttonActionThird} label={props.actionThird.label} onClick={props.actionThird.onClick}/>}
                    {props.actionSecond && <ButtonComponent color={'#35A2EB'} className={classes.buttonActionSecond} label={props.actionSecond.label} onClick={props.actionSecond.onClick}/>}
                    {props.actionFirst && <ButtonComponent className={classes.buttonActionFirst} label={props.actionFirst.label} onClick={props.actionFirst.onClick}/>}
                </>
            );
        },

        // Traduction
        textLabels: {
            body: {
                noMatch: 'Aucun résultat.',
                toolTip: "Trier",
                columnHeaderTooltip: column => `Trier par ${column.label}`
            },
            pagination: {
                next: "Page suivante",
                previous: "Page précédente",
                rowsPerPage: "Ligne par page:",
                displayRows: "sur"
            },
            toolbar: {
                search: "Recherche",
                downloadCsv: "Télécharger CSV",
                print: "Imprimer",
                viewColumns: "Colonnes",
                filterTable: "Filtre"
            },
            viewColumns: {
                title: "Affichage des colonnes",
                titleAria: "Afficher/Cacher colonne"
            },
            selectedRows: {
                text: "ligne(s) sélectionnée(s)",
                delete: "Supprimer"
            },
            filter: {
                all: "Tous",
                title: "FILTRES",
                reset: "RESET"
            }
        },

        // Cache
        onFilterChange: (column, list) => {
            cacheTableFilter[props.id] = list;
        },
        onChangePage: (number) => {
            cacheTablePage[props.id] = number;
        },
        onColumnSortChange: (changedColumn, direction) => {
            cacheTableOrderBy[props.id] = {name: changedColumn, direction: direction};
        },
        onChangeRowsPerPage: (numberOfRows) => {
            cacheTableRowsPerPage[props.id] = numberOfRows;
        },
        sortOrder: cacheTableOrderBy[props.id] ?? {name: 'id', direction: 'DESC'},
        page: cacheTablePage[props.id] ?? 0,
        rowsPerPage: cacheTableRowsPerPage[props.id] ?? 10,

        // Select
        selectableRowsHeader: !!props.actionSelection,
        selectableRowsHideCheckboxes: false,
        selectableRowsOnClick: false,
        selectableRows: (props.actionSelection) ? 'multiple' : 'none',
        customToolbarSelect: () => {
            return <>
                {props.actionSelection && <ButtonComponent className={classes.buttonSelection} label={props.actionSelection.label} onClick={props.actionSelection.onClick}/>}
            </>;
        },
        onRowsDelete: false,
        isRowSelectable: () => {
            return true;
        },
        rowsSelected: [],

        // Server
        count: count,
        serverSide: !!props.promiseServerData,
        onTableInit: onTable,
        onTableChange: onTable
    };

    React.useEffect(loadData, []);
    React.useEffect(() => {
        if (reloadTable.id === props.id) loadData();
    }, [reloadTable]);

    if (cacheTableFilter[props.id]) {
        for (let index in props.columns) {
            props.columns[index].options.filterList = cacheTableFilter[props.id][index];
        }
    }

    return (
        <Fade in={true} {...{timeout: 1000}}>
            <div>
                <MUIDataTable
                    className={classes.MUIDataTable}
                    data={data}
                    columns={props.columns}
                    options={options}
                    title={props.loading ||
                    loading ? <><CircularProgress size={20} className={classes.circularProgress}/> <span className={classes.span}>Mise à jour des données.</span></> :
                        props.title ? <span className={classes.title}>{props.title}</span> : ''}
                />
            </div>
        </Fade>
    )
}

const useStyles = (theme, height, minHeight, small) => makeStyles({
    MUIDataTable: {
        margin: 0,
        '& td': small ? {
            paddingTop: 0,
            paddingBottom: 0,
        }: {},
        '&  > div:nth-child(3)': {
            minHeight: height ? height + ' !important' : (minHeight ? minHeight : ''),
            maxHeight: height ? height + ' !important' : '',
        },
        '& .MuiTableCell-root': {
            fontSize: 12,
            '& div': {
                fontSize: 12
            }
        },
        '& .MuiTableCell-head': {
            fontWeight: 600,
            zIndex: 1
        },
        '& .MuiSvgIcon-root': {
            width: 20,
            height: 20
        },
        '& p': {
            fontSize: 12
        },
        '& .MuiSelect-root': {
            fontSize: 12
        }
    },
    circularProgress: {
        margin: 10,
        color: theme.colorPrimary
    },
    title: {
        margin: 10,
        color: '#5E6E82',
        fontSize: 14,
    },
    span: {
        margin: '0 10px',
        fontSize: 10,
        height: 10,
        display: 'inline-block',
        verticalAlign: 'bottom',
        padding: '18px 0',
        color: '#5E6E82'
    },
    buttonActionFirst: {
        margin: '10px -8px 10px 20px'
    },
    buttonActionSecond: {
        margin: '10px -8px 10px 20px'
    },
    buttonActionThird: {
        margin: '10px -8px 10px 10px'
    },
    buttonSelection: {
        margin: '10px 5px',
        marginRight: 14
    }
});
