import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Fade, Grid, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import Api, { LibraryResourceImage } from '../../../Api';
import BoxLearnerComponent from '../../../Component/BoxLearnerComponent';
import LayersIcon from '@material-ui/icons/Layers';
import CardResourceType from './Component/CardResourceType';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import LazyLoading from './Component/LazyLoading';

const Resource = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [resources, setResources] = React.useState();
    const [typeLabel, setTypeLabel] = React.useState();
    const [libraryResourceTypes, setLibraryResourceTypes] = React.useState([]);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        setLoading(true);

        Api.get({
            route: 'learner_resource_list',
            data: { id: props.match.params.id}
        },
            (response) => {
                if (response && response.data) {
                    setResources(response.data.data);
                    setTypeLabel(response.data.typeLabel);
                    setBreadcrumbs({
                        currentPage: response.data.typeLabel ? response.data.typeLabel : 'Ressource',
                        links: [
                            { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                            { path: getRoutePathname('learner_library_resource'), label: 'Bibliothèque' },
                        ]
                    });
                }
            });

        Api.get({ route: 'learner_resource_library_resource_types' },
            (response) => {
                if (response && response.data) {
                    setLibraryResourceTypes(response.data);
                }
            });

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [props.match.params.id]);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{ textAlign: 'center', maxWidth: 1240, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            <FeaturedResourceComponent
                                organism={authenticationReducer.organism}
                                formationGroup={authenticationReducer.formationGroup}
                            />
                            <br />
                            <div style={{ width: isSmallView ? '90%' : '80%', margin: 'auto' }}>
                                 <p style={{ textAlign: 'left', fontWeight: 600 }}>{resources.length > 0 ? 'Tous les ' + typeLabel : 'Aucune ressource de ce type est disponible'}</p>
                                <Grid container spacing={3} >
                                    {resources &&
                                        resources.map((resource) => (
                                            <Grid key={resource.id} item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                <MediaLinkComponent
                                                    path={resource.link}
                                                    type={resource.format}
                                                    isLibrary={true}
                                                >
                                                    <div className={classes.card}>
                                                        <ShadowBoxComponent style={{ padding: 10, height: 180 }}>
                                                            <LazyLoading path={LibraryResourceImage + resource.image} />
                                                        </ShadowBoxComponent>
                                                        <p style={{ fontWeight: 600 }}>{resource.title}</p>
                                                        <p style={{ fontSize: 12 }}>{resource.description}</p>
                                                    </div>  
                                                </MediaLinkComponent>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </div>
                            <br />
                            <BoxLearnerComponent
                                title={'Bibliothèque de ressource'}
                                color={'#fff'}
                                backgroundHeader={'rgb(79, 147, 165)'}
                                backgroundBody={'rgb(193, 216, 218)'}
                                iconTitle={<LayersIcon />}
                            >
                                <Grid container spacing={3} >
                                    {libraryResourceTypes &&
                                        libraryResourceTypes.map((libraryResourceType) => (
                                            <CardResourceType
                                                key={libraryResourceType.value}
                                                libraryResourceType={libraryResourceType}
                                                onClick={() => {
                                                    props.history.push(getRoutePathname('learner_resource', { id: libraryResourceType.value }))
                                                }}
                                            />
                                        ))
                                    }
                                </Grid>
                                <p
                                    className={classes.textLink}
                                    onClick={() => { props.history.push(getRoutePathname('learner_library_resource')) }}
                                >
                                    {'Voir toutes les ressources \>'}</p>
                            </BoxLearnerComponent>
                        </>
                }
            </div>
        </>
    );
};

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    card: {
        height: 300,
        padding: 15,
        background: '#fff',
        cursor: 'pointer',
        border: '1px solid #F2F2F2',
        borderRadius: 10,
        '& > p': {
            textAlign: 'left'
        }
    },
    textLink: {
        position: 'absolute',
        bottom: -10,
        left: 40,
        textDecoration: 'underline',
        fontWeight: 500,
        cursor: 'pointer'
    }
});

export default Resource;