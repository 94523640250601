import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from "moment";

export default function ProspectActivity(props = {}) {
    const columnsProspectActivities = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'description',
            label: 'Description',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: ' ',
            options: {filter: true, sort: true}
        }
    ];

    return (
        <TableComponent
            noCache={true}
            sortOrder={{name: 'id', direction: 'desc'}}
            id={'crm_prospect_prospect_activity_list'}
            title={'Historique des activités'}
            columns={columnsProspectActivities}
            promiseData={(resolve) => {
                if (props.match.params.id) {
                    Api.get({route: 'crm_prospect_prospect_activity_list', params: {id: props.match.params.id}}, (response) => {
                        if (response.data) {
                            let data = response.data;
                            for (let index in data) data[index].createdAt = moment(data[index].createdAt).fromNow();
                            resolve(data);
                        }
                        else {
                            resolve([]);
                        }
                    })
                }
                else {
                    resolve([]);
                }
            }}
        />
    )
}
