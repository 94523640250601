import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { getRoutePathname } from "../../../Config/Route";
import { useDispatch } from "react-redux";
import SweetAlert from "sweetalert-react";
import { TableReload } from "../../../Action/TableAction";

function GroupPrivateList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "title",
            label: "Titre",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "deletedAt",
            label: "Date de fermeture",
            options: { filter: true, sort: true }
        },
        {
            name: "numProspect",
            label: "Membres",
            options: { filter: true, sort: true }
        },
        {
            name: "numPost",
            label: "Groupe de discussion",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('community_group_private', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des groupes',
            context: 'Forum',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableComponent
                        id={'community_group_private_list'}
                        title={'Groupes privé'}
                        columns={columns}
                        actionFirst={{
                            label: 'Créer un groupe',
                            onClick: () => props.history.push(getRoutePathname('community_group_private', { id: null }))
                        }}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'community_group_private_list'
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        data[index].createdAt = moment(data[index].createdAt).format('lll');
                                        data[index].deletedAt = data[index].deletedAt ? moment(data[index].deletedAt).format('lll') : '-';
                                        data[index].action = getAction(data[index]);
                                    }
                                    resolve(data);
                                });
                        }}
                    />
                    <br/>
                </Grid>
            </Grid>
            <br />

        </ContentViewComponent>
    );
}

export default GroupPrivateList;
