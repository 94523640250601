import React from 'react';
import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from "@material-ui/core/FormHelperText";

function SelectAutocompleteAutocompleteComponent(props = {
    name: '',
    nullable: true,
    disabled: false,
    onChange: false,
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    },
    options: []
}) {
    const classes = useStyles();
    const options = props.options;
    const data = props.handler.form[props.name];
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled;
    const [value, setValue] = React.useState(data.value ? data.value : '');
    const [values, setValues] = React.useState('');

    const onChange = (event, newValue) => {
        setValues(newValue);
        setValue(newValue.value)
        if (props.onChange) {
            props.onChange(newValue.value);
            setFocus(false);
        }
    }
    const onBlur = () => {
        setFocus(false);
        let newForm = { ...props.handler.form };
        newForm[props.name].value = value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onFocus = () => {
        setFocus(true);
    }

    React.useEffect(() => {
        setError(props.handler.form[props.name].error)
    }, [props.handler.form[props.name]].error);
    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue('');
            setValues('');
            setTimeout(() => { setValue(props.handler.form[props.name].value); }, 50);
        }
    }, [props.handler.form[props.name].value]);

    React.useEffect(() => {
        if (value && options) {
            options.map((option, index) => {
                if (option.value === value) {
                    setValues(option)
                }
            })
        }
    }, [value, options]);

    return (
        <FormControl variant="outlined"
            className={`
                        ${classes.selectAutocomplete}
                        ${isFocus && !disabled ? classes.selectAutocompleteFocus : error && !disabled ? classes.selectAutocompleteError : value && !(disabled || props.disabled) ? classes.selectAutocompleteValid : ''}
                    `}
        >
            <Autocomplete
                value={values}
                options={options}
                getOptionLabel={(option) => option.label || ""}
                getOptionSelected={(option, value) => option.label === value.label}
                disableClearable={true}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                classes={{paper : classes.paper}}
                noOptionsText={<span style={{color:'#FFFFFF'}}>Aucune option</span>}
                disabled={disabled || props.disabled}
                renderInput={(params) => <TextField {...params} label={data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')} variant="outlined" />}
            />
            <FormHelperText>{error && !isFocus ? error : data.textHelper}</FormHelperText>
        </FormControl>
    );
}

const useStyles = makeStyles({
    selectAutocomplete: {
        width: '100%',
        marginTop: 0,
        '& selectAutocomplete': {
            padding: '10px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0.5px 12px'
        },
        '& .MuiSelectAutocomplete-selectAutocomplete:focus': {
            background: '#FFFFFF'
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 10px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -4px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            height: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important',
            borderColor: 'rgba(0, 0, 0, 0.23) !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        },
        '& label': {
            color: 'rgba(0, 0, 0, 0.54) !important',
            backgroundColor: '#FFFFFF',
            padding: '0 2px'
        }
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#ffffff',
        fontSize: 13,
        backgroundColor: '#666666',
        border:'1px solid #000000'
      },
    selectAutocompleteFocus: {
        '& fieldset': {
            borderColor: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& label': {
            color: '#3F51BF !important'
        }
    },
    selectAutocompleteValid: {
        '& fieldset': {
            borderColor: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        },
        '& label': {
            color: '#008000 !important'
        }
    },
    selectAutocompleteError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    }
});

export default SelectAutocompleteAutocompleteComponent;
