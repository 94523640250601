import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import {getRoutePathname} from "../../../Config/Route";
import SweetAlert from "sweetalert-react";
import {TableReload} from "../../../Action/TableAction";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {useDispatch} from "react-redux";
import {SnackbarOpen} from "../../../Action/SnackbarAction";

function ResourceList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "resourceTypeName",
            label: "Type",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('content_manager_resource', {id: row.id}));
                    }}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des ressources',
            context: 'Gestion de contenu',
            description: ''
        });

        setReadyContent(true);
    }, []);
 
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'content_manager_resource_list'}
                title={'Ressources'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un ressource',
                    onClick: () => props.history.push(getRoutePathname('content_manager_resource', {id: null}))
                }}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'content_manager_resource_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].action = getAction(data[index]);
                                data[index].resourceTypeName = data[index].resourceTypeName ? data[index].resourceTypeName : '-';
                            }

                            resolve(data);
                        });
                }}
            />
            <br/>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette ressource ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                            route: 'content_manager_resource_delete',
                            params: {id: actionRow.id}
                        },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Ressource supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_resource_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>
    );
}

export default ResourceList;
