import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { makeStyles } from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import Api from "../../../Api";
import { getRoutePathname } from "../../../Config/Route";
import TitleComponent from "../../../Component/TitleComponent";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { useDispatch } from "react-redux";
import Fade from "@material-ui/core/Fade";
import TabsComponent from "../../../Component/TabsComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import GroupFormationPostList from './GroupFormationPostList';
import GroupFormationProspectList from './GroupFormationProspectList';

function GroupFormation(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [isOrganism, setIsOrganism] = React.useState(false);

    const [form, setForm] = React.useState({
        title: {
            name: 'title',
            label: 'Titre',
            textHelper: 'Saisissez le titre du Groupe.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Saisissez l\'organisme du groupe.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        isLock: {
            name: 'isLock',
            label: 'Retiré le groupe',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        isPermission: {
            name: 'isPermission',
            label: 'Accès à la création de disscusion',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
    });
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'community_group_formation_edit',
                    params: { id: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({
                    route: 'community_group_formation_add',
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('community_group_formation', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Groupe créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer un Groupe',
                context: 'Forum',
                description: '',
                links: [
                    { path: getRoutePathname('community_group_formation_list'), label: 'Liste des Groupes' }
                ]
            });
            setIsEdition(true);

            Api.get({
                route: 'community_group_formation_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                        if(response.data.organism.length) setIsOrganism(true)
                        else setIsOrganism(false)
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('community_group_formation_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
        else {
            setBreadcrumbs({
                title: 'Ajouter un Groupe',
                context: 'Forum',
                description: '',
                links: [
                    { path: getRoutePathname('community_group_formation_list'), label: 'Liste des Groupes' }
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);
    React.useEffect(() => {
        Api.get({
            route: 'select_organisms'
        },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={isReadyContent} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent className={classes.shadowBox}>
                        <TitleComponent title={isEdition ? 'Formulaire d\'édition d\'un Groupe' : 'Formulaire de création d\'un Groupe'} />

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <TextFieldComponent name={'title'} handler={handler} disabled={true} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <MultipleSelectComponent name={'organism'} handler={handler} options={optionOrganism} disabled={true} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                <CheckboxComponent name={'isLock'} handler={handler} disabled={!isOrganism}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <CheckboxComponent name={'isPermission'} handler={handler} />
                            </Grid>
                        </Grid>

                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading} />
                    </ShadowBoxComponent>
                </div>
            </Fade>
            <br />
            {
                isEdition &&
                <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                    <div>
                        <TabsComponent
                            tabDefault={'GroupFormationProspectList'}
                            tabs={[
                                { id: 'GroupFormationProspectList', label: 'Membres' },
                                { id: 'GroupFormationPostList', label: 'Groupe de discussions' },
                            ]}
                        >
                            <div key={'GroupFormationProspectList'}>
                                <GroupFormationProspectList {...props} />
                            </div>
                            <div key={'GroupFormationPostList'}>
                                <GroupFormationPostList {...props} />
                            </div>
                        </TabsComponent>
                    </div>
                </Slide>
            }

            <br />
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});

export default GroupFormation;
