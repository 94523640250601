import React from 'react';
import {Box, Chip, Fade, LinearProgress as LinearProgressMUI, makeStyles} from "@material-ui/core";
import ButtonComponent from "../../../../Component/ButtonComponent";
import {theme} from "../../../../App";
import {useSelector} from "react-redux";

function CardFormation(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [sizeView, setSizeView] = React.useState('');
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        setSizeView(screenReducer.screen);
    }, [screenReducer.screen]);

    return (
        <Fade in={props.show} {...{timeout: 500}}>
            <div className={classes.box} style={{height: sizeView === 'XS' ? 130 : 280}}>
                <Box className={classes.chipBox}>
                    {props.financeTypes.map((financeType) =>
                        <Chip
                            size="small"
                            label={financeType.label}
                            key={financeType.id}
                            className={classes.chip}
                        />)}
                </Box>

                {
                    sizeView !== 'XS' &&
                    <>
                        {!load && <img src="/asset/images/preload_image.png" className={classes.cardImagePreload} alt={'Image formation'}/>}
                        <img src={props.image && props.image} className={classes.cardImage} alt={'Image formation'} onLoad={() => setLoad(true)}/>
                    </>

                }

                <div style={{width: '90%', margin: 'auto'}}>
                    <p style={{
                        minHeight: 30,
                        textAlign: 'left',
                        fontWeight: 500, fontSize: sizeView === 'XS' ? 11 : 13,
                        marginTop: sizeView === 'XS' ? 20 : 10,
                    }}>
                        {props.title ? props.title.substring(0, 50) : ''}
                        {props.title && props.title.length > 50 ? '...' : ''}
                    </p>

                    {props.untilAvailable && <p style={{fontSize: 10, fontStyle: 'italic', textAlign: 'left'}}>{props.untilAvailable}</p>}

                    <div className={classes.cardHour}>{props.hours && props.hours}h</div>
                    <ButtonComponent
                        small={true}
                        color={props.notAvailable ? '#4f93a5' : theme.colorPrimary}
                        style={{position: 'absolute', bottom: 10, right: 10, fontSize: 9, padding: 6}}
                        label={props.notAvailable ? 'Pré-inscription →' : 'Inscription →'} onClick={() => props.onClick()}
                    />
                </div>

                {sizeView !== 'XS' && <LinearProgressMUI className={classes.linearProgress} hidden={load}/>}
            </div>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles(({
    box: {
        border: '1px solid #F2F2F2',
        borderRadius: 10,
        background: '#fff',
        position: 'relative'
    },
    cardImagePreload: {
        height: 110,
        width: '100%',
        borderRadius: '10px 10px 0px 0px',
        position: 'absolute',
        top: 0,
        left: 0
    },
    cardImage: {
        display: 'block',
        borderRadius: '10px 10px 0px 0px',
        height: 110,
        width: '100%',
        objectFit: 'cover'
    },
    chipBox: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    chip: {
        margin: '3px 3px 3px 1px',
        fontSize: 7,
        fontWeight: 700,
        color: '#FFFFFF',
        backgroundColor: '#03a9f4',
        '&.MuiChip-sizeSmall': {
            height: 15
        }
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    cardHour: {
        fontWeight: 500,
        padding: 5,
        width: '40%',
        borderRadius: '0 0 5px 0',
        margin: '20px 0px',
        fontSize: 12,
        border: '1px solid #F2F2F2',
    }
}));

export default CardFormation;
/*
    <a
                                target={'_blank'}
                                href="https://www.santeformapro.com/formations/infirmiers/gestion-de-la-violence-et-de-lagressivite-en-exercice-liberal/"
                                style={{color: theme.colorPrimary, textDecoration: 'none'}}
                            > En savoir plus </a>
 */
