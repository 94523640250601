import React from 'react';
import {makeStyles} from "@material-ui/core";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {Add} from "@material-ui/icons";
import {clone} from "../../../Tool/CloneCommon";
import Grid from "@material-ui/core/Grid";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Alert from '@material-ui/lab/Alert';
import DatePickerComponent from "../../../Component/DatePickerComponent";

function Invoice(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [countForm, setCountForm] = React.useState(1);
    const [form, setForm] = React.useState({
        quantity: {
            name: 'quantity',
            label: 'Quantité',
            textHelper: 'Saisissez une quantité.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        price: {
            name: 'price',
            label: 'Prix unitaire',
            textHelper: 'Saisissez un prix unitaire',
            type: 'float',
            defaultValue: props.sessionFormation.price,
            options: {validation: ['required']}
        }
    });
    const [formAt, setFormAt] = React.useState({
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: props.invoiceAt,
            options: {validation: ['required', 'date']}
        }
    });

    const [total, setTotal] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [countRegistration, setCountRegistration] = React.useState(false);
    const handler = formHandlerInit(form, setForm);
    const handlerAt = formHandlerInit(formAt, setFormAt);

    const add = () => {
        let newForm = {};
        newForm['quantity' + countForm] = {
            name: 'quantity' + countForm,
            label: 'Quantité',
            textHelper: 'Saisissez une quantité.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        };
        newForm['price' + countForm] = {
            name: 'price' + countForm,
            label: 'Prix unitaire',
            textHelper: 'Saisissez un prix unitaire',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        };
        setForm({...form, ...newForm});
        setCountForm(countForm + 1);
    };
    const sub = () => {
        let oldForm = clone(form);
        if (oldForm['price' + (countForm - 1)]) {
            delete oldForm['price' + (countForm - 1)];
            delete oldForm['quantity' + (countForm - 1)];
            setForm(oldForm);
            setCountForm(countForm - 1);
        }
    };
    const getRender = () => {
        if (form) {
            let r = [];
            let c = 0;

            for (let index in form) {
                if (c % 2 === 0) {
                    r.push(<Grid item xs={12} sm={12} md={4} lg={4} xl={4}><TextFieldComponent name={index} handler={handler}/></Grid>);
                } else {
                    r.push(<Grid item xs={12} sm={12} md={8} lg={8} xl={8}><TextFieldComponent name={index} handler={handler}/></Grid>);
                }
                c++;
            }

            return r;
        } else {
            return <></>;
        }
    };
    const calcTotal = () => {
        let c = 0;
        let m = 0;
        let t = 0;

        for (let index in form) {
            if (c % 2 === 0) {
                m = form[index].value;
            } else {
                t += m * form[index].value;
            }
            c++;
        }

        setTotal((Number(t) === t) ? t.toFixed(2) : 0);
    };

    const save = () => {
        if (handler.checkError() || handlerAt.checkError()) {
            console.log('Error');
        } else {
            let data = handler.getData();
            let dataAt = handlerAt.getData();
            let dataSave = [];
            let c = 0;
            let m = 0;

            for (let index in data) {
                if (c % 2 === 0) {
                    m = form[index].value;
                } else {
                    dataSave.push({'price': form[index].value, 'quantity': m});
                }
                c++;
            }

            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            Api.post({
                    route: 'financial_management_billing_session_formation_invoice',
                    params: {id: props.sessionFormation.id},
                    data: {lines: dataSave, 'invoiceAt': dataAt.invoiceAt}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        dispatch(TableReload('billing_registration_list'));

                        dispatch(
                            SnackbarOpen({
                                text: 'Session formation facturée.',
                                variant: 'success',
                            })
                        );

                        dispatch(TableReload('billing_registration_list'));

                        const url = Api.route({
                            route: 'financial_management_billing_invoice_download',
                            params: {id: response.data.id},
                        });
                        window.open(url, '_blank');
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(handlerAt.start, []);
    React.useEffect(() => {
        let newForm = {...handler.form};
        newForm['price'].value = props.sessionFormation.price;
        newForm['quantity'].value = props.sessionFormation.countInvoice;
        handler.set(newForm);
    }, [props.sessionFormation.price]);
    React.useEffect(calcTotal, [form]);
    React.useEffect(() => {
        let count = 0;
        for (let index in props.sessionFormation.registrations) {
            let state = props.sessionFormation.registrations[index].state;
            if (state === 1 || state === 5 || state === 6) {
                count++;
            }
        }
        setCountRegistration(count);
    }, [props.sessionFormation.registrations]);
    React.useEffect(() => {
        handlerAt.setValue('invoiceAt', props.sessionFormation.invoiceAt);
    }, [props]);

    return (
        <>
            <Dialog open={props.open} maxWidth={'lg'}>
                <DialogTitle style={{fontSize: 15}}>Facture</DialogTitle>
                <DialogContent>
                    <div style={{width: '70vw', maxWidth: 600}}>
                        {props.sessionFormation.countInvoice !== countRegistration && <>
                            <Alert severity="warning">Attention, vous allez facturer {props.sessionFormation.countInvoice} personne(s) sur {countRegistration}.</Alert><br/><br/>
                        </>}

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <DatePickerComponent name={'invoiceAt'} handler={handlerAt}/>
                            </Grid>
                        </Grid>

                        <hr/>
                        <br/>

                        <Grid container spacing={2}>
                            {getRender(form, handler)}
                        </Grid>

                        <div style={{width: '100%', height: 20}}>
                            <ButtonComponent className={classes.btnAddSub} color={'#5E6E82'} label={<Add style={{fontSize: 17}}/>} onClick={add}/>
                            <ButtonComponent disabled={countForm <= 1} style={{paddingLeft: 2, paddingBottom: 2, color: '#FFF'}} className={classes.btnAddSub} color={countForm <= 1 ? '#959595' : '#5E6E82'} label={'-'} onClick={sub}/>
                        </div>

                        <br/>
                        <hr/>

                        <div style={{textAlign: 'end'}}>
                            Total : {total}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'}/>
                    <ButtonComponent disabled={loading} onClick={save} label={'Générer la facture'}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    btnAddSub: {
        backgroundColor: 'rgb(94, 110, 130)',
        borderRadius: 20,
        padding: 0,
        width: 25,
        height: 25,
        minWidth: 0,
        margin: 6,
        fontSize: 29,
        float: 'right'
    }
});

export default Invoice;
