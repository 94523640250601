import React from 'react';
import {Switch, Route} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from "./Component/AppBar";
import Drawer from "./Component/Drawer";

export default function MainLayout(props) {
    const classes = useStyles();

    return (
        <div className={classes.mainLayout}>
            {/* <== AppBar */}
            <AppBar {...props}/>
            {/* AppBar ==> */}

            {/* <== Drawer */}
            <Drawer {...props}/>
            {/* Drawer ==> */}

            {/* <== View */}
            <div className={classes.view}>
                <Switch>
                    {
                        props.routes.map((route) => (
                            <Route key={'route_' + route.id} path={route.path} render={props => <route.view {...props} routes={route.routes}/>}/>
                        ))
                    }
                </Switch>
            </div>
            {/* View ==> */}
        </div>
    );
}

const useStyles = makeStyles({
    mainLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'flex'
    },
    view: {
        flexGrow: 1,
        overflowX: 'scroll',
        paddingTop: 58,
        boxSizing: 'border-box'
    }
});
