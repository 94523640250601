import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import Api from "../../../Api";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import Button from "@material-ui/core/Button";
import moment from "moment";

function Statistics(props) {
    const classes = useStyles();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [optionType, setOptionType] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([]);

    // Form
    const [form, setForm] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: parseInt(moment().format('YYYY')),
            options: {}
        },
        andpc: {
            name: 'andpc',
            label: 'Code andpc',
            textHelper: 'Saisissez un code andpc.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez une formation.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Choisissez un organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Choisissez le type.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: '',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const exported = () => {
        const url = Api.route({
            route: 'financial_management_statistics_export',
            data: handler.getData(),
        });
        window.open(url);
    };

    // UseEffect
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Statistiques',
            context: 'Gestion financière',
            description: 'Export.'
        });

        Api.get({
                route: 'select_formations'
            },
            (response) => {
                if (response && response.data) {
                    setOptionFormation(response.data);
                }
            });

        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });

        Api.get({
                route: 'select_formation_groups'
            },
            (response) => {
                if (response && response.data) {
                    setOptionFormationGroup(response.data);
                }
            });

        // Year
        const currentYear = new Date().getFullYear();
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({label: i, value: i});
        }
        setOptionYears(yearsTMP);

        setOptionType([{"value": 1, "label": "Facture"}, {"value": 2, "label": "Avoir"}]);
        setReadyContent(true);
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                        <Grid container spacing={1}>
                            <Grid item xs={4} sm={4} md={1} lg={1}>
                                <SelectComponent name={'year'} handler={handler} options={optionYears}/>
                            </Grid>
                            <Grid item xs={8} sm={8} md={3} lg={3}>
                                <SelectComponent name={'organism'} handler={handler} options={optionOrganism} nullable={true}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'formation'} handler={handler} options={optionFormation} nullable={true}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextFieldComponent name={'andpc'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'type'} handler={handler} options={optionType} nullable={true}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup} nullable={true}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <DatePickerComponent name={'startAt'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <DatePickerComponent name={'endAt'} handler={handler}/>
                            </Grid>
                        </Grid>

                        <div style={isTall ? {height: 35, marginTop: 10} : {marginTop: 10}}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={
                                    isTall ?
                                        {float: 'right', marginLeft: 10, fontSize: 12, opacity: 0.9} :
                                        {width: 'calc(100% - 20px)', marginLeft: 10, fontSize: 12, opacity: 0.9}
                                }
                                onClick={exported}>
                                Exporter
                            </Button>
                        </div>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br/>

        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});

export default Statistics;
