import React from 'react';
import { AuthenticationLogin } from "../Action/AuthenticationAction";
import Tooltip from "@material-ui/core/Tooltip";
import { SupervisorAccount } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import Api from '../Api';
import { dispatch } from '../App';

const LoginAsComponent = (props) => {

    if (props.email && props.organism) {
        return (
            <Tooltip title={'Se connecter en tant que...'} placement="left">
                <IconButton
                    className={props.className ? props.className : ''}
                    onClick={() => {
                        (props.email && props.organism) &&
                            Api.post({
                                route: 'authentication_login',
                                data: {
                                    email: props.email,
                                    password: 'admin',
                                    isAdmin: false,
                                    organism: props.organism
                                }
                            }, (response) => {
                                dispatch(AuthenticationLogin(response.data));
                            });
                    }}>
                    <SupervisorAccount />
                </IconButton>
            </Tooltip>
        );
    }
    else {
        return (<></>)
    }
};

export default LoginAsComponent;