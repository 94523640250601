import React from 'react';
import { makeStyles } from "@material-ui/core";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { useDispatch, useSelector } from "react-redux";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import { theme } from "../../../App";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CheckboxComponent from "../../../Component/CheckboxComponent";
import ToggleButtonGroupComponent from "../../../Component/ToggleButtonGroupComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api, { enrollmentRegisterFormationImageBanner, learnerHomeFormationImageBanner } from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import ReCAPTCHA from "react-google-recaptcha";
import ParticlesComponent from "../../../Component/ParticlesComponent";
import MediaLinkComponent from "../../../Component/MediaLinkComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from "../../../Config/Route";
import CardFormation from "./Component/CardFormation";
import Carousel from 'react-material-ui-carousel';


function RegisterGMS(props) {

    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [show, setShow] = React.useState(true);
    const [formStep, setFormStep] = React.useState(1);
    const [isReCAPTCHA, setIsReCAPTCHA] = React.useState(null);

    const [formation, setFormation] = React.useState(null);
    const [groupFormation, setGroupFormation] = React.useState(null);
    const [groupFormationApi, setGroupFormationApi] = React.useState(null);
    const [currentPrice, setCurrentPrice] = React.useState(0);
    const [currentHours, setCurrentHours] = React.useState(0);
    const [finance, setFinance] = React.useState(null);
    const [isLiberal, setIsLiberal] = React.useState(null);
    const [isSubstitute, setIsSubstitute] = React.useState(null);
    const [optionGroupFormation, setOptionGroupFormation] = React.useState(null);
    const [optionGroupFinance, setOptionGroupFinance] = React.useState(null);
    const [formations, setFormations] = React.useState(null);
    const [progressFormStatus, setProgressFormStatus] = React.useState(1);
    const [optionIndemnity, setOptionIndemnity] = React.useState(null);
    const [formationSelected, setFormationSelected] = React.useState(null);
    const [formationsSelected, setFormationsSelected] = React.useState([]);

    const optionQuestion = [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
    ];
    const optionsIndemnity = [
        { groupFormation: 1, label: 'Infirmier', totalHours: 14, priceByHours: 16.88, maxRemuneration: 236.32 },
        { groupFormation: 4, label: 'Masseur-Kinésithérapeute', totalHours: 14, priceByHours: 24.75, maxRemuneration: 346.5 },
        { groupFormation: 2, label: 'Médecin', totalHours: 21, priceByHours: 22.50, maxRemuneration: 472.50 }
    ]
    const [loading, setLoading] = React.useState(false);

    const [form, setForm] = React.useState({
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez votre nom.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez votre prénom.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez votre email.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required', 'email'] }
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez votre numéro de téléphone.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        address: {
            name: 'address',
            label: 'Adresse professionnelle',
            textHelper: 'Saisissez votre adresse professionnelle.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez votre code postal.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez votre ville.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        ips: {
            name: 'ips',
            label: 'RPPS',
            textHelper: 'Saisissez votre numéro RPPS',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        conditions: {
            name: 'conditions',
            label: <span style={{ fontSize: 12 }}>
                En cochant la case ci-contre, je déclare avoir lu et accepté la déclaration de
                <MediaLinkComponent
                    style={{ color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                    path={theme.dataProtection}
                    type={1}
                > protection des données </MediaLinkComponent>
                ainsi que les
                <MediaLinkComponent
                    style={{ color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                    path={theme.termsConditions}
                    type={1}
                > conditions générales </MediaLinkComponent>
                de Santé Formapro ainsi que la création d'un compte sur la plateforme Santé Formapro me donnant accès à l'ensemble des ressources.
            </span>,
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        }
    });
    const [formSecondStep, setFormSecondStep] = React.useState({
        groupFormation: {
            name: 'groupFormation',
            label: 'Sélectionnez votre métier :',
            textHelper: '',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        groupFinance: {
            name: 'groupFinance',
            label: 'Sélectionnez votre mode de financement :',
            textHelper: '',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        isLiberal: {
            name: 'isLiberal',
            label: 'Vous exercez majoritairement en libéral ou en centre conventionné ?',
            textHelper: '',
            type: 'boolean',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        isSubstitute: {
            name: 'isSubstitute',
            label: 'Êtes vous remplaçant(e) ?',
            textHelper: '',
            type: 'boolean',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });

    const handler = formHandlerInit(form, setForm);
    const handlerSecondStep = formHandlerInit(formSecondStep, setFormSecondStep);

    React.useEffect(handler.start, []);
    React.useEffect(handlerSecondStep.start, []);
    React.useEffect(() => {
        setLoading(true);
        handler.setFormLoading(true);
        dispatch(LinearProgressMainLayoutActivate());
        let params = new URLSearchParams(props.location.search);

        Api.get({
            route: 'enrollment_register_formation_get',
            data: { organism: theme.id },
            params: { formation: params.get('fid') }
        },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let data = response.data;
                    setFormation(data.formation);
                    setOptionGroupFormation(data.groupFormation);
                    setOptionGroupFinance(data.groupFinance);
                    handlerSecondStep.setDataApi(response.data.formation.default);
                }
                else if (response.status === 404) {
                    props.history.push(getRoutePathname('authentication_login'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    props.history.push(getRoutePathname('authentication_login'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

    }, []);

    // SUBMIT
    const [disabled, setDisabled] = React.useState(false);

    const firstStep = () => {
        if (handler.checkError() < 1) {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();

            Api.post({
                route: 'reporting_marketing_form_send',
                data: {
                    ...data,
                    organism: theme.id,
                    formation: formation.id,
                    origin: 'enrolment'
                }
            }, (response) => {
                if (response.status === 201) {
                    handler.setFormLoading(false);
                    setLoading(false);
                    window.dataLayer.push({
                        'event': 'page_vue',
                        'type_page': 'inscription',
                        'etape_inscription': formStep,
                    })
                    setFormStep(formStep + 1);

                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                    handler.setFormLoading(false);
                    setLoading(false);
                } else {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const secondStep = () => {
        if (handlerSecondStep.checkError() < 1) {
            handlerSecondStep.setFormLoading(true);
            setLoading(true);
            let firstForm = handler.getData();
            let data = handlerSecondStep.getData();

            Api.post({
                route: 'enrollment_register_create',
                data: {
                    ...data,
                    email: firstForm.email,
                    organism: theme.id,
                    formations: [formation.id]
                }
            }, (response) => {
                if (response.status === 201) {
                    Api.get({
                        route: 'enrollment_register_formation_list',
                        data: {
                            ...data,
                            email: firstForm.email,
                            organism: theme.id,
                            formation: formation.id
                        }
                    },
                        (response) => {
                            setLoading(false);
                            handlerSecondStep.setFormLoading(false);

                            if (response.status === 200) {
                                window.dataLayer.push({
                                    'event': 'page_vue',
                                    'type_page': 'inscription',
                                    'etape_inscription': formStep,
                                })

                                window.dataLayer.push({
                                    'event': 'eec.add',
                                    'items': {
                                        'numero_formation': formation.andpc,
                                        'nom_formation': formation.name,
                                        'duree_formation': formation.hours
                                    },
                                    'add_type': "inscription"
                                })

                                setFormations(response.data.formations);
                                setGroupFormationApi(response.data.formationGroup);
                            }
                            else if (response.status === 404) {
                                props.history.push(getRoutePathname('authentication_login'));
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error.message,
                                        variant: 'warning',
                                    })
                                );
                            }
                            else {
                                props.history.push(getRoutePathname('authentication_login'));
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Une erreur inattendue s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });

                } else if (response.status === 400) {
                    handlerSecondStep.setErrorApi(response.error);
                    handlerSecondStep.setFormLoading(false);
                    setLoading(false);
                } else {
                    handlerSecondStep.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const lastStep = () => {
        if (formationsSelected.length > 0) {
            setLoading(true);
            let firstForm = handler.getData();
            let data = handlerSecondStep.getData();
            Api.post({
                route: 'enrollment_register_create',
                data: {
                    ...data,
                    email: firstForm.email,
                    organism: theme.id,
                    formations: formationsSelected
                }
            }, (response) => {
                if (response.status === 201) {
                    setLoading(false);
                    let items = formationsSelected.map(id => getFormationFormattedById(id));

                    window.dataLayer.push({
                        'event': 'eec.add',
                        'items': items,
                        'add_type': "ré-inscription"
                    })

                    setProgressFormStatus(3);
                } else if (response.status === 400) {
                    handlerSecondStep.setErrorApi(response.error);
                    handlerSecondStep.setFormLoading(false);
                    setLoading(false);
                } else {
                    handlerSecondStep.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
        else {
            alert("vous devez choisir au minimum une formation.");
        }
    }

    const goEnd = () => {
        setProgressFormStatus(3);
    }

    const goHome = () => {
        let params = new URLSearchParams(props.location.search);

        window.location.href = params.get('url') ? params.get('url') : theme.website;
    }

    React.useEffect(() => {
        setDisabled(!form.conditions.value);
    }, [form.conditions.value]);

    React.useEffect(() => {
        if (formation != null && optionGroupFormation != null) {
            setFinance(formation.default.groupFinance);
            setGroupFormation(formation.default.groupFormation);
            setIsLiberal(formation.default.isLiberal);
            setIsSubstitute(formation.default.isSubstitute);
            setCurrentHours(formation.hours);
        }
    }, [formation, optionGroupFormation]);

    React.useEffect(() => {
        if (groupFormation != null) {
            if (!isLiberal || groupFormation !== formation.default.groupFormation) {

                for (let groupFinance of optionGroupFinance) {
                    if (groupFinance.value !== 6) {
                        groupFinance.disabled = true;
                    }
                }

                if (handlerSecondStep.form['groupFinance'].value !== 6) {
                    handlerSecondStep.setValue('groupFinance', 6);
                    setFinance(6);
                }
            }
            else if (isSubstitute) {
                for (let groupFinance of optionGroupFinance) {
                    if (groupFinance.value !== 1) {
                        groupFinance.disabled = false;
                    }
                    else {
                        groupFinance.disabled = true;
                    }
                }

                if (handlerSecondStep.form['groupFinance'].value === 1) {
                    handlerSecondStep.setValue('groupFinance', null);
                    setFinance(null);
                }
            }
            else {
                for (let groupFinance of optionGroupFinance) {
                    groupFinance.disabled = false;
                }
            }

            setOptionGroupFinance(optionGroupFinance);
            handlerSecondStep.setDataApi(handlerSecondStep.getData());
        }
    }, [groupFormation, finance, isLiberal, isSubstitute]);

    React.useEffect(() => {
        if (finance === 6) {
            setCurrentPrice(formation.price);
        }
        else {
            setCurrentPrice(0);
        }
    }, [finance]);

    React.useEffect(() => {
        if (formations != null && groupFormationApi != null) {
            if (formations.length > 0 && finance === 1) {
                setOptionIndemnity(getOptionsIndemnityByGroupFormation(groupFormationApi));
                setProgressFormStatus(2);
            }
            else {
                setProgressFormStatus(3);
            }
        }
    }, [formations, groupFormationApi]);

    React.useEffect(() => {
        if (formationSelected != null) {
            let newHours = 0;
            if (formationsSelected.includes(formationSelected.id)) {
                newHours = currentHours - formationSelected.hours;
                setFormationsSelected([...formationsSelected.filter(item => item !== formationSelected.id)]);
                setCurrentHours(newHours);
            }
            else {
                newHours = currentHours + formationSelected.hours;
                if (newHours > (optionIndemnity.totalHours * 1.2)) {
                    alert("Vous avez atteint le maximum d'heures possibles. Veuillez désélectionner une formation si vous souhaitez en choisir une différente.");
                }
                else {
                    setFormationsSelected([...formationsSelected, formationSelected.id]);
                    setCurrentHours(newHours);
                }
            }
        }
        setFormationSelected(null);
    }, [formationSelected]);

    const getGroupFormationById = (id) => {
        for (let groupFormation of optionGroupFormation) {
            if (groupFormation.value === id) {
                return groupFormation.label;
            }
        }
        return null;
    };
    const getGroupFinanceById = (id) => {
        for (let groupFinance of optionGroupFinance) {
            if (groupFinance.value === id) {
                return groupFinance.label;
            }
        }
        return null;
    };
    const getOptionsIndemnityByGroupFormation = (id) => {
        for (let optionIndemnity of optionsIndemnity) {
            if (optionIndemnity.groupFormation === id) {
                return optionIndemnity;
            }
        }
        return null;
    };

    const getFormationFormattedById = (id) => {
        console.log(id);
        console.log(formations);
        for (let formationstack of formations) {
            for (let formation of formationstack) {
                if (formation.id === id) {
                    return {
                        'numero_formation': formation.andpc,
                        'nom_formation': formation.name,
                        'duree_formation': formation.hours
                    };
                }
            }
        }
        return null;
    };

    // SCREEN
    const [isSmallScreen, setSmallScreen] = React.useState(false);
    const [hasShowImage, setHasShowImage] = React.useState(false);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'SM' || screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);

            let hasShowImageTmp = screenReducer.screen === 'LG' || screenReducer.screen === 'XL';
            if (hasShowImageTmp !== hasShowImage) setHasShowImage(hasShowImageTmp);
        }
    }, [screenReducer.screen]);

    return (
        <div className={classes.content}>
            <div style={{ width: 'calc(1440px - 200px)', margin: 'auto', maxWidth: '100%', backgroundColor: 'white' }}>
                {
                    loading === true ?
                        <div style={{ textAlign: 'center', margin: 'auto', height: '100%' }}>
                            <Fade in={true} {...{ timeout: 1000 }}>
                                <div className={classes.loader}>
                                    <div className={classes.loaderContent} style={isSmallScreen ? { width: 225 } : {}}>
                                        <ShadowBoxComponent>
                                            <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                            <div className={classes.loaderActivity}>
                                                <UseAnimations animation={activity} size={35} />
                                            </div>
                                        </ShadowBoxComponent>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        : loading === false ?
                            <>
                                <div className={`${isSmallScreen ? classes.headerSmall : classes.header}`} style={{ height: isSmallScreen ? 'auto' : '670px', }}>
                                    <div style={{ position: 'absolute', width: '100%', height: '100% ',top:0, backgroundColor: isSmallScreen ? 'rgba(255,255,255,.9)' : 'rgba(0,0,0,50%)' }}></div>
                                    <div style={{ padding: isSmallScreen ? '40px 40px 0px 40px' : '40px 40px 0px 40px', display: "flex", zIndex: 10,position: "relative"}} >
                                        <a href={'https://www.larevuedupraticien-dpc.fr'} style={{ textDecoration:"none", color: "#FFFFFF", fontSize: '25px', margin: '0', padding: 10, background: "rgb(79, 170, 170)", display: isSmallScreen ? "none" : "inline-flex", width: "fit-content", borderRadius: 7, fontWeight: "bold", alignItems: "center" }} ><ArrowLeftIcon style={{width: 32,height: 'auto',transform: "translate(-0%,10%)"}}/>Revenir sur la page programme</a>
                                        <a style={{ margin: isSmallScreen ? "auto 0 0 0" : "0 0 0 auto" }} href={'https://www.larevuedupraticien-dpc.fr'}><img src={'/asset/images/logoDPC-white.png'} alt="logo-dpc" style={{ display: 'block', width: '200px' }} /></a>
                                    </div>
                                    <div style={{ padding: isSmallScreen ? '40px 40px 40px 40px' : '144px 40px 0px 40px', position:"relative",  display: isSmallScreen ? 'bloc' : 'flex' }}>
                                        <div style={{ width: hasShowImage ? '60%' : '100%', position: 'relative' }}>
                                            <div style={{ display: isSmallScreen ? 'none' : 'block', color: '#FFF', padding: '0 32px' }}>
                                                <p style={{ fontSize: '52px', margin: '0', }}>Rejoignez l’excellence</p>
                                                <p style={{ fontSize: '118px', margin: '-30px -4px', fontWeight: 'bold' }}>médicale</p>
                                                <p style={{ fontSize: '15.6px', margin: '24px 0', }}>Avec La Revue du Praticien, formation des médecins depuis 1951</p>
                                            </div>
                                            <button style={{ display: isSmallScreen ? 'none' : 'flex', alignItems: 'center', padding: '10px', background: '#4FAAAA', border: '0', borderRadius: '6px', color: '#FFF', fontWeight: 'bold', fontSize: '22px', margin: '35px' }}>
                                                <p style={{ margin: ' 0 10px', }}>INSCRIPTION DPC</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23.616" height="13.503" viewBox="0 0 23.616 13.503">
                                                    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M18,20.679l8.93-8.937a1.681,1.681,0,0,1,2.384,0,1.7,1.7,0,0,1,0,2.391L19.2,24.258a1.685,1.685,0,0,1-2.327.049L6.68,14.14a1.688,1.688,0,0,1,2.384-2.391Z" transform="translate(-6.188 -11.246)" fill="#fff9f7" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div style={{ width: hasShowImage ? '40%' : '100%', display: isSmallScreen ? 'none' : 'block' }}>
                                            <div style={{ background: '#FFF', padding: '0px 40px', border: '2px solid rgb(79, 170, 170)', borderRadius: '12px', overflow: 'hidden', width: '31%', position: 'absolute', top: '542px', margin: 'auto', right: '0%', transform: 'translate(-6%,0%)' }}>
                                                <img src={'/asset/images/programmeDPC.png'} alt="logo" style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <Fade in={show} {...{ timeout: 750 }}>
                                    {
                                        progressFormStatus === 1 ?
                                            <ShadowBoxComponent className={`${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`}>

                                                <div style={{
                                                    fontSize: 25,
                                                    margin: 14,
                                                    width: hasShowImage ? 'calc(100% - 388px)' : '100%',
                                                    textAlign: 'left',
                                                    color: theme.colorPrimary,
                                                    fontWeight: 'bold'
                                                }}>
                                                    Formulaire d'inscription - <span style={{ fontSize: 20 }}>{formStep}/2</span>
                                                </div>

                                                {
                                                    (formStep === 1) ?
                                                        (
                                                            <>
                                                                <div style={{ width: hasShowImage ? 'calc(100% - 388px)' : '100%' }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'lastname'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'firstname'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={6}>
                                                                            <TextFieldComponent name={'email'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={6}>
                                                                            <TextFieldComponent name={'phone'} handler={handler} />
                                                                        </Grid>

                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'ips'} handler={handler} />
                                                                        </Grid>

                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'address'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={6}>
                                                                            <TextFieldComponent name={'city'} handler={handler} />
                                                                        </Grid>

                                                                        <Grid className={classes.MuiOutlinedInput} item xs={6}>
                                                                            <TextFieldComponent name={'zipCode'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <CheckboxComponent name={'conditions'} handler={handler} direction={'right'} />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <div style={{ margin: 'auto', width: 304, height: 78 }}>
                                                                                <ReCAPTCHA
                                                                                    sitekey="6LfnhnAdAAAAAE13KAvXvElcK7ZfrkemZ0Aq7Bst"
                                                                                    onChange={(value) => setIsReCAPTCHA(value)}
                                                                                />
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div style={{ textAlign: 'right', width: hasShowImage ? 'calc(100% - 388px)' : '100%' }}>
                                                                    <ButtonComponent style={{ marginTop: 14, background: theme.colorPrimary }} type="submit" label="Suivant" className={classes.button} onClick={firstStep} disabled={loading || disabled || isReCAPTCHA === null} />
                                                                </div>
                                                            </>
                                                        )
                                                        : (formStep === 2) ? (
                                                            <>
                                                                <div style={{ width: hasShowImage ? 'calc(100% - 388px)' : '100%', textAlign: 'left' }}>
                                                                    <div style={{ display: 'block', padding: '10px' }}>
                                                                        <ToggleButtonGroupComponent name={'groupFormation'} handler={handlerSecondStep} options={optionGroupFormation} onChange={(event) => (setGroupFormation(event))} />
                                                                    </div>
                                                                    <div style={{ display: 'block', padding: '10px' }}>
                                                                        <ToggleButtonGroupComponent name={'isLiberal'} handler={handlerSecondStep} options={optionQuestion} onChange={(event) => (setIsLiberal(event))} />
                                                                    </div>
                                                                    <div style={{ display: 'block', padding: '10px' }}>
                                                                        <ToggleButtonGroupComponent name={'isSubstitute'} handler={handlerSecondStep} options={optionQuestion} onChange={(event) => (setIsSubstitute(event))} />
                                                                    </div>
                                                                    <div style={{ display: 'block', padding: '10px' }}>
                                                                        <ToggleButtonGroupComponent name={'groupFinance'} handler={handlerSecondStep} options={optionGroupFinance} onChange={(event) => (setFinance(event))} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ textAlign: 'right', width: hasShowImage ? 'calc(100% - 388px)' : '100%' }}>
                                                                    <ButtonComponent style={{ marginTop: 14, background: theme.colorPrimary }} type="submit" label="Finaliser" className={classes.button} onClick={secondStep} />
                                                                </div>
                                                            </>
                                                        ) : <></>

                                                }
                                                {hasShowImage && (
                                                    (formStep === 1) ?
                                                        <div className={classes.containerResume}>
                                                            <div className={classes.Resume}>
                                                                <p style={{ margin: '0', fontSize: '25px', color: '#333', padding: '20px 0', fontWeight: 'bold' }}>Résumé</p>
                                                                <div style={{ margin: '0', color: '#333', backgroundColor: '#FFF', padding: '20px 0' }}>
                                                                    <p className={classes.title}>Formation choisie</p>
                                                                    <p className={classes.info}>{(formation == null) ? '-' : formation.name}</p>
                                                                </div>
                                                            </div>
                                                            <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo} />
                                                        </div>
                                                        :
                                                        <div className={classes.containerResume}>
                                                            <div className={classes.Resume}>
                                                                <p style={{ margin: '0', fontSize: '25px', color: '#333', padding: '20px 0', fontWeight: 'bold' }}>Résumé</p>
                                                                <div style={{ margin: '0', color: '#333', backgroundColor: '#FFF', padding: '20px 0' }}>
                                                                    <p className={classes.title}>Formation choisie</p>
                                                                    <p className={classes.info}>{(formation == null) ? '-' : formation.name}</p>
                                                                    <p className={classes.title}>Vous êtes</p>
                                                                    <p className={classes.info}>{(groupFormation == null) ? '-' : getGroupFormationById(groupFormation)}</p>
                                                                    <p className={classes.title}>Mode de financement</p>
                                                                    <p className={classes.info}>{(finance == null) ? '-' : getGroupFinanceById(finance)}</p>
                                                                </div>
                                                                <p style={{ margin: '0', fontSize: '15px', color: '#333', padding: '20px 0' }}>Prix : <span style={{ fontSize: '15px', }}>{(formation == null) ? '0' : formation.price}€</span><br /><span style={{ margin: '0', fontSize: '15px' }}><b>Reste à charge : {currentPrice}€</b></span></p>
                                                            </div>
                                                            <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo} />
                                                        </div>
                                                )
                                                }
                                            </ShadowBoxComponent>
                                            : progressFormStatus === 2 ?
                                                <ShadowBoxComponent className={`${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`}>
                                                    <div style={{
                                                        fontSize: 25,
                                                        margin: 14,
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        color: 'rgb(231, 21, 123)',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        Vous avez droit à {optionIndemnity.totalHours} heures de formation DPC!
                                                    </div>
                                                    <p style={{ textAlign: 'left' }}>En tant que {optionIndemnity.label}, vous avez droit à <b>{optionIndemnity.totalHours} heures de formation prises en charge et indemnisées</b> par l'ANDPC, soit la possibilité de valider plusieurs thèmes par an.
                                                        <br />La formation sélectionnée est de <b>{formation.hours} heures</b>. Vous pouvez encore profiter de <b>{optionIndemnity.totalHours - formation.hours} heures</b> éligibles au DPC.</p>
                                                    <h3 style={{ textAlign: 'left' }}>Choisissez un autre thème:</h3>
                                                    <p style={{ textAlign: 'left' }}>Vous avez opté pour <b>{currentHours} heures</b> de formation au total. <b>Vous allez recevoir une indemnité de {(currentHours * optionIndemnity.priceByHours > optionIndemnity.maxRemuneration) ? optionIndemnity.maxRemuneration : currentHours * optionIndemnity.priceByHours}€.</b></p>
                                                    <br />
                                                    <Carousel
                                                        autoPlay={false}
                                                        navButtonsAlwaysVisible={true}
                                                        duration={2000}
                                                        animation={'slide'}
                                                    >
                                                        {
                                                            formations.map((formationStack, index) =>
                                                                formationStack.map((formation, fIndex) =>
                                                                    <CardFormation
                                                                        show={show}
                                                                        key={fIndex}
                                                                        hours={formation.hours}
                                                                        selected={formationsSelected.includes(formation.id)}
                                                                        image={enrollmentRegisterFormationImageBanner + formation.image}
                                                                        title={formation.name}
                                                                        onClick={() => {
                                                                            setFormationSelected(formation);
                                                                        }}
                                                                        type={formation.type}
                                                                    />
                                                                )
                                                            )
                                                        }
                                                    </Carousel>

                                                    <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
                                                        <ButtonComponent type="submit" label="Non merci" className={classes.button} onClick={goEnd} />
                                                        <ButtonComponent style={{ marginLeft: 20, background: theme.colorPrimary }} type="submit" label="Valider ces inscriptions" className={classes.button} onClick={lastStep} />
                                                    </div>
                                                </ShadowBoxComponent>
                                                : progressFormStatus === 3 ?
                                                    <ShadowBoxComponent className={`${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`}>
                                                        <div style={{
                                                            fontSize: 25,
                                                            margin: 14,
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            color: 'rgb(231, 21, 123)',
                                                            fontWeight: 'bold'
                                                        }}>
                                                            Votre demande d'inscription a bien été prise en compte
                                                        </div>
                                                        <p style={{ textAlign: 'left' }}>Merci pour votre inscription ! Un conseiller va vous recontacter rapidement pour finaliser cette dernière.</p>

                                                        {

                                                            (finance === 3 && groupFormation === 1) ?
                                                                <>
                                                                    <p style={{ textAlign: 'left' }}>Votre budget FIFPL étant de 1200€ /an, vous avez la possibilité de vous inscrire à 4 formations en e-learning.</p>
                                                                    <p style={{ textAlign: 'left' }}>Choisissez d'autres thèmes pour compléter votre budget.</p>
                                                                </>
                                                                : (finance === 3 && groupFormation === 2) ?
                                                                    <>
                                                                        <p>Votre budget FIFPL en e-learning, étant de 350€ /an, vous avez la possibilité de vous inscrire à 2 formations.</p>
                                                                        <p>Choisissez un autre thème pour compléter votre budget.</p>
                                                                    </>
                                                                    : <></>

                                                        }
                                                        <p style={{ textAlign: 'left' }}>Un courriel vous a été envoyé contenant toutes les informations nécessaires à la finalisation de votre inscription. </p>
                                                        <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
                                                            <ButtonComponent style={{ marginTop: 20, background: theme.colorPrimary }} type="submit" label="ACCUEIL du site" className={classes.button} onClick={goHome} />
                                                        </div>
                                                    </ShadowBoxComponent>
                                                    : <></>
                                    }
                                </Fade>

                                <div className={classes.iconDpc} >
                                    <div>
                                        <img src={'/asset/images/dossier.jpg'} alt="logo" />
                                        <p>Dossier<br /><span>clinique progressif</span></p>
                                    </div>
                                    <div>
                                        <img src={'/asset/images/reference.jpg'} alt="logo" />
                                        <p>Références<br /><span>bibliographiques</span></p>
                                    </div>
                                    <div>
                                        <img src={'/asset/images/video.jpg'} alt="logo" />
                                        <p>Vidéos<br /><span>d’experts</span></p>
                                    </div>
                                    <div>
                                        <img src={'/asset/images/test.jpg'} alt="logo" />
                                        <p>Tests<br /><span>de connaissances</span></p>
                                    </div>
                                    <div>
                                        <img src={'/asset/images/qcm.jpg'} alt="logo" />
                                        <p>QCM</p>
                                    </div>
                                </div>
                                <div className={classes.descriptionDpc}>
                                    <h1 style={{ width: isSmallScreen ? '100%' : '500px', }}>
                                        Avec La Revue du Praticien,
                                        rejoignez l’excellence médicale !
                                    </h1>
                                    <p>
                                        Lors de sa première parution, le 1er octobre 1951, consacrée au traitement des ulcères
                                        gastroduodénaux, La Revue du Praticien se donnait pour objectif la formation des
                                        étudiants et des médecins. Soixante-dix ans plus tard, alors que plusieurs
                                        générations de futurs médecins ont préparé leur concours avec la « Revue du Prat »,
                                        celle-ci demeure, avec plus de 23.000 abonnés, la principale revue de formation médicale
                                        francophone. Ce succès jamais démenti, elle le doit à la fidélité de ses lecteurs,
                                        conséquence de la qualité de ses articles rédigés, comme le montre les liens développés
                                        avec l’Académie de médecine, par les meilleurs spécialistes. La richesse du thésaurus
                                        constitué au fil des ans et constamment actualisé justifie l’engagement de La Revue du
                                        Praticien dans le D.P.C. Celui-ci s’inscrit dans la continuité de la ligne éditoriale de
                                        la Revue et fournit à ses participants une garantie de qualité des ressources pédagogiques
                                        et des références scientifiques.<br /><br />
                                        <b>Pr Jean-Noël Fiessinger, président du comité éditorial de La Revue du Praticien, membre de l’Académie de médecine.</b>
                                    </p>
                                </div>
                                <div className={classes.avisDpc}>
                                    <h1 style={{ fontSize: isSmallScreen ? '60px' : '87px', }} >Les avis</h1>
                                    <p style={{ fontSize: isSmallScreen ? '30px' : '43px', }} >de nos médecins</p>
                                    <div className={classes.boxAvis}>
                                        <div style={{ height: isSmallScreen ? 'auto' : '192px', }}>
                                            <div><p>Dr A<br /><span>Médecin généraliste</span></p></div>
                                            <p>Formation très efficace, tant dans la documentation, que par la très grande pédagogie de l’expert.</p>
                                        </div>
                                        <div style={{ height: isSmallScreen ? 'auto' : '192px', }}>
                                            <div><p>Dr C.<br /><span>Médecin généraliste</span></p></div>
                                            <p>Satisfaction totale sans point négatif. Clair, concis, pratique et en phase avec la pratique au cabinet.</p>
                                        </div>
                                        <div style={{ height: isSmallScreen ? 'auto' : '192px', }}>
                                            <div><p>Dr S.<br /><span>Médecin généraliste</span></p></div>
                                            <p>L’accès est simple, disponible partout, les articles scientifiques étaient agréables à lire, les informations concises et utiles à notre pratique.</p>
                                        </div>
                                    </div>
                                </div>

                            </> : <></>
                }
            </div>
        </div >
    );
}

const useStyles = (theme) => makeStyles({
    MuiOutlinedInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '20px !important',
        },
    },
    title: {
        fontSize: '10px',
        margin: 0,
    },
    info: {
        fontSize: '15px',
        padding: '5px 10px',
        background: '#E6E6E6',
        width: '200px',
        margin: '5px auto 15px auto',
        borderRadius: '10px'
    },
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 'auto',
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'auto',
        boxSizing: 'border-box'
    },
    shadowBox: {
        padding: 25,
        minHeight: 600,
        margin: '80px auto',
        width: 'calc(100vw - 200px)',
        maxWidth: 1000,
        textAlign: 'center',
        boxSizing: 'border-box',
        border: '2px solid rgb(79, 170, 170)'
    },
    shadowBoxSmall: {
        margin: 'auto auto 60px auto',
        padding: 25,
        height: '100vh',
        overflow: 'scroll',
        width: '100vw',
        textAlign: 'center',
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderTop: '2px solid rgb(79, 170, 170)',
        borderBottom: '2px solid rgb(79, 170, 170)',
        borderRadius: 0
    },
    containerResume: {
        height: '100%',
        width: 388,
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        backgroundColor: 'rgb(80 170 170 / 60%)'
    },
    Resume: {
        height: 'auto',
        width: '80%',
        margin: 'auto',
        backgroundColor: '#E6E6E6',
        borderRadius: '5px',
        boxShadow: '0px 0px 3px rgba(0,0,0,.2)'
    },
    logo: {
        width: '65%',
        maxWidth: 130,
        margin: 'auto',
        position: 'absolute',
        bottom: '20px',
        right: '20px'
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    header: {
        backgroundImage: `url("/asset/images/imgHeaderDPC.png")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        borderBottom: '6px solid rgb(79, 170, 170)'
    },
    headerSmall: {
        backgroundImage: `url("/asset/images/imgHeaderDPC.png")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'block',
    },
    iconDpc: {
        display: 'flex',
        flexWrap: 'wrap-reverse',
        gap: '26px 10px',
        width: 'calc(119vw - 200px)',
        maxWidth: 1028,
        margin: 'auto',
        '& > div': {
            width: '169px',
            height: '131px',
            boxShadow: '0px 1px 4px #361B40',
            borderRadius: '10px',
            margin: 'auto',
            overflow: 'hidden',
            textAlign: 'center',
            background: '#FFF'
        },
        '& > div > img': {
            width: '70px',
        },
        '& > div > p': {
            margin: 0,
            fontWeight: 'bold',
            fontSize: '15px'
        },
        '& > div > p > span': {
            fontWeight: 'initial',
            color: 'rgba(0,0,0,60%)'
        }
    },
    descriptionDpc: {
        width: 'calc(123vw - 200px)',
        maxWidth: 1000,
        margin: 'auto',
        '& > h1': {
            color: '#361B40',
            boxShadow: 'inset 120px 0px 0px rgba(79,170,170, 50%)',
            padding: '19px 10px'
        },
        '& > p': {
            color: '#7C7C7C',
        },
    },
    avisDpc: {
        width: '100%',
        background: 'rgba(79,170,170, 40%)',
        borderBottom: '30px solid #361B40',
        '& > h1': {
            width: 'calc(123vw - 200px)',
            maxWidth: 1007,
            color: '#361B40',
            padding: '19px 0px',
            margin: '0 auto',
        },
        '& > p': {
            width: 'calc(123vw - 200px)',
            maxWidth: 1007,
            color: '#FFFF',
            padding: '0px',
            margin: '0 auto',
            transform: 'translate(0,-70%)',
        },
    },
    boxAvis: {
        display: 'flex',
        width: 'calc(119vw - 200px)',
        flexWrap: 'wrap-reverse',
        gap: '26px 10px',
        maxWidth: 1028,
        margin: 'auto',
        padding: '0 0 70px 0',
        '& > div': {
            width: '322px',
            boxShadow: '0px 1px 4px #361B40',
            borderRadius: '10px',
            margin: 'auto',
            overflow: 'hidden',
            textAlign: 'left',
            background: '#FFF'
        },
        '& > div > div': {
            padding: ' 0 20px',
            color: '#000',
            fontWeight: 'bold'
        },
        '& > div > div > p > span': {
            fontWeight: 'initial',
            color: '#7C7C7C',
        },
        '& > div > p': {
            padding: ' 0 20px',
            lineHeight: '25px',
            color: '#7C7C7C',
        },
    },
});

export default RegisterGMS;
