import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import Api, {learnerHomeFormationImageBanner} from "../../../Api";
import { Fade, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import CardFormation from './Component/CardFormation';

const Document = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const dispatch = useDispatch();
    const memoryReducer = useSelector(state => state.MemoryReducer);
    const [formationsLeaner, setFormationsLeaner] = React.useState([]);
    const [infoLeaner, setInfoLeaner] = React.useState([]);
    const [show, setShow] = React.useState(true);


    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {

        setBreadcrumbs({
            currentPage: 'Mes documents',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });

        dispatch(LinearProgressMainLayoutActivate());

        // Redirect auto catalog
        if (memoryReducer.registerFormation) {
            props.history.push(getRoutePathname('learner_catalog'));
        }
        else {
            Api.get({
                route: 'learner_document_info'
            },
                (response) => {
                    setFormationsLeaner(response.data.formations);
                    setInfoLeaner(response.data.info);
                    setTimeout(() => {
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        setLoading(false);
                    }, 1000);
                });
        }

    }, []);
    
    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{ textAlign: 'center', maxWidth: '80%', margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <div style={{ overflow: 'SM', paddingTop: 20, paddingBottom: 20 }}>
                            <Grid container spacing={3}>

                                {
                                    formationsLeaner.length > 0 ?
                                        formationsLeaner.map((formationLeaner, index) =>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <CardFormation
                                                    show={show}
                                                    key={index}
                                                    image={learnerHomeFormationImageBanner + formationLeaner.image}
                                                    title={formationLeaner.title}
                                                    pathFormationSyntheses={formationLeaner.pathFormationSyntheses}
                                                    registration={formationLeaner.registration}
                                                />
                                            </Grid>
                                        )
                                        :
                                        <ShadowBoxComponent className={classes.shadowBox}>
                                            <p style={{ textAlign: 'center' }}>Vous ne disposez pour l'instant d'aucun document.</p>
                                        </ShadowBoxComponent>
                                }
                            </Grid>
                        </div>
                }
            </div>
        </>
    );
};

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    }
});

export default Document;