import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import { Box, makeStyles } from "@material-ui/core";
import CommentIcon from '@material-ui/icons/Comment';
import FilterPaginationComponent from '../../../../Component/FilterPaginationComponent';
import ButtonComponent from "../../../../Component/ButtonComponent";
import Badge from '@material-ui/core/Badge';
import EditorComponent from "../../../../Component/EditorComponent";
import Api from "../../../../Api";
import { theme } from "../../../../App";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getRoutePathname } from "../../../../Config/Route";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import FormationPostCommentList from './FromationPostCommentList';
import FormationPostGroup from './FormationPostGroup';
import FormationPostProspectList from './FormationPostProspectList';
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";

function GroupFormationPost(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const [group, setGroup] = React.useState([]);
    const [prospectList, setProspectList] = React.useState([]);
    const [commentList, setCommentList] = React.useState([]);
    const [communityPost, setCommunityPost] = React.useState([]);
    const filter = [
        {
            data: 'lastname',
            type: 'textField'
        },
        {
            data: 'firstname',
            type: 'textField'
        },
    ];
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Écrire un commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoad(true)
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'community_group_formation_post_comment_add',
                data: handler.getData(),
                params: {
                    groupFormation: props.match.params.groupFormationId,
                    post: props.match.params.id
                }
            }, (response) => {
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 201) {
                    loadData()
                    handler.reset();
                    dispatch(
                        SnackbarOpen({
                            text: 'Commentaire enregistrer.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const loadData = () => {
        setLoad(true)
        dispatch(LinearProgressMainLayoutActivate());
        if (props.match.params.id && props.match.params.groupFormationId) {
            Api.get({
                route: 'community_group_formation_post_comment_list',
                params: { groupFormation: props.match.params.groupFormationId, post: props.match.params.id }
            },
                (response) => {
                    if (response.data) {
                        let data = response.data;
                        for (let index in data.commentList) {
                            data.commentList[index].createdAt = moment(data.commentList[index].createdAt).format("lll");
                            if (data.commentList[index].responsProspect)
                                data.commentList[index].responsProspect.createdAt = moment(data.commentList[index].responsProspect.createdAt).format("lll");
                        }
                        setGroup(data.group);
                        setProspectList(data.prospectList);
                        setCommentList(data.commentList);
                        setLoad(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else {
                        setGroup([]);
                        setProspectList([]);
                        setCommentList([]);
                    }
                });
        } else {
            setGroup([]);
            setProspectList([]);
            setCommentList([]);
        }
        if (props.match.params.id && props.match.params.groupFormationId) {
            Api.get({
                route: 'community_group_formation_post_get',
                params: {
                    groupFormation: props.match.params.groupFormationId,
                    id: props.match.params.id
                }
            },
                (response) => {
                    setReadyContent(true);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        data.createdAt = moment(data.createdAt).format("lll");
                        setCommunityPost(data);
                    }
                    else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            setCommunityPost([]);
        }
    }

    const viewFormComment = (id) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <Box className={classes.boxComponent} style={{ textAlign: 'center' }}>
                    <Grid container spacing={2} style={{width:'90%', margin:'auto'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className={classes.titleForm}>Répondre au poste</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <EditorComponent name={'comment'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ButtonComponent className={classes.buttonComment} label={'Commenter'} onClick={() => { save(id) }} disabled={load} />
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        )
    }
    const viewList = (dataList) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <>
                    <FormationPostCommentList
                        data={dataList}
                        loadData={loadData}
                        params={{
                            communityGroup: props.match.params.groupFormationId,
                            communityPost: props.match.params.id
                        }}
                    />
                </>
            </Fade>
        )
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        loadData();
        setBreadcrumbs({
            title: 'Discussion',
            context: 'Forum',
            description: '',
            links: [
                { path: getRoutePathname('community_group_formation_list'), label: 'Liste des Groupes' },
                { path: getRoutePathname('community_group_formation', { id: props.match.params.groupFormationId }), label: 'Groupe' },
            ]
        });
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={isReadyContent} {...{ timeout: 500 }}>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                            <>
                                <Fade in={true} {...{ timeout: 500 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box className={classes.boxComponent}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} style={{ display: 'flex' }}>
                                                    {communityPost.status === 'Admin' ?
                                                        <Badge color="secondary" badgeContent={'A'} showZero >
                                                            <Avatar className={classes.avatar}>{communityPost.firstname && communityPost.firstname.charAt().toUpperCase()}{communityPost.lastname && communityPost.lastname.charAt().toUpperCase()}</Avatar>
                                                        </Badge> :
                                                        <Avatar className={classes.avatar}>{communityPost.firstname && communityPost.firstname.charAt().toUpperCase()}{communityPost.lastname && communityPost.lastname.charAt().toUpperCase()}</Avatar>
                                                    }
                                                    <Box style={{ padding: '0 10px' }}>
                                                        <p style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", color: "#495666" }}>{communityPost.title}</p>
                                                        <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                                            <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> post créer par <span className={classes.nameProspect}>{communityPost.firstname && communityPost.firstname.charAt().toUpperCase()}.{communityPost.lastname}</span> le {communityPost.createdAt}
                                                        </p>
                                                        <p className={classes.subTitle} style={{ margin: 0, padding: "10px 0" }} dangerouslySetInnerHTML={{ __html: communityPost.subTitle }} />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                    <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "5px 0 0 0" }} >
                                                        <p style={{ margin: 0, textAlign: "right" }}><CommentIcon style={{ transform: "translate(-18%,41%)" }} />{communityPost.numComment > 99 ? '99+' : communityPost.numComment} Commentaires</p>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <br />
                                    </Grid>
                                </Fade>
                                <FilterPaginationComponent
                                    data={commentList}
                                    title={'Commentaire'}
                                    icon={<CommentIcon />}
                                    filter={filter}
                                    pageNumber={7}
                                    viewList={viewList}
                                    loading={load}
                                    loadData={loadData}
                                    error={'Aucun commentaire'}
                                    className={classes.commentList}
                                />
                                <br />
                                {viewFormComment()}
                                <br />
                            </>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Grid item xs={12} sm={12} md={6} lg={12} xl={12} style={{margin: "auto"}}>
                                    <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                                        <Box>
                                            <FormationPostGroup data={group} />
                                        </Box>
                                    </Slide>
                                </Grid>
                                <br/>
                                <Grid item xs={12} sm={12} md={6} lg={12} xl={12} style={{ margin: "auto", position: 'sticky', top: 22 }}>
                                    <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                                        <Box>
                                            <FormationPostProspectList data={prospectList} />
                                        </Box>
                                    </Slide>
                                </Grid>
                            </Grid>
                    </Grid>
                </Box>

            </Fade>
        </ContentViewComponent >

    );
}

const useStyles = (theme) => makeStyles(({
    circularProgress: {
        margin: 10,
        color: theme.colorPrimary
    },
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    titleForm: {
        color: "#5E6E82",
        fontSize: 20,
        margin: "auto",
        fontWeight: "bold",
        borderLeft: "10px solid",
        padding: " 10px 30px",
        textAlign: "left",
    },
    title: {
        margin: 0,
        fontSize: 21,
        fontWeight: "bold",
        color: "#495666"
    },
    shadowBoxComponent: {
        paddingBottom: 60,
        height: '100%'
    },
    shadowBox: {
        padding: 0,
        textAlign: 'center',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    cardImagePreload: {
        height: '84%',
        width: '100%',
        maxWidth: 280,
        position: 'absolute',
        transform: 'translate(-0%, -50%)',
        borderRadius: 10,
        border: '3px solid rgb(223, 223, 223)',
        top: '50%',
        left: '0%',
        zIndex: 1
    },
    cardImage: {
        height: '84%',
        width: '100%',
        maxWidth: 280,
        position: 'absolute',
        transform: 'translate(-0%, -50%)',
        borderRadius: 10,
        border: '3px solid rgb(223, 223, 223)',
        top: '50%',
        left: '0%',
        zIndex: 0
    },
    cardText: {
        color: '#228B9F',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    buttonComment: {
        borderRadius: "5px",
        padding: "0px 30px",
        fontSize: 15,
        textTransform: "initial",
        fontWeight: "bolder",
        background: "#c8dfe5 !important",
        boxShadow: "0 0",
        border: "4px solid #c8dfe5",
        color: "#5E6E82"
    },
    buttonV1: {
        textTransform: "initial",
        fontSize: 15,
        padding: "5px 33px",
        boxShadow: "initial",
        borderRadius: "0px 5px 5px 0px",
        border: "1px solid rgb(3, 169, 244)"
    },
    buttonV2: {
        textTransform: "initial",
        fontSize: 15,
        padding: "5px 33px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(3, 169, 244)",
        color: "rgb(3, 169, 244)"
    },
    titleGroup: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: "left",
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    nameProspect: {
        color: theme.colorPrimary
    },
    commentList: {
        '& .MuiBox-root-144': {
            background: "#FFF",
            borderRadius: 20,
            border: '1px solid rgb(223, 223, 223)',
        },
        '& .MuiBox-root-144 .MuiIconButton-root': {
            margin: '0 13px 0 auto !important',
        }
    }
}));

export default GroupFormationPost;
