import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {makeStyles} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Api, {contentManagerFormationImageBanner} from "../../../Api";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import SelectComponent from "../../../Component/SelectComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Config/Route";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../Component/TabsComponent";
import ANDPC from "./ANDPC";
import FormationChapter from "./FormationChapter";
import FormationResource from "./FormationResource";
import FormationQuestion from "./FormationQuestion";
import FormationQuestionEpp from "./FormationQuestionEpp";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import FIFPL from "./FIFPL";
import CPF from "./CPF";
import FormationSession from './FormationSession';

function Formation(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [isPrePost, setPrePost] = React.useState(false);
    const [isSurveyEpp, setIsSurveyEpp] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [load, setLoad] = React.useState(false);
    const [form, setForm] = React.useState({
        id: {
            name: 'id',
            label: 'ID',
            textHelper: 'Saisissez l\'ID de la formation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de la formation.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        description: {
            name: 'description',
            label: 'Description',
            textHelper: 'Saisissez la description de la formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        orientation: {
            name: 'orientation',
            label: 'Orientation',
            textHelper: 'Saisissez l\'orientation de la formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        descriptionOffline: {
            name: 'descriptionOffline',
            label: 'Description Hors Connexion',
            textHelper: 'Saisissez la description de la formation hors connexion.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe',
            textHelper: 'Choisissez le groupe de la formation.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        duration: {
            name: 'duration',
            label: 'Durée en seconde',
            textHelper: 'Saisissez la durée totale de la formation (C+CV+HC en second).',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        introductionEpp:{
            name: 'introductionEpp',
            label: 'Introduction questionnaire EPP',
            textHelper: 'Saisissez une introduction pour le questinnaire EPP.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        durationOffline: {
            name: 'durationOffline',
            label: 'Durée en seconde',
            textHelper: 'Saisissez la durée de la formation hors connexion (en second).',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        objectives: {
            name: 'objectives',
            label: 'Objectifs',
            textHelper: 'Saisissez l\'objectifs de la formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        organismFormation: {
            name: 'organismFormation',
            label: 'Organisme',
            textHelper: 'Saisissez l\'organisme de la formation.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        priceHT: {
            name: 'priceHT',
            label: 'Prix',
            textHelper: 'Saisissez le prix de la formation.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        surveySatisfaction: {
            name: 'surveySatisfaction',
            label: 'Questionnaire de satisfaction',
            textHelper: 'Activez ou désactivez ce questionnaire.',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        surveyPre: {
            name: 'surveyPre',
            label: 'Questionnaire Pre',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        surveyPost: {
            name: 'surveyPost',
            label: 'Questionnaire Post',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        surveyEpp: {
            name: 'surveyEpp',
            label: 'Questionnaire Pre/Post EPP',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        honorCertificate: {
            name: 'honorCertificate',
            label: 'Attesttation sur l\'honneur suivi de formation',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Types de financement',
            textHelper: 'Saisissez les types de financement de la formation.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        forcedUnlock: {
            name: 'forcedUnlock',
            label: 'Débloquage des cours',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        isResponsive: {
            name: 'isResponsive',
            label: 'Formation disponible sur tablette / mobile',
            textHelper: '',
            type: 'boolean',
            defaultValue: true,
            options: {}
        },

    });
    const getFormFormation = (id) => {
        Api.get({
                route: 'content_manager_formation_get',
                params: {id: id}
            },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let data = response.data;
                    setImage(data.id + '.webp');
                    handler.setDataApi(data);
                    (data.surveyPre === true || data.surveyPost === true) ? setPrePost(true) : setPrePost(false);
                    (data.surveyEpp === true) ? setIsSurveyEpp(true) : setIsSurveyEpp(false);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('content_manager_formation_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setTimeout(() => setReadyContent(true), 250);
            });
    }
    const [image, setImage] = React.useState('');
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                if (props.match.params.id) {
                    Api.post({
                        route: 'content_manager_formation_edit',
                        data: handler.getData(),
                        params: {id: props.match.params.id}
                    }, (response) => {
                        handler.setFormLoading(false);
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());

                        if (response.status === 200) {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Formation modifiée.',
                                    variant: 'success',
                                })
                            );
                            getFormFormation(props.match.params.id);
                        } else if (response.status === 400) {
                            handler.setErrorApi(response.error);
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_formation_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('content_manager_formation', {id: datas.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Formation enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    const remove = () => {
        handler.setFormLoading(true);
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'content_manager_formation_delete',
            params: {id: props.match.params.id}
        }, (response) => {
            handler.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                props.history.push(getRoutePathname('content_manager_formation_list'));
                dispatch(
                    SnackbarOpen({
                        text: 'Formation supprimé.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
                if (response.error && response.error.message) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    // ALL
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
                route: 'select_formation_groups'
            },
            (response) => {
                if (response && response.data) {
                    setOptionFormationGroup(response.data);
                }
            });
        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
        Api.get({
                route: 'select_finance_types',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionFinanceType(response.data);
                }
            });
        setReadyContent(false);


        if (props.match.params.id) {
            setIsEdition(true);
            setBreadcrumbs({
                title: 'Formations',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    {path: getRoutePathname('content_manager_formation_list'), label: 'Liste des formations'}
                ]
            });

            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            getFormFormation(props.match.params.id);
        } else {
            setIsEdition(false);
            setReadyContent(true);
            setBreadcrumbs({
                title: 'Ajouter une formation',
                context: 'Gestion de contenu',
                description: ''
            });
            setIsSurveyEpp(true);
        }
    }, [props.match.params.id]);
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                {
                    isEdition &&
                    <Fade in={true} {...{timeout: 1000}}>
                        <Grid style={{minHeight: '100%'}} item xs={12} sm={12} md={12} lg={isEdition ? 3 : 12} xl={isEdition ? 3 : 12}>
                            <ShadowBoxComponent style={{minHeight: '100%'}}>
                                {!load && <img src="/asset/images/preload_image.png" className={classes.imagePreload} alt={'Image formation'}/>}
                                <img className={classes.image} src={image ? (contentManagerFormationImageBanner + image) : ''} onLoad={() => setLoad(true)} alt={'Image formation'}/>
                            </ShadowBoxComponent>
                        </Grid>
                    </Fade>
                }
                <Fade in={true} {...{timeout: 750}}>
                    <Grid style={{minHeight: '100%'}} item xs={12} sm={12} md={12} lg={isEdition ? 9 : 12} xl={isEdition ? 9 : 12}>
                        <ShadowBoxComponent style={{minHeight: '100%'}} className={classes.shadowBox}>
                            <TitleComponent title={isEdition ? <>Formulaire d'édition de la formation</> : <>Formulaire de création d'une formation</>}/>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'id'} handler={handler} disabled={isEdition}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'name'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <MultipleSelectComponent name={'financeType'} handler={handler} options={optionFinanceType}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <MultipleSelectComponent name={'organismFormation'} handler={handler} options={optionOrganism}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <TextFieldComponent name={'duration'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <TextFieldComponent name={'priceHT'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <TextFieldComponent name={'durationOffline'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'description'} handler={handler} multiline={true}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'orientation'} handler={handler} multiline={true}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'descriptionOffline'} handler={handler} multiline={true}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'objectives'} handler={handler} multiline={true}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'introductionEpp'} handler={handler} multiline={true} disabled={!isSurveyEpp}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={6} xl={6}>
                                    <CheckboxComponent name={'surveyEpp'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <CheckboxComponent name={'honorCertificate'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <CheckboxComponent name={'surveySatisfaction'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <CheckboxComponent name={'surveyPre'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <CheckboxComponent name={'surveyPost'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <CheckboxComponent name={'forcedUnlock'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <CheckboxComponent name={'isResponsive'} handler={handler}/>
                                </Grid>
                            </Grid>

                            <div className={classes.button}>
                                <ButtonComponent label={'Enregistrer'} className={classes.buttonSave} onClick={save} disabled={loading}/>
                            </div>

                            {isEdition && <div className={classes.button}>
                                <ButtonComponent label={'Supprimer'} color={'#dc3545'} className={classes.buttonDelete} onClick={remove} disabled={loading}/>
                            </div>}
                        </ShadowBoxComponent>
                    </Grid>
                </Fade>
            </Grid>

            <br/>
            {
                isEdition &&
                <Slide direction={'up'} in={true} {...{timeout: 500}}>
                    <div>
                        <TabsComponent
                            tabDefault={'andpc'}
                            tabs={[
                                {id: 'andpc', label: 'Gestion ANDPC'},
                                {id: 'fifpl', label: 'Gestion FIFPL'},
                                {id: 'cpf', label: 'Gestion CPF'},
                                {id: 'chapters', label: 'Chapitres'},
                                {id: 'resource', label: 'Ressources'},
                                {id: 'session', label: 'Sessions'},
                                {id: 'question', label: 'Questions', disabled: !isPrePost},
                                {id: 'questionEpp', label: 'Questions EPP', disabled: !isSurveyEpp}
                            ]}
                        >
                            <div key={'andpc'}>
                                <ANDPC {...props} />
                            </div>
                            <div key={'fifpl'}>
                                <FIFPL {...props} />
                            </div>
                            <div key={'cpf'}>
                                <CPF {...props} />
                            </div>
                            <div key={'chapters'}>
                                <FormationChapter {...props} />
                            </div>
                            <div key={'resource'}>
                                <FormationResource {...props} />
                            </div>
                            <div key={'session'}>
                                <FormationSession {...props} />
                            </div>
                            {
                                isPrePost &&
                                <div key={'question'}>
                                    <FormationQuestion {...props} />
                                </div>
                            }
                            {
                                isSurveyEpp &&
                                <div key={'questionEpp'}>
                                    <FormationQuestionEpp {...props} />
                                </div>
                            }

                        </TabsComponent>
                    </div>
                </Slide>
            }

            <br/>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    }
});

export default Formation;
