import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Delete, Edit } from "@material-ui/icons";
import { getRoutePathname } from "../../../Config/Route";

export default function VirtualClassroomUser(props = {}) {

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'lastname',
            label: 'Nom',
            options: { filter: true, sort: true }
        },
        {
            name: 'firstname',
            label: 'Prénom',
            options: { filter: true, sort: true }
        },
        {
            name: 'email',
            label: 'Email',
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Numéro de téléphone",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser'} placement="left">
                        <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', {id: row.id}))}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'order', direction: 'desc' }}
                id={'content_manager_virtual_classroom_user_list'}
                title={'Participants lié a cette classe virtuelle : '}
                columns={columns}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_virtual_classroom_user_list',
                            params: { virtualclassroom: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                console.log(data);
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />
        </>
    )
}
