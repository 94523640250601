import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Assignment} from "@material-ui/icons";
import {getRoutePathname} from "../../../Config/Route";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {Dialog, DialogContent, makeStyles} from "@material-ui/core";
import Import from "../ProspectList/Import";
import {hasRight} from "../../../Tool/UserTool";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import {TableReload} from "../../../Action/TableAction";
import {cacheTablePage, cacheTableServer} from "../../../Cache";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {inArray} from "../../../Tool/ArrayCommon";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import Button from "@material-ui/core/Button";
import Check from "./Check";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import Assign from "../ProspectList/Assign";
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import LoginAsComponent from '../../../Component/LoginAsComponent';

function ProspectList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [showUpload, setShowUpload] = React.useState(false);
    const [showCheck, setShowCheck] = React.useState(false);
    const [showAssign, setShowAssign] = React.useState(false);
    const [optionProspectStates, setOptionProspectStates] = React.useState([]);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionFinanceTypes, setOptionFinanceTypes] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);
    const [loadDownload, setLoadDownload] = React.useState(false);

    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "ips",
            label: "IPS",
            options: {filter: true, sort: true}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "phone",
            label: "Téléphone",
            options: {filter: true, sort: true}
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {filter: true, sort: true}
        },
        {
            name: "prospectStateLabel",
            label: "État",
            options: {filter: false, sort: true}
        },
        {
            name: "assign",
            label: "Assigné",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        },
        {
            name: "organismName",
            label: "Organisme",
            options: {filter: true, sort: false}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row, list) => {
        return (
            <div style={{width: 88}}>
                {
                    (
                        hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') ||
                        hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') ||
                        hasRight(authenticationReducer, 'ROLE_CRM_ADMIN') ||
                        hasRight(authenticationReducer, 'ROLE_CRM')
                    ) &&
                    <LoginAsComponent email={row.email} organism={row.organismId} />
                }
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', {id: row.id}), {list: list})}>
                        <Assignment/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    // Search
    const [formSearch, setFormSearch] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].commercial ? cacheTableServer['crm_prospect_list'].commercial : 'all',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].state ? cacheTableServer['crm_prospect_list'].state : 'all',
            options: {}
        },
        text: {
            name: 'text',
            label: 'Recherche',
            textHelper: 'Saisissez votre recherche.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].text ? cacheTableServer['crm_prospect_list'].text : '',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].formationGroup ? cacheTableServer['crm_prospect_list'].formationGroup : 'all',
            options: {}
        },
        clientFilter: {
            name: 'clientFilter',
            label: 'Type',
            textHelper: 'Trier par type.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].clientFilter ? cacheTableServer['crm_prospect_list'].clientFilter : 'all',
            options: {}
        },
        pole: {
            name: 'pole',
            label: 'Pôle',
            textHelper: 'Trier par pôle.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].pole ? cacheTableServer['crm_prospect_list'].pole : 'all',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].organism ? cacheTableServer['crm_prospect_list'].organism : 'all',
            options: {}
        },
        registrationAt: {
            name: 'registrationAt',
            label: 'Aucune inscription depuis le...',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].registrationAt ? cacheTableServer['crm_prospect_list'].registrationAt : '',
            options: {}
        },
        hasPhone: {
            name: 'hasPhone',
            label: 'Téléphone obligatoire',
            textHelper: '',
            type: 'boolean',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].hasPhone ? cacheTableServer['crm_prospect_list'].hasPhone : '',
            options: {}
        },
        hasFormationGroup: {
            name: 'hasFormationGroup',
            label: 'Profession obligatoire',
            textHelper: '',
            type: 'boolean',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].hasFormationGroup ? cacheTableServer['crm_prospect_list'].hasFormationGroup : '',
            options: {}
        },
        financeTypes: {
            name: 'financeTypes',
            label: 'En ne tenant compte que...',
            textHelper: 'Saisissez les types de financement.',
            type: 'array',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].financeTypes ? cacheTableServer['crm_prospect_list'].financeTypes : '',
            options: {}
        }
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['crm_prospect_list'] = 0;
        cacheTableServer['crm_prospect_list'].page = 0;
        cacheTableServer['crm_prospect_list'][index] = value;
        dispatch(TableReload('crm_prospect_list'))
    };


    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Contacts',
            context: 'CRM',
            description: 'Liste des contacts.'
        });

        setReadyContent(true);

        Api.get({
                route: 'select_prospect_states'
            },
            (response) => {
                if (response && response.data) {
                    setOptionProspectStates([...[{value: 'all', label: 'Tous'}], ...response.data]);
                }
            });
        Api.get({
                route: 'crm_commercial_list'
            },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([...[{value: 'all', label: 'Tous'}, {value: 0, label: 'Aucun'}], ...response.data]);
                }
            });
        Api.get({
                route: 'select_poles'
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionPoles(data);
                }
            });
        Api.get({
                route: 'select_organisms'
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });

        Api.get({
                route: 'select_formation_groups'
            },
            (response) => {
                if (response && response.data) {
                    let data = [{value: 'all', label: 'Tous'}, ...response.data];
                    setOptionFormationGroup(data);
                }
            });

        Api.get({
                route: 'select_finance_types',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionFinanceTypes(response.data);
                }
            });
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextFieldComponent name={'text'} handler={handlerSearch} onChange={(val) => inputSearchChange('text', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'clientFilter'} handler={handlerSearch} options={[{label: 'Tous', value: 'all'}, {label: 'Prospects', value: '0'}, {label: 'Clients', value: '1'}, {label: 'Archivés', value: '2'}]} onChange={(val) => inputSearchChange('clientFilter', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <DatePickerComponent name={'registrationAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('registrationAt', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <MultipleSelectComponent name={'financeTypes'} disabled={!formSearch['registrationAt'].value} handler={handlerSearch} options={optionFinanceTypes} onChange={(val) => inputSearchChange('financeTypes', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'pole'} handler={handlerSearch} options={optionPoles} onChange={(val) => inputSearchChange('pole', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercials} onChange={(val) => inputSearchChange('commercial', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <SelectComponent name={'state'} handler={handlerSearch} options={optionProspectStates} onChange={(val) => inputSearchChange('state', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <CheckboxComponent name={'hasPhone'} handler={handlerSearch} onChange={(val) => inputSearchChange('hasPhone', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <CheckboxComponent name={'hasFormationGroup'} handler={handlerSearch} onChange={(val) => inputSearchChange('hasFormationGroup', val)}/>
                            </Grid>
                        </Grid>
                        <div style={isTall ? {height: 35, marginTop: 10} : {marginTop: 10}}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={
                                    isTall ?
                                        {float: 'right', marginLeft: 10, fontSize: 12, opacity: 0.9, background: '#28a745'} :
                                        {width: 'calc(100% - 20px)', marginLeft: 10, fontSize: 12, opacity: 0.9, background: '#28a745'}
                                }
                                onClick={() => setShowCheck(true)}>
                                Vérification disponibilité
                            </Button>
                            {(hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_ADMIN')) && <Button
                                disabled={loadDownload}
                                variant="contained"
                                color="primary"
                                style={
                                    isTall ?
                                        {float: 'right', marginLeft: 10, fontSize: 12, opacity: 0.9} :
                                        {width: 'calc(100% - 20px)', marginLeft: 10, fontSize: 12, opacity: 0.9, marginTop: 10}
                                } onClick={() => {
                                setLoadDownload(true);
                                let datas = handlerSearch.getData();
                                let filters = {
                                    text: formSearch.text.value ? formSearch.text.value : '',
                                    commercial: (datas.commercial === 'all' || !datas.commercial) ? '' : datas.commercial,
                                    state: (datas.state === 'all' || !datas.state) ? '' : datas.state,
                                    clientFilter: (formSearch.clientFilter.value === 'all') ? '' : formSearch.clientFilter.value,
                                    pole: (formSearch.pole.value === 'all') ? '' : formSearch.pole.value,
                                    organism: (formSearch.organism.value === 'all') ? '' : formSearch.organism.value,
                                    formationGroup: (formSearch.formationGroup.value === 'all') ? '' : formSearch.formationGroup.value,
                                    registrationAt: formSearch.registrationAt.value ? formSearch.registrationAt.value : '',
                                    financeTypes: formSearch.financeTypes.value ? formSearch.financeTypes.value : '',
                                    hasPhone: formSearch.hasPhone.value ? formSearch.hasPhone.value : '',
                                    hasFormationGroup: formSearch.hasFormationGroup.value ? formSearch.hasFormationGroup.value : '',
                                };

                                Api.getDownload({
                                    route: 'crm_prospect_list',
                                    filename: 'export_contact.xls',
                                    data: filters
                                }, (response) => {
                                    if (response.status === 200) {
                                        setLoadDownload(false)
                                    }
                                })
                            }}>
                                Télécharger au format Excel
                            </Button>}
                        </div>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br/>

            <TableComponent
                id={'crm_prospect_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Contacts'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un contact',
                    onClick: () => props.history.push(getRoutePathname('crm_prospect', {id: null}))
                }}
                actionSecond={hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') && {
                    label: 'Importer des contacts',
                    onClick: () => setShowUpload(true)
                }}
                actionThird={(
                    hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN')
                ) && {
                    label: 'Assigner des contacts',
                    onClick: () => setShowAssign(true)
                }}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        text: formSearch.text.value ? formSearch.text.value : '',
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        commercial: (datas.commercial === 'all' || !datas.commercial) ? '' : datas.commercial,
                        state: (datas.state === 'all' || !datas.state) ? '' : datas.state,
                        clientFilter: (formSearch.clientFilter.value === 'all') ? '' : formSearch.clientFilter.value,
                        pole: (formSearch.pole.value === 'all') ? '' : formSearch.pole.value,
                        organism: (formSearch.organism.value === 'all') ? '' : formSearch.organism.value,
                        formationGroup: (formSearch.formationGroup.value === 'all') ? '' : formSearch.formationGroup.value,
                        registrationAt: formSearch.registrationAt.value ? formSearch.registrationAt.value : '',
                        financeTypes: formSearch.financeTypes.value ? formSearch.financeTypes.value : '',
                        hasPhone: formSearch.hasPhone.value ? formSearch.hasPhone.value : '',
                        hasFormationGroup: formSearch.hasFormationGroup.value ? formSearch.hasFormationGroup.value : '',
                    };
                    Api.get({
                            route: 'crm_prospect_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;

                            for (let index in data) {
                                data[index].assign = data[index].commercialId === authenticationReducer.id ? 'Vous' : (data[index].commercialName ?? '-');
                                data[index].action = getAction(data[index], response.data.list);
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                            }

                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br/>

            <Dialog open={showUpload} maxWidth={'lg'} onClose={() => setShowUpload(false)}>
                <DialogContent>
                    <div style={{width: '60vw', maxWidth: 500}}>
                        <Import onClose={() => setShowUpload(false)}/>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={showCheck} maxWidth={'lg'} onClose={() => setShowCheck(false)}>
                <DialogContent>
                    <div style={{width: '60vw', maxWidth: 500}}>
                        <Check {...props} onClose={() => setShowCheck(false)}/>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={showAssign} maxWidth={'lg'} onClose={() => setShowAssign(false)}>
                <DialogContent>
                    <div style={{width: '60vw', maxWidth: 500}}>
                        <Assign filter={handlerSearch} onClose={() => setShowAssign(false)}/>
                    </div>
                </DialogContent>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});
export default ProspectList;
