import React from 'react';
import { Grid, makeStyles, Typography } from "@material-ui/core";
import CardFormation from "./Component/CardFormation";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { getRoutePathname } from "../../../Config/Route";
import { theme } from "../../../App";
import moment from 'moment';
import EventBoxComponent from '../../../Component/EventBoxComponent';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { AuthenticationLogin } from '../../../Action/AuthenticationAction';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import PersonIcon from '@material-ui/icons/Person';
import { Description, Settings } from '@material-ui/icons';
import LayersIcon from '@material-ui/icons/Layers';
import BoxLearnerComponent from '../../../Component/BoxLearnerComponent';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import CardResourceType from './Component/CardResourceType';

function Home(props) {
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const memoryReducer = useSelector(state => state.MemoryReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [formationsLeaner, setFormationsLeaner] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [timeProcessing, setTimeProcessing] = React.useState();
    const [isEventBoxShow, setIsEventBoxShow] = React.useState(false);
    const [stateEventBoxContent, setStateEventBoxContent] = React.useState();
    const currentYear = moment().year();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [countDataFormation, setCountDataFormation] = React.useState({
        countDataPending: '',
        countDataInProgress: ''
    });
    const [libraryResourceTypes, setLibraryResourceTypes] = React.useState([]);
    const goRegistration = (registrationId, organismId) => {

        if (parseInt(organismId) === authenticationReducer.organism) {
            setShow(false);
            setTimeout(() => {
                props.history.push(getRoutePathname('learner_registration', { id: registrationId }));
            }, 600);
        }
        else {
            Api.post({
                route: 'authentication_login',
                data: {
                    email: authenticationReducer.email,
                    password: 'learner',
                    isAdmin: false,
                    organism: parseInt(organismId)
                }
            }, (response) => {
                dispatch(AuthenticationLogin(response.data));
                setTimeout(() => {
                    props.history.push(getRoutePathname('learner_registration', { id: registrationId }));
                }, 600);
            });
        }
    };

    const changeStateEventBoxContent = (times) => {
        if (!times.timeYearNext && times.timeYearCurrent) {
            if ((times.totalFormationDurationCurrent < times.timeYearCurrent)) {
                setStateEventBoxContent(1);
            }
            else {
                setStateEventBoxContent(theme.id === 1 ? 2 : 0);
            }
        }
        else if (times.timeYearNext) {
            if ((times.totalFormationDurationNext < times.timeYearNext) &&
                (times.totalFormationDurationCurrent < times.timeYearCurrent)) {
                setStateEventBoxContent(1);
            }
            else if ((times.totalFormationDurationNext >= times.timeYearNext) &&
                (times.totalFormationDurationCurrent >= times.timeYearCurrent)) {
                setStateEventBoxContent(theme.id === 1 ? 2 : 0);
            }
            else {
                setStateEventBoxContent(3);
            }
        }
    };

    React.useEffect(() => {
        Api.get({
            route: 'learner_home_time_processing'
        },
            (response) => {
                setTimeProcessing(response.data);
                changeStateEventBoxContent(response.data);
            });

        let lastConnection = localStorage.getItem('lastConnection');

        if (moment().subtract(3, 'days').isBefore(lastConnection)) {
            setIsEventBoxShow(false);
        }
        else {
            setIsEventBoxShow(true);
            localStorage.setItem('lastConnection', moment().format('YYYY-MM-DD'));
            
        }
    }, []);
    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        setBreadcrumbs({
            currentPage: 'Tableau de bord'
        });
        // Redirect auto catalog
        if (memoryReducer.registerFormation) {
            props.history.push(getRoutePathname('learner_catalog'));
        }
        else {
            Api.get({ route: 'learner_home_info' },
                (response) => {
                    setFormationsLeaner(response.data.data);
                    setCountDataFormation({
                        countDataPending: response.data.countDataPending,
                        countDataInProgress: response.data.countDataInProgress
                    });
                });

            Api.get({ route: 'learner_home_library_resource_types' },
                (response) => {
                    if (response && response.data) {
                        setLibraryResourceTypes(response.data);
                    }
                });
            setTimeout(() => {
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
            }, 1000);
        }
    }, []);
    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <div style={{ textAlign: 'center', maxWidth: 1240, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            {isEventBoxShow &&
                                (((timeProcessing && timeProcessing.timeYearCurrent) || (timeProcessing && timeProcessing.timeYearNext)) && stateEventBoxContent > 0) &&
                                <EventBoxComponent
                                    content={
                                        <>
                                            {stateEventBoxContent === 1 &&
                                                <>
                                                    {timeProcessing.totalFormationDurationCurrent > 0 && <p>Vous avez consommé {timeProcessing.totalFormationDurationCurrent}h de formation DPC.</p>}
                                                    <p>
                                                        Nous estimons votre compte formation à {(timeProcessing.timeYearCurrent - timeProcessing.totalFormationDurationCurrent)}h.<br />
                                                        Ce qui vous donne droit à encore vous former et percevoir {timeProcessing.maxRemunerationCurrent / timeProcessing.timeYearCurrent * (timeProcessing.timeYearCurrent - timeProcessing.totalFormationDurationCurrent)}€
                                                        de rémunération sur l'année {currentYear}.
                                                    </p>
                                                </>
                                            }

                                            {stateEventBoxContent === 2 &&
                                                <>
                                                    <p>Félicitation vous avez consommé votre budget DPC.</p>
                                                    <p>Mais ne vous inquiétez pas !<br /> Nous pouvons vous accompagner sur des formation CPF ou FIFPL.</p>
                                                </>
                                            }

                                            {stateEventBoxContent === 3 &&
                                                <>
                                                    <p>Félicitation vous avez complété votre budget DPC {currentYear}. <br />Mais bonne nouvelle, des sessions sont disponible pour {currentYear + 1}.</p>
                                                    <p>
                                                        Nous estimons votre compte formation à {(timeProcessing.timeYearNext - timeProcessing.totalFormationDurationNext)}h.<br />
                                                        Ce qui vous donne droit à encore vous former et percevoir {timeProcessing.maxRemunerationNext / timeProcessing.timeYearNext * (timeProcessing.timeYearNext - timeProcessing.totalFormationDurationNext)}€
                                                        de rémunération sur l'année {currentYear + 1}.
                                                    </p>
                                                </>
                                            }
                                            <p>Vous pouvez accéder directement à notre catalogue en cliquant ci-dessous.</p>
                                        </>}
                                    open={isEventBoxShow}
                                    labelButton={'Catalogue'}
                                    onClickButton={() => {
                                        props.history.push(getRoutePathname('learner_catalog'))
                                    }}
                                    icon={<NotificationsNoneIcon />}
                                />
                            }

                            {(authenticationReducer && authenticationReducer.id === 3) && <FeaturedResourceComponent
                                organism={authenticationReducer.organism}
                                formationGroup={authenticationReducer.formationGroup}
                            />}

                            <BoxLearnerComponent
                                title={'Votre carnet de formation'}
                                color={'#000'}
                                backgroundHeader={'rgb(251, 234, 169)'}
                                backgroundBody={'rgb(253, 245, 220)'}
                                iconTitle={<LayersIcon />}
                                styleContent={{ flexDirection: 'column', alignItems: 'center' }}
                                styleContentSmall={{ flexDirection: 'column', alignItems: 'center' }}
                            >
                                {(countDataFormation.countDataPending > 0) && <p style={{ textAlign: 'left', width: '90%', fontWeight: 600 }}>Formations en attente</p>}
                                {Object.keys(formationsLeaner).map((organismId) =>
                                    (formationsLeaner[organismId] && formationsLeaner[organismId]['pending_formations']) && formationsLeaner[organismId]['pending_formations'].map((formationLeaner, index) =>
                                        <CardFormation
                                            text={formationLeaner.availableAt ? <span>Contenu disponible le <br /> {moment(formationLeaner.availableAt).format('ll')}</span> : 'Voir la formation'}
                                            show={show}
                                            key={index}
                                            hours={formationLeaner.hours}
                                            organism={organismId}
                                            image={learnerHomeFormationImageBanner + formationLeaner.image}
                                            textSession={formationLeaner.textSession}
                                            title={formationLeaner.title}
                                            state={formationLeaner.state}
                                            financeTypeName={formationLeaner.financeTypeName}
                                            onClick={() => formationLeaner.availableAt ? {} : goRegistration(formationLeaner.registration, organismId)}
                                            type={formationLeaner.type}
                                        />
                                    )
                                )}

                                {(countDataFormation.countDataInProgress > 0) && <p style={{ textAlign: 'left', width: '90%', fontWeight: 600 }}>Vos formations en cours</p>}
                                {Object.keys(formationsLeaner).map((organismId) =>
                                    (formationsLeaner[organismId] && formationsLeaner[organismId]['in_progress_formations']) && formationsLeaner[organismId]['in_progress_formations'].map((formationLeaner, index) =>
                                        <CardFormation
                                            text={formationLeaner.availableAt ? <span>Contenu disponible le <br /> {moment(formationLeaner.availableAt).format('ll')}</span> : 'Voir la formation'}
                                            show={show}
                                            key={index}
                                            hours={formationLeaner.hours}
                                            organism={organismId}
                                            image={learnerHomeFormationImageBanner + formationLeaner.image}
                                            textSession={formationLeaner.textSession}
                                            title={formationLeaner.title}
                                            state={formationLeaner.state}
                                            financeTypeName={formationLeaner.financeTypeName}
                                            onClick={() => formationLeaner.availableAt ? {} : goRegistration(formationLeaner.registration, organismId)}
                                            type={formationLeaner.type}
                                        />
                                    )
                                )}
                                {(countDataFormation.countDataPending <= 0 && countDataFormation.countDataInProgress <= 0) && <p>Vous n'avez pas de formation disponible ...</p>}
                            </BoxLearnerComponent>

                            {(authenticationReducer && authenticationReducer.id === 3 && libraryResourceTypes && libraryResourceTypes.length > 0) &&
                                <BoxLearnerComponent
                                    title={'Bibliothèque de ressource'}
                                    color={'#fff'}
                                    backgroundHeader={'rgb(79, 147, 165)'}
                                    backgroundBody={'rgb(193, 216, 218)'}
                                    iconTitle={<LayersIcon />}
                                >
                                    <Grid container spacing={2}>
                                        {libraryResourceTypes &&
                                            libraryResourceTypes.map((libraryResourceType) => (
                                                <CardResourceType
                                                    key={libraryResourceType.value}
                                                    libraryResourceType={libraryResourceType}
                                                    onClick={() => {
                                                        props.history.push(getRoutePathname('learner_resource', { id: libraryResourceType.value }))
                                                    }}
                                                />
                                            ))
                                        }
                                    </Grid>
                                    <p
                                        className={classes.textLink}
                                        onClick={() => { props.history.push(getRoutePathname('learner_library_resource')) }}
                                    >
                                        {'Voir toutes les ressources \>'}</p>
                                </BoxLearnerComponent>}

                            <BoxLearnerComponent
                                title={'Votre compte'}
                                color={'#fff'}
                                backgroundHeader={theme.colorPrimary}
                                backgroundBody={theme.colorPrimaryOpacity}
                                iconTitle={<PersonIcon />}
                                styleContent={{ alignItems: 'center', justifyContent: 'space-evenly' }}
                                styleContentSmall={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}
                            >
                                <ShadowBoxComponent className={classes.btn} onClick={() => props.history.push(getRoutePathname('learner_my_info'))}>
                                    <Settings className={classes.btnIcon} />
                                    <span className={classes.btnText}>Mes Informations</span>
                                </ShadowBoxComponent>
                                <ShadowBoxComponent className={classes.btn} onClick={() => props.history.push(getRoutePathname('learner_document'))}>
                                    <Description className={classes.btnIcon} />
                                    <span className={classes.btnText}>Mes documents</span>
                                </ShadowBoxComponent>
                            </BoxLearnerComponent>
                            <br />
                        </>
                }
            </div>
        </>

    );
}

const useStyles = (theme) => makeStyles(({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    btn: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'center',
        padding: 8,
        cursor: 'pointer'
    },
    btnIcon: {
        verticalAlign: 'middle'
    },
    btnText: {
        verticalAlign: 'middle',
        marginLeft: 10
    },
    textLink: {
        position: 'absolute',
        bottom: -10,
        left: 40,
        textDecoration: 'underline',
        fontWeight: 500,
        cursor: 'pointer'
    }
}));

export default Home;
