import React, { Fragment } from 'react';
import { Avatar, Drawer as DrawerMUI, makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "./LinearProgress";
import { AppBar as AppBarMUI } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Api from "../../../Api";
import { AuthenticationLogout } from "../../../Action/AuthenticationAction";
import { DrawerMainLayoutClose, DrawerMainLayoutOpen } from "../../../Action/DrawerMainLayoutAction";
import CloseIcon from "@material-ui/icons/Close";
import UseAnimations from "react-useanimations";
import facebook from "react-useanimations/lib/facebook";
import twitter from "react-useanimations/lib/twitter";
import linkedin from "react-useanimations/lib/linkedin";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Description, LiveHelp, MeetingRoom, Menu, Settings } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { getRoutePathname } from "../../../Config/Route";
import { theme } from "../../../App";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ForumIcon from '@material-ui/icons/Forum';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import DashboardIcon from '@material-ui/icons/Dashboard';

export default function AppBar(props) {
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const drawerMainLayoutReducer = useSelector(state => state.DrawerMainLayoutReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isActiveTab, setIsActiveTab] = React.useState();

    const closeNav = (callback) => {
        callback();
        dispatch(DrawerMainLayoutClose())
    };
    const logout = () => {
        dispatch(AuthenticationLogout());
        Api.get({ route: 'authentication_logout' }, () => {
        });
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
        dispatch(DrawerMainLayoutClose())
    }, [screenReducer.screen]);

    React.useEffect(() => {
        switch (props.location.pathname) {
            case "/learner/home":
                setIsActiveTab(1);
            break;
            case "/learner/catalog":
                setIsActiveTab(2);
            break;
            case "/learner/library-resource":
                setIsActiveTab(3);
            break;
            case "/learner/community":
                setIsActiveTab(4);
            break;
        }
    }, [props.location.pathname]);

    return (
        <>
            <AppBarMUI position="fixed" className={`${classes.appBar}`}>
                <Toolbar>
                    <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.appBarLogo} />

                    {isSmallView ? <div style={{ flex: 1 }}>
                        <Tooltip title="Déconnexion" aria-label="Déconnexion">
                            <IconButton component="span" className={classes.appBarLogout} onClick={() => dispatch(DrawerMainLayoutOpen())}>
                                <Menu className={classes.appBarLogoutIcon} />
                            </IconButton>
                        </Tooltip>
                    </div>

                    :

                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end', margin: 5 }}>
                        <IconButton className={`${classes.appBarBtn} ${isActiveTab === 1 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_home'))}>
                            Tableau de bord
                        </IconButton>
                        <IconButton className={`${classes.appBarBtn} ${isActiveTab === 2 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_catalog'))}>
                            Catalogue
                        </IconButton>
                        {(authenticationReducer && authenticationReducer.id === 3) && <IconButton className={`${classes.appBarBtn} ${isActiveTab === 3 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_library_resource'))}>
                            Bibliothèque de ressources
                        </IconButton>}
                        {(authenticationReducer && authenticationReducer.id === 3) && <IconButton className={`${classes.appBarBtn} ${isActiveTab === 4 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_community'))}>
                            Forum
                        </IconButton>}
                        <IconButton component="span" className={classes.appBarSubMenu} onClick={() => setIsOpen(true)}>
                            {(authenticationReducer.firstname && authenticationReducer.lastname) &&
                                <Avatar className={classes.avatar}>{authenticationReducer.firstname.charAt().toUpperCase()}{authenticationReducer.lastname.charAt().toUpperCase()}
                                </Avatar>
                            }
                            <ArrowDropDownIcon />
                        </IconButton>
                    </div>}
                </Toolbar>
                <LinearProgress />
            </AppBarMUI>

            <Fragment>
                <DrawerMUI className={classes.subMenu} anchor={'right'} open={isOpen} onClose={() => setIsOpen(false)}>
                    <CloseIcon className={classes.drawerIconClose} onClick={() => setIsOpen(false)} />

                    <List style={{ color: '#FFF', padding: 10, marginTop: 30 }}>
                        <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); props.history.push(getRoutePathname('learner_my_info')) }}>
                            <ListItemIcon className={classes.drawerListIcon}><Settings style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes informations'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); props.history.push(getRoutePathname('learner_document')) }}>
                            <ListItemIcon className={classes.drawerListIcon}><Description style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes documents'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); window.open(theme.faq, "_blank") }}>
                            <ListItemIcon className={classes.drawerListIcon}><LiveHelp style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'FAQ'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(logout)}>
                            <ListItemIcon className={classes.drawerListIcon}><MeetingRoom style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Déconnexion'} />
                        </ListItem>
                    </List>
                    <div className={classes.drawerNetworkSmall}>
                        <UseAnimations animation={facebook} size={18} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left' }} onClick={() => window.open(theme.facebook, "_blank")} />
                        <UseAnimations animation={twitter} size={18} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left' }} onClick={() => window.open(theme.twitter, "_blank")} />
                        <UseAnimations animation={linkedin} size={18} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left' }} onClick={() => window.open(theme.linkedin, "_blank")} />
                    </div>
                </DrawerMUI>
            </Fragment>

            <Fragment>
                <DrawerMUI anchor={'top'} open={drawerMainLayoutReducer.isOpen} onClose={() => dispatch(DrawerMainLayoutClose())}>
                    <CloseIcon className={classes.drawerIconClose} onClick={() => dispatch(DrawerMainLayoutClose())} />
                    <div className={classes.drawerNetworkSmall}>
                        <UseAnimations animation={facebook} size={18} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left' }} onClick={() => window.open(theme.facebook, "_blank")} />
                        <UseAnimations animation={twitter} size={18} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left' }} onClick={() => window.open(theme.twitter, "_blank")} />
                        <UseAnimations animation={linkedin} size={18} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left' }} onClick={() => window.open(theme.linkedin, "_blank")} />
                    </div>
                    <List style={{ color: '#FFF', padding: 0 }}>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_home')))}>
                            <ListItemIcon className={classes.drawerListIcon}><DashboardIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Tableau de bord'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_catalog')))}>
                            <ListItemIcon className={classes.drawerListIcon}><PhotoLibraryIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Catalogue'} />
                        </ListItem>
                        {(authenticationReducer && authenticationReducer.id === 3) && <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_library_resource')))}>
                            <ListItemIcon className={classes.drawerListIcon}><LibraryBooksIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Bibliothèque de ressources'} />
                        </ListItem>}
                        {(authenticationReducer && authenticationReducer.id === 3) && <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_community')))}>
                            <ListItemIcon className={classes.drawerListIcon}><ForumIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Forum'} />
                        </ListItem>}
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_my_info')))}>
                            <ListItemIcon className={classes.drawerListIcon}><Settings style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes informations'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_document')))}>
                            <ListItemIcon className={classes.drawerListIcon}><Description style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes documents'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => window.open(theme.faq, "_blank"))}>
                            <ListItemIcon className={classes.drawerListIcon}><LiveHelp style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'FAQ'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(logout)}>
                            <ListItemIcon className={classes.drawerListIcon}><MeetingRoom style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Déconnexion'} />
                        </ListItem>
                    </List>
                </DrawerMUI>
            </Fragment>
        </>
    );
}

const useStyles = (theme) => makeStyles(style => ({
    appBar: {
        zIndex: 4,
        transition: style.transitions.create(['width', 'margin'], {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        background: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.5)',
        height: 80,
        '& .MuiToolbar-regular': {
            height: 80,
            minHeight: '80px !important',
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    appBarSubMenu: {
        padding: 1,
        border: '0.5px solid #5E6E82',
        borderRadius: 30,
    },
    subMenu: {
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 35,
        height: 35,
    },
    appBarLogo: {
        height: 25,
        paddingLeft: 25,
        float: 'right'
    },
    appBarBtn: {
        padding: 20,
        margin: 0,
        color: '#5E6E82',
        fontWeight: 700,
        fontSize: 13,
        borderRadius: 0,
        position: 'relative',
        float: 'right'
    },
    appBarLogout: {
        float: 'right',
        padding: 10,
        margin: 8,
        marginRight: 12,
        color: '#5E6E82'
    },
    appBarLogoutIcon: {
        fontSize: 22
    },
    drawerIconClose: {
        position: 'absolute',
        right: 0,
        padding: 12,
        fontSize: 18,
        zIndex: 2,
        cursor: 'pointer'
    },
    drawerNetworkSmall: {
        width: '100%',
        display: 'block',
    },
    drawerListItem: {
        paddingTop: 3,
        paddingBottom: 3,
        '&:hover': {
            background: 'rgb(236 236 236)'
        }
    },
    drawerListText: {
        cursor: 'pointer',
        margin: 0,
        padding: 5,
        '& .MuiListItemText-primary': {
            fontSize: 12,
            color: '#5E6E82'
        }
    },
    activeTab: {
        color: theme.colorPrimary,
    }
}));
