import React from 'react';
import {makeStyles} from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import Fade from "@material-ui/core/Fade";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import IconButton from "@material-ui/core/IconButton";
import {Fullscreen, FullscreenExit} from "@material-ui/icons";
import {ScreenFull} from "../../../Action/ScreenAction";
import {getRoutePathname} from "../../../Config/Route";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {theme} from "../../../App";
import Iframe from "./Iframe";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';

function RegistrationCourse(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    const goBack = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
        }, 600);
    };

    // Iframe Scale
    const [iFrameScale, setIFrameScale] = React.useState(1);
    const generateIFrameScale = () => {

        if (!isScale) return;

        let orientation = window.screen.orientation && window.screen.orientation.angle === 90;

        if (screenReducer.full) {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (screenReducer.full ? 0 : 58)) / iFrameHeight;

            if (orientation) {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            } else {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            }
        } else {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (window.innerHeight / 3)) / iFrameHeight;


            if (orientation) {
                if (window.innerHeight < iFrameHeight) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(1);
                }
            } else {
                let r = 1;
                if (window.innerHeight < (iFrameHeight + 200)) {
                    r = ratioHeight;
                }
                if (window.innerWidth < iFrameWidth && ratioHeight > ratioWidth) {
                    r = ratioWidth;
                }

                setIFrameScale(r);
            }
        }
    };
    React.useEffect(generateIFrameScale, [screenReducer.full, screenReducer.size, screenReducer.orientation]);
    React.useEffect(() => {
        if (screenReducer.orientation && isScale) {
            setTimeout(() => dispatch(ScreenFull(true)), 50);
        }
    }, [screenReducer.orientation]);

    let isScale = true;
    let styleContent = {};
    let iFrameWidth = 0;
    let iFrameHeight = 0;

    if (props.location.state) {
        if (props.location.state.courseType === 4) {
            iFrameWidth = 1240;
            iFrameHeight = '100%';
            styleContent = {maxWidth: iFrameWidth, width: '100%', height: '100%', zIndex: 1, position: 'relative'};
            isScale = false;
        } else if (props.location.state.courseType === 6) {
            iFrameWidth = 1240;
            iFrameHeight = '100%';
            styleContent = {maxWidth: iFrameWidth, width: '100%', height: iFrameHeight, zIndex: 1, position: 'relative', transform: 'scale(' + iFrameScale + ')'};
            isScale = true;
        } else {
            iFrameWidth = 920;
            iFrameHeight = 640;
            styleContent = {width: iFrameWidth, height: iFrameHeight, zIndex: 101, position: 'relative', transform: 'scale(' + iFrameScale + ')'};
            isScale = true;
        }
    }


    React.useEffect(() => {
        if (props.match.params.registration && props.match.params.course) {
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                    route: 'learner_registration_course_get',
                    params: {
                        registration: props.match.params.registration,
                        course: props.match.params.course
                    }
                },
                (response) => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setBreadcrumbs({
                            currentPage: response.data.name,
                            links: [
                                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                                { path: getRoutePathname('learner_registration', {id: props.match.params.registration}), label: 'Sommaire' }
                            ]
                        });
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);
    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        window.addEventListener("fromItemToPortail", goBack);
        return () => window.removeEventListener("fromItemToPortail", goBack)
    }, []);
    React.useEffect(() => {
        if (!props.location.state) goBack();
    }, [props.location.state]);
    React.useEffect(() => {
        if (props.location.state.courseType === 5 || props.location.state.courseType === 6) {
            Api.post({
                    route: 'learner_registration_course_score',
                    params: {
                        registration: props.match.params.registration,
                        course: props.match.params.course
                    },
                    data: {
                        score: 100
                    }
                },
                (response) => {
                    if (response.status === 200) {
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <div style={{textAlign: 'center', margin: 'auto', height: '85%'}}>
                {
                    loading === true ?
                        <Fade in={true} {...{timeout: 1000}}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35}/>
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        : loading === false ?
                            <Fade in={show} {...{timeout: 1000}}>
                                <div style={{textAlign: 'center', margin: 'auto', alignItems: 'center', justifyContent: 'center', height: '100%', display: "flex", position: 'relative'}}>

                                    {screenReducer.full && <div style={{height: '100%', width: '100%', backgroundColor: '#000', position: 'absolute', zIndex: 100}}/>}

                                    <div style={styleContent}>
                                        {
                                            isScale && <>
                                                {
                                                    screenReducer.full ?
                                                        <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(false))}>
                                                            <FullscreenExit className={classes.btnFullScreenIcon}/>
                                                        </IconButton> :
                                                        <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(true))}>
                                                            <Fullscreen className={classes.btnFullScreenIcon}/>
                                                        </IconButton>
                                                }
                                            </>
                                        }
                                        <Iframe
                                            id={'Iframe'}
                                            registration={props.match.params.registration}
                                            course={props.match.params.course}
                                            courseType={props.location.state.courseType}
                                        />
                                    </div>
                                </div>
                            </Fade>
                            : <></>
                }
            </div>
        </>
    );
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
            {
                margin: 'auto',
                width: '15% !important'
            }
    },
    shadowBox: {
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
    },
    btnFullScreen: {
        right: 60,
        top: 0,
        position: 'absolute',
        padding: 10,
        margin: 0,
        marginRight: 12,
        color: '#FFFFFF',
        zIndex: 3
    },
    btnFullScreenIcon: {
        fontSize: 30
    }
});

export default RegistrationCourse;
