import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { makeStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch } from "react-redux";
import { getRoutePathname } from "../../../Config/Route";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../Component/TabsComponent";
import SelectComponent from "../../../Component/SelectComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import VirtualClassroomUser from "./VirtualClassroomUser";

function VirtualClassroom(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        former: {
            name: 'former',
            label: 'Formateur',
            textHelper: 'Choisissez le nom du formateur',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez la Formation.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Saisissez la duration de la classe virtuelle en seconde (en second).',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        path: {
            name: 'path',
            label: 'Url zoom',
            textHelper: 'Saisissez une url.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        sessionOrganism: {
            name: 'sessionOrganism',
            label: 'Organisme',
            textHelper: 'Choisissez un Organisme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        sessionStartAt: {
            name: 'sessionStartAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].sessionStartAt ? cacheTableServer['content_manager_virtual_classroom_list'].sessionStartAt : '',
            options: { validation: ['required'] }
        },
        sessionEndAt: {
            name: 'sessionEndAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].sessionEndAt ? cacheTableServer['content_manager_virtual_classroom_list'].sessionEndAt : '',
            options: { validation: ['required'] }
        }
    });
    const handler = formHandlerInit(form, setForm);
    const [optionFormer, setOptionFormer] = React.useState([]);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [optionFormation, setOptionFormation] = React.useState([]);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'content_manager_virtual_classroom_edit',
                    data: handler.getData(),
                    params: { id: props.match.params.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Classe virtuelle modifiée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_virtual_classroom_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('content_manager_virtual_classroom', { id: response.data[0].id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Classe virtuelle enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    const remove = () => {
        handler.setFormLoading(true);
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'content_manager_virtual_classroom_delete',
            params: { id: props.match.params.id }
        }, (response) => {
            handler.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                props.history.push(getRoutePathname('content_manager_virtual_classroom_list'));
                dispatch(
                    SnackbarOpen({
                        text: 'Classe virtuelle supprimé.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
                if (response.error && response.error.message) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    // ALL
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setReadyContent(false);

        if (props.match.params.id) {
            setIsEdition(true);
            setBreadcrumbs({
                title: 'Classe virtuelle',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    { path: getRoutePathname('content_manager_virtual_classroom_list'), label: 'Liste des classes virtuelles' }
                ]
            });

            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                route: 'content_manager_virtual_classroom_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data[0];
                        handler.setDataApi(data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('content_manager_virtual_classroom_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        }
        else {
            setIsEdition(false);
            setReadyContent(true);
            setBreadcrumbs({
                title: 'Ajouter une classe virtuelle',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    { path: getRoutePathname('content_manager_virtual_classroom_list'), label: 'Liste des classes virtuelles' }
                ]
            });
        }
    }, [props.match.params.id]);
    React.useEffect(() => {
        Api.get({
            route: 'select_formers'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormer(response.data);
                }
            });
        Api.get({
            route: 'select_formations'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormation(response.data);
                }
            });
        Api.get({
            route: 'select_organisms'
        },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                <Fade in={true} {...{ timeout: 750 }}>
                    <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ShadowBoxComponent style={{ minHeight: '100%' }} className={classes.shadowBox}>
                            <TitleComponent title={isEdition ? <>Formulaire d'édition de la classe virtuelle</> : <>Formulaire de création d'une classe virtuelle</>} />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <SelectComponent name={'former'} handler={handler} options={optionFormer} disabled={isEdition} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <SelectComponent name={'formation'} handler={handler} options={optionFormation} disabled={isEdition} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <SelectComponent name={'sessionOrganism'} handler={handler} options={optionOrganism} disabled={isEdition} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent name={'path'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <DatePickerComponent name={'sessionStartAt'} handler={handler} disabled={isEdition} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <DatePickerComponent name={'sessionEndAt'} handler={handler} disabled={isEdition} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent name={'duration'} handler={handler} disabled={isEdition} />
                                </Grid>
                            </Grid>

                            <div className={classes.button}>
                                <ButtonComponent label={'Enregistrer'} className={classes.buttonSave} onClick={save} disabled={loading} />
                            </div>

                            {isEdition && <div className={classes.button}>
                                <ButtonComponent label={'Supprimer'} color={'#dc3545'} className={classes.buttonDelete} onClick={remove} disabled={loading} />
                            </div>}
                        </ShadowBoxComponent>
                    </Grid>
                </Fade>
            </Grid>
            <br />
            {
                isEdition &&
                <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                    <div>
                        <TabsComponent
                            tabDefault={'users'}
                            tabs={[
                                { id: 'users', label: 'Participants' },
                            ]}
                        >
                            <div key={'users'}>
                                <VirtualClassroomUser {...props} />
                            </div>

                        </TabsComponent>
                    </div>
                </Slide>
            }

            <br />
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    }
});

export default VirtualClassroom;
