import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import { Pagination } from "@material-ui/lab";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";

export default function PaginationComponent(props = {
    dataList: [],
    dataKey: null,
    pageNumber: 0,
    viewList: null,
    error: '',
    backgroundColor: '',
    color: '',
    classe: ''
}) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const usePagination = (data, itemsPerPage) => {
        if (data && itemsPerPage) {

            const maxPage = Math.ceil(data.length / itemsPerPage);

            function currentData() {
                const begin = (currentPage - 1) * itemsPerPage;
                const end = begin + itemsPerPage;
                return data.slice(begin, end);
            }

            function next() {
                setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
            }

            function prev() {
                setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
            }

            function jump(page) {
                const pageNumber = Math.max(1, page);
                setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
            }
            return { next, prev, jump, currentData, currentPage, maxPage };
        }
    }

    let [page, setPage] = React.useState(1);
    const PER_PAGE = props.pageNumber;
    const count = Math.ceil((props.dataKey ? props.dataKey.length : props.dataList ? props.dataList.length : 0) / PER_PAGE);
    const _DATA = usePagination((props.dataKey ? props.dataKey : props.dataList), PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };
    React.useEffect(() => {
        if (props.dataList.length) {
            setPage(1);
            setCurrentPage(1);
        }
    }, [props.dataList.length]);
    return (
        <>
            {(_DATA.currentData() && _DATA.currentData().length === 0)
                ?
                <p style={{ color: "#666", textTransform: "uppercase", fontSize: '4vw', opacity: 0.1, width: "100%", textAlign:'center'}}><b>{props.error ? props.error : "Aucun résultat"}</b></p>
                :
                <>
                    {
                        _DATA.currentData().map((dataList, index) =>
                            <Fade in={true} {...{ timeout: 500 }} key={index}>
                                {props.viewList(dataList)}
                            </Fade>
                        )
                    }
                    {
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12} style={{ background: props.backgroundColor, padding: 7 }}>
                                <Pagination
                                    style={{ margin: '0 0 0 auto', width: 'fit-content' }}
                                    count={count}
                                    color={props.color}
                                    size="medium"
                                    page={page}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={handleChange}
                                    className={props.classe}
                                />
                            </Grid>
                        </Grid>
                    }
                </>
            }
        </>

    )
}
