import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Fade, Grid, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import Api, { LibraryResourceImage, LibraryResourceTypeIcon } from '../../../Api';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import LazyLoading from './Component/LazyLoading';

const LibraryResource = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [libraryResources, setLibraryResources] = React.useState();

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {

        setBreadcrumbs({
            currentPage: 'Bibliothèque de ressources',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });

        Api.get({ route: 'learner_library_resource_list' },
            (response) => {
                if (response && response.data) {
                    setLibraryResources(response.data);
                }
            });

        setLoading(false);
    }, []);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{ textAlign: 'center', maxWidth: isSmallView ? '100%' : '80%', margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            <FeaturedResourceComponent
                                organism={authenticationReducer.organism}
                                formationGroup={authenticationReducer.formationGroup}
                            />
                            <br />

                            {libraryResources &&
                                libraryResources.map((libraryResource) => (
                                    <div key={libraryResource.id} style={{ width: '80%', margin: 'auto', position: 'relative' }}>
                                        {libraryResource.resources &&
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 700 }}>
                                                    <img width="40px" height="40px" src={LibraryResourceTypeIcon + libraryResource.icon} alt='icon' />
                                                    <p style={{ marginLeft: 10, fontSize: 20 }}>{libraryResource.label}</p>
                                                </div>

                                                <Grid container spacing={3}>
                                                    {libraryResource.resources.map((resource) => (
                                                        <Grid key={resource.id} item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                            <MediaLinkComponent
                                                                path={resource.link}
                                                                type={resource.format}
                                                                isLibrary={true}
                                                            >
                                                            <div className={classes.card}>
                                                                <ShadowBoxComponent style={{ padding: 10, height: 180}}>
                                                                    <LazyLoading path={LibraryResourceImage + resource.image} />
                                                                </ShadowBoxComponent> 
                                                                <p style={{ fontWeight: 600, marginTop: 30 }}>{resource.title}</p>
                                                                <p style={{ fontSize: 12 }}>{resource.description}</p>
                                                            </div>  
                                                            </MediaLinkComponent>
                                                        </Grid>
                                                    ))}

                                                </Grid>
                                                <p
                                                    className={classes.textLink}
                                                    onClick={() => { props.history.push(getRoutePathname('learner_resource', { id: libraryResource.id })) }}
                                                >
                                                    {`Voir les ${libraryResource.label} \>`}
                                                </p>
                                            </>}
                                    </div>
                                ))
                            }
                        </>
                }
            </div>
        </>
    );
};

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    textLink: {
        textAlign: 'left',
        textDecoration: 'underline',
        fontWeight: 500,
        cursor: 'pointer'
    },
    card: {
        height: 300,
        padding: 15,
        background: '#fff',
        cursor: 'pointer',
        border: '1px solid #F2F2F2',
        borderRadius: 10,
        '& > p': {
            textAlign: 'left'
        }
    },
    imagePreload: {
        height: 160,
        width: '100%',
        maxWidth: 170,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    resourceImage: {
        width: '100%',
        height: 170,
        objectFit: 'cover'
    }
});

export default LibraryResource;