import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {makeStyles} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Config/Route";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../Component/TabsComponent";
import QuestionAnswer from "./QuestionAnswer";
import QuestionComment from './QuestionComment';
import CheckboxComponent from "../../../Component/CheckboxComponent";

function Question(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        question: {
            name: 'question',
            label: 'Question',
            textHelper: 'Saisissez une question.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        formation: {
            name: 'formation',
            label: 'Nom de la formation',
            textHelper: '',
            type: 'text',
            defaultValue: '',
        },
        active: {
            name: 'active',
            label: 'Afficher la Question',
            textHelper: '',
            type: 'boolean',
            defaultValue: false
        }
    });
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'content_manager_question_edit',
                data: handler.getData(),
                params: {id: props.match.params.id}
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Question modifiée.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

        }
    };
    const remove = () => {
        handler.setFormLoading(true);
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'content_manager_question_delete',
            params: {id: props.match.params.id}
        }, (response) => {
            handler.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                props.history.push(getRoutePathname('content_manager_formation', {id: props.match.params.formationId}));
                dispatch(
                    SnackbarOpen({
                        text: 'Question supprimé.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
                if (response.error && response.error.message) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    // ALL
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setReadyContent(false);

        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                    route: 'content_manager_question_get',
                    params: {id: props.match.params.id}
                },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                        setBreadcrumbs({
                            title: 'Question',
                            context: 'Gestion de contenu',
                            description: '',
                            links: [
                                {path: getRoutePathname('content_manager_formation_list'), label: 'Liste des formations'},
                                {path: getRoutePathname('content_manager_formation', {id: props.match.params.formationId}), label: data.formation}
                            ]
                        });
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('content_manager_formation_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else {
            props.history.push(getRoutePathname('content_manager_formation_list'));
        }
    }, [props.match.params.id]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                <Fade in={true} {...{timeout: 750}}>
                    <Grid style={{minHeight: '100%'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ShadowBoxComponent style={{minHeight: '100%'}} className={classes.shadowBox}>
                            <TitleComponent title={<>Éditer la question</>}/>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextFieldComponent name={'question'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextFieldComponent name={'formation'} handler={handler} disabled={true}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CheckboxComponent name={'active'} handler={handler}/>
                                </Grid>
                            </Grid>

                            <div className={classes.button}>
                                <ButtonComponent label={'Enregistrer'} className={classes.buttonSave} onClick={save} disabled={loading}/>
                            </div>

                            <div className={classes.button}>
                                <ButtonComponent label={'Supprimer'} color={'#dc3545'} className={classes.buttonDelete} onClick={remove} disabled={loading}/>
                            </div>
                        </ShadowBoxComponent>
                    </Grid>
                </Fade>
            </Grid>
            <br/>

            <Slide direction={'up'} in={true} {...{timeout: 500}}>
                <div>
                    <TabsComponent
                        tabDefault={'reponses'}
                        tabs={[
                            {id: 'reponses', label: 'Réponses'},
                            {id: 'comments', label: 'Comments'},
                        ]}
                    >
                        <div key={'reponses'}>
                            <QuestionAnswer {...props} />
                        </div>
                        <div key={'comments'}>
                            <QuestionComment {...props} />
                        </div>
                    </TabsComponent>
                </div>
            </Slide>
            <br/>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    }
});

export default Question;
