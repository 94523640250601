import React from 'react';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import {useDispatch, useSelector} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {makeStyles} from '@material-ui/core/styles';
import {getRoutePathname} from "../../../Config/Route";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {theme} from "../../../App";
import RadioComponent from "../../../Component/RadioComponent";

function RegistrationSurveySatisfaction(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isCompleted, setCompleted] = React.useState(false);

    const [form, setForm] = React.useState({
        estimateDuration: {
            name: 'estimateDuration',
            label: 'Durée estimée',
            textHelper: 'La durée estimée de la formation et la durée réelle de réalisation concordent.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        qualityInformation: {
            name: 'qualityInformation',
            label: 'Qualité des informations',
            textHelper: 'La formation aura un impact positif sur mon travail : le contenu proposé me sera utile dans ma pratique quotidienne.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        teachingMethod: {
            name: 'teachingMethod',
            label: 'Méthodes pédagogiques',
            textHelper: 'Les méthodes pédagogiques proposées sont adaptées (rythme, équilibre théorie/pratique…).',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        interactivity: {
            name: 'interactivity',
            label: 'Animations et interactivité',
            textHelper: 'Les différents types de contenus (images, schéma, vidéo, exercices, hiérarchisation du texte…) sont pertinents et motivants.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        progression: {
            name: 'progression',
            label: 'Progression',
            textHelper: 'L’enchaînement proposé des étapes est satisfaisant.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        satisfaction: {
            name: 'satisfaction',
            label: 'Appréciation générale',
            textHelper: 'Évaluation globale de la formation. Le programme est conforme à mes attentes. Les objectifs fixés ont été atteints.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {
                max: 1000
            }
        },
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const optionsRadio = [{value : 5}, {value : 4}, {value : 3}, {value : 2}, {value : 1}];

    const goBack = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
        }, 600);
    };
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            let data = handler.getData();

            Api.post({
                route: 'learner_registration_survey_satisfaction_add',
                data: data,
                params: {registration: props.match.params.registration}
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'L\'enregistrement du questionnaire a été un succès.',
                            variant: 'success',
                        })
                    );
                    setCompleted(true);
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

        }
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        setLoading(true);
        handler.setFormLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
                route: 'learner_registration_survey_satisfaction_get',
                params: {registration: props.match.params.registration}
            },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    let data = response.data;
                    handler.setDataApi(data);
                    setCompleted(data.isCompleted);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }, []);

    return (
        <div style={{textAlign: 'center', maxWidth: 1040, margin: 'auto'}}>
            {
                loading ?
                    <Fade in={true} {...{timeout: 1000}}>
                        <div className={classes.loader}>
                            <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                <ShadowBoxComponent>
                                    <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                    <div className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={35}/>
                                    </div>
                                </ShadowBoxComponent>
                            </div>
                        </div>
                    </Fade>
                    :
                    <div>
                        <Fade in={show} {...{timeout: 1000}}>
                            <div>
                                <ShadowBoxComponent className={classes.shadowBoxBack} onClick={goBack} style={isSmallView ? {width: 'calc(100% - 30px)', marginLeft: 15} : {}}>
                                    <ArrowLeftIcon className={classes.shadowBoxBackIcon}/>
                                    <span className={classes.shadowBoxBackText}>Revenir au sommaire</span>
                                </ShadowBoxComponent>
                                <ShadowBoxComponent className={classes.shadowBox}>
                                    <TitleComponent title={'Questionnaire de satisfaction'}/>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {
                                            isCompleted ?
                                                <p style={{textAlign: 'center'}}>Merci d'avoir participer au questionnaire</p>
                                                :
                                                <p>Nous vous remercions de bien vouloir répondre à ce questionnaire qui nous permettra d’améliorer la qualité de nos formations e-learning. <br/>
                                                    Merci d’exprimer votre opinion sur les affirmations suivantes en cochant la case qui correspond à votre degré d’accord ou de désaccord.</p>
                                            }
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TableContainer component={Paper} style={{backgroundColor: 'rgba(159,159,159,0.1)'}}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell align="center">Excellent</TableCell>
                                                            <TableCell align="center">Très bien</TableCell>
                                                            <TableCell align="center">Bien</TableCell>
                                                            <TableCell align="center">Passable</TableCell>
                                                            <TableCell align="center">Très mauvais</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <RadioComponent name={'estimateDuration'} options={optionsRadio} disabled={isCompleted} line={true} theme={'table'} handler={handler}/>
                                                        <RadioComponent name={'qualityInformation'} options={optionsRadio} disabled={isCompleted} line={true} theme={'table'} handler={handler}/>
                                                        <RadioComponent name={'progression'} options={optionsRadio} disabled={isCompleted} line={true} theme={'table'} handler={handler}/>
                                                        <RadioComponent name={'teachingMethod'} options={optionsRadio} disabled={isCompleted} line={true} theme={'table'} handler={handler}/>
                                                        <RadioComponent name={'interactivity'} options={optionsRadio} disabled={isCompleted} line={true} theme={'table'} handler={handler}/>
                                                        <RadioComponent name={'satisfaction'} options={optionsRadio} disabled={isCompleted} line={true} theme={'table'} handler={handler}/>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            COMMENTAIRES LIBRES :
                                            N'hésitez pas à nous faire part de vos remarques éventuelles sur la formation (max 240 caractères).
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextFieldComponent name={'comment'} multiline={true} nullable={true} disabled={isCompleted} handler={handler}/>
                                        </Grid>
                                    </Grid>

                                    <div className={classes.buttonGroup}>
                                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={isCompleted}/>
                                    </div>
                                </ShadowBoxComponent>
                            </div>
                        </Fade>
                    </div>
            }
        </div>
    );
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
            {
                margin: 'auto',
                width: '15% !important'
            }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    button: {
        float: 'right'
    },
});

export default RegistrationSurveySatisfaction;
