import {cloneJson} from "./Tool/CloneCommon";
import moment from "moment";
import axios from 'axios';
import {dispatch} from "./App";
import {AuthenticationLogout} from "./Action/AuthenticationAction";
import {SnackbarOpen} from "./Action/SnackbarAction";

const host = '/api';
const routeApi = {
    default: '/',

    // Authentication
    authentication_login: '/authentication/login',
    authentication_logout: '/authentication/logout',
    authentication_register_create: '/authentication/register/create',
    authentication_password_reset_request: '/authentication/password/reset-request',
    authentication_password_check_validity_code: '/authentication/password/check-validity-code',
    authentication_password_change: '/authentication/password/change',
    authentication_password_first_change: '/authentication/password/first-change',

    // Investor
    investor_dashboard_info: '/investor/dashboard/info',

    // Reporting Marketing
    reporting_marketing_dashboard_info: '/reporting-marketing/dashboard/info',
    reporting_marketing_dashboard_form_origin_list: '/reporting-marketing/dashboard/form-origin/list',
    reporting_marketing_dashboard_formation_group_list: '/reporting-marketing/dashboard/formation-group/list',
    reporting_marketing_dashboard_formation_list: '/reporting-marketing/dashboard/formation/list',
    reporting_marketing_dashboard_campaign_list: '/reporting-marketing/dashboard/campaign/list',
    reporting_marketing_campaign_list: '/reporting-marketing/campaign/list',
    reporting_marketing_campaign_add: '/reporting-marketing/campaign/add',
    reporting_marketing_campaign_type_list: '/reporting-marketing/campaign-type/list',
    reporting_marketing_campaign_type_add: '/reporting-marketing/campaign-type/add',
    reporting_marketing_form_send: '/reporting-marketing/form/send',

    // Reporting pedagogic
    reporting_pedagogic_survey_satisfaction_list: '/reporting-pedagogic/survey-satisfaction/list',
    reporting_pedagogic_epp_session_formation_list: '/reporting-pedagogic/epp/session-formation/list',
    reporting_pedagogic_epp_session_formation_registration_list: '/reporting-pedagogic/epp/session-formation/{id}/registration/list',
    reporting_pedagogic_epp_session_formation_download_form: '/reporting-pedagogic/epp/session-formation/{id}/download/form',

    // CRM
    crm_reporting_info: '/crm/reporting/info',

    crm_dashboard_registrations: '/crm/dashboard/registrations',

    crm_prospect_list: '/crm/prospect/list',
    crm_prospect_check: '/crm/prospect/organism/{organism}/check/{emailIPS}',
    crm_prospect_add: '/crm/prospect/add',
    crm_prospect_fusion: '/crm/prospect/{id}/fusion',
    crm_prospect_get: '/crm/prospect/{id}',
    crm_prospect_edit: '/crm/prospect/{id}',
    crm_prospect_delete: '/crm/prospect/{id}',
    crm_prospect_cancel_deletion: '/crm/prospect/{id}/cancel-deletion',
    crm_prospect_import: '/crm/prospect/import',
    crm_prospect_assign: '/crm/prospect/assign',
    crm_prospect_form_list: '/crm/prospect/{id}/form/list',
    crm_prospect_prospect_activity_list: '/crm/prospect/{id}/prospect-activity/list',
    crm_prospect_assign_me: '/crm/prospect/{id}/assign-me',
    crm_prospect_unassign_me: '/crm/prospect/{id}/unassign-me',
    crm_prospect_note_edit: '/crm/prospect/{id}/note',
    crm_prospect_reset_password: '/crm/prospect/{id}/reset-password',
    crm_prospect_prospect_state_edit: '/crm/prospect/{id}/prospect-state',

    crm_prospect_prospect_hotline_list: '/crm/prospect/{prospect}/hotline/list',
    crm_prospect_prospect_hotline_add: '/crm/prospect/{prospect}/hotline/add',

    crm_prospect_prospect_comment_list: '/crm/prospect/{id}/comment/list',
    crm_prospect_prospect_comment_add: '/crm/prospect/{id}/comment/add',
    crm_prospect_commercial_list: '/crm/prospect/{id}/commercial/list',
    crm_prospect_commercial: '/crm/prospect/{id}/commercial',
    crm_prospect_commercial_commission: '/crm/commercial/commission',

    crm_prospect_registration_list: '/crm/prospect/{prospect}/registration/list',
    crm_prospect_registration_add: '/crm/prospect/{prospect}/registration/add',
    crm_prospect_registration_get: '/crm/prospect/{prospect}/registration/{registration}',
    crm_prospect_registration_edit: '/crm/prospect/{prospect}/registration/{registration}',
    crm_prospect_registration_cancel: '/crm/prospect/{prospect}/registration/{registration}/cancel',
    crm_prospect_registration_delete: '/crm/prospect/{prospect}/registration/{registration}',
    crm_prospect_registration_re_enrollment: '/crm/prospect/{prospect}/registration/{registration}/re-enrollment',
    crm_prospect_registration_formation_session_formation_list: '/crm/prospect/{prospect}/registration/formation/{formation}/session-formation/list',
    crm_prospect_registration_formation_list: '/crm/prospect/{prospect}/registration/formation/list',

    crm_commercial_list: '/crm/commercial/list',

    crm_commercial_task_list: '/crm/commercial-task/list',
    crm_commercial_task_add: '/crm/commercial-task/add',
    crm_commercial_task_edit: '/crm/commercial-task/{id}',
    crm_commercial_task_delete: '/crm/commercial-task/{id}',

    crm_hotline_list: '/crm/hotline/list',

    crm_relaunch_registration_list: '/crm/relaunch/registration/list',
    crm_relaunch_registration_phone: '/crm/relaunch/registration/{id}/phone',
    crm_relaunch_registration_mail: '/crm/relaunch/registration/{id}/mail',

    // Content Manager
    content_manager_formation_list: '/content-manager/formation/list',
    content_manager_formation_add: '/content-manager/formation/add',
    content_manager_formation_get: '/content-manager/formation/{id}',
    content_manager_formation_edit: '/content-manager/formation/{id}',
    content_manager_formation_delete: '/content-manager/formation/{id}',
    content_manager_formation_andpc_list: '/content-manager/formation/{id}/andpc/list',
    content_manager_formation_andpc: '/content-manager/formation/{id}/andpc',
    content_manager_formation_fifpl_list: '/content-manager/formation/{id}/fifpl/list',
    content_manager_formation_fifpl: '/content-manager/formation/{id}/fifpl',
    content_manager_formation_cpf_list: '/content-manager/formation/{id}/cpf/list',
    content_manager_formation_cpf: '/content-manager/formation/{id}/cpf',

    content_manager_chapter_list: '/content-manager/chapter/list',
    content_manager_chapter_add: '/content-manager/chapter/add',
    content_manager_chapter_get: '/content-manager/chapter/{id}',
    content_manager_chapter_edit: '/content-manager/chapter/{id}',
    content_manager_chapter_delete: '/content-manager/chapter/{id}',

    content_manager_formation_formation_course_list: '/content-manager/formation/{formation}/chapter-course/list',
    content_manager_formation_formation_course_add: '/content-manager/formation/{formation}/chapter-course/add',
    content_manager_formation_formation_course_delete: '/content-manager/formation/{formation}/chapter-course/{id}',

    content_manager_chapter_chapter_course_list: '/content-manager/chapter/{chapter}/chapter-course/list',
    content_manager_chapter_chapter_course_add: '/content-manager/chapter/{chapter}/chapter-course/add',
    content_manager_chapter_chapter_course_get: '/content-manager/chapter/{chapter}/chapter-course/{id}',
    content_manager_chapter_chapter_course_edit: '/content-manager/chapter/{chapter}/chapter-course/{id}',
    content_manager_chapter_chapter_course_delete: '/content-manager/chapter/{chapter}/chapter-course/{id}',

    content_manager_formation_formation_chapter_list: '/content-manager/formation/{formation}/formation-chapter/list',
    content_manager_formation_formation_chapter_add: '/content-manager/formation/{formation}/formation-chapter/add',
    content_manager_formation_formation_chapter_get: '/content-manager/formation/{formation}/formation-chapter/{id}',
    content_manager_formation_formation_chapter_edit: '/content-manager/formation/{formation}/formation-chapter/{id}',
    content_manager_formation_formation_chapter_delete: '/content-manager/formation/{formation}/formation-chapter/{id}',

    content_manager_formation_formation_question_list: '/content-manager/formation/{formation}/formation-question/list',
    content_manager_formation_formation_question_add: '/content-manager/formation/{formation}/formation-question/add',
    content_manager_formation_formation_question_get: '/content-manager/formation/{formation}/formation-question/{id}',
    content_manager_formation_formation_question_edit: '/content-manager/formation/{formation}/formation-question/{id}',
    content_manager_formation_formation_question_delete: '/content-manager/formation/{formation}/formation-question/{id}',

    content_manager_formation_formation_question_epp_list: '/content-manager/formation/{formation}/formation-question-epp/list',
    content_manager_formation_formation_question_epp_add: '/content-manager/formation/{formation}/formation-question-epp/add',
    content_manager_formation_formation_question_epp_get: '/content-manager/formation/{formation}/formation-question-epp/{id}',
    content_manager_formation_formation_question_epp_edit: '/content-manager/formation/{formation}/formation-question-epp/{id}',
    content_manager_formation_formation_question_epp_delete: '/content-manager/formation/{formation}/formation-question-epp/{id}',

    content_manager_question_question_answer_list: '/content-manager/question/{question}/question-answer/list',
    content_manager_question_question_answer_add: '/content-manager/question/{question}/question-answer/add',
    content_manager_question_question_answer_get: '/content-manager/question/{question}/question-answer/{id}',
    content_manager_question_question_answer_edit: '/content-manager/question/{question}/question-answer/{id}',
    content_manager_question_question_answer_delete: '/content-manager/question/{question}/question-answer/{id}',

    content_manager_question_question_comment_list: '/content-manager/question/{question}/question-comment/list',
    content_manager_question_question_comment_add: '/content-manager/question/{question}/question-comment/add',
    content_manager_question_question_comment_get: '/content-manager/question/{question}/question-comment/{id}',
    content_manager_question_question_comment_edit: '/content-manager/question/{question}/question-comment/{id}',
    content_manager_question_question_comment_delete: '/content-manager/question/{question}/question-comment/{id}',

    content_manager_formation_formation_resource_list: '/content-manager/formation/{formation}/formation-resource/list',
    content_manager_formation_formation_resource_add: '/content-manager/formation/{formation}/formation-resource/add',
    content_manager_formation_formation_resource_get: '/content-manager/formation/{formation}/formation-resource/{id}',
    content_manager_formation_formation_resource_edit: '/content-manager/formation/{formation}/formation-resource/{id}',
    content_manager_formation_formation_resource_delete: '/content-manager/formation/{formation}/formation-resource/{id}',

    content_manager_formation_formation_session_list: '/content-manager/formation/{formation}/formation-session/list',
    content_manager_formation_formation_session_add: '/content-manager/formation/{formation}/formation-session/add',
    content_manager_formation_formation_session_delete: '/content-manager/formation/{formation}/formation-session/{id}',
    content_manager_formation_formation_session_select_sessions: '/content-manager/formation/{formation}/formation-session/select-sessions',

    content_manager_chapter_formation_chapter_list: '/content-manager/chapter/{chapter}/formation-chapter/list',
    content_manager_course_chapter_course_list: '/content-manager/course/{course}/chapter-course/list',

    content_manager_virtual_classroom_list: '/content-manager/virtual-classroom/list',
    content_manager_virtual_classroom_add: '/content-manager/virtual-classroom/add',
    content_manager_virtual_classroom_get: '/content-manager/virtual-classroom/{id}',
    content_manager_virtual_classroom_active: '/content-manager/virtual-classroom/active/{id}',
    content_manager_virtual_classroom_edit: '/content-manager/virtual-classroom/{id}',
    content_manager_virtual_classroom_delete: '/content-manager/virtual-classroom/{id}',

    content_manager_virtual_classroom_user_list: '/content-manager/virtual-classroom/{virtualclassroom}/user-virtual-classroom/list',

    content_manager_question_get: '/content-manager/question/{id}',
    content_manager_question_edit: '/content-manager/question/{id}',
    content_manager_question_delete: '/content-manager/question/{id}',

    content_manager_course_list: '/content-manager/course/list',
    content_manager_course_add: '/content-manager/course/add',
    content_manager_course_get: '/content-manager/course/{id}',
    content_manager_course_edit: '/content-manager/course/{id}',
    content_manager_course_delete: '/content-manager/course/{id}',

    content_manager_resource_list: '/content-manager/resource/list',
    content_manager_resource_add: '/content-manager/resource/add',
    content_manager_resource_get: '/content-manager/resource/{id}',
    content_manager_resource_edit: '/content-manager/resource/{id}',
    content_manager_resource_delete: '/content-manager/resource/{id}',
    content_manager_resource_formation_resource_list: '/content-manager/resource/{id}/formation-resource/list',

    content_manager_resource_type_list: '/content-manager/resource/type/list',
    content_manager_resource_type_add: '/content-manager/resource/type/add',
    content_manager_resource_type_delete: '/content-manager/resource/type/{id}',
    content_manager_resource_type_edit: '/content-manager/resource/type/{id}',

    content_manager_resource_group_list: '/content-manager/resource/group/list',
    content_manager_resource_group_add: '/content-manager/resource/group/add',
    content_manager_resource_group_delete: '/content-manager/resource/group/{id}',
    content_manager_resource_group_edit: '/content-manager/resource/group/{id}',

    //Marketing Manager
    marketing_manager_featured_resource_info: '/marketing-manager/featured-resource/info',
    marketing_manager_featured_resource_resource_get: '/marketing-manager/featured-resource/resource/{id}',
    marketing_manager_featured_resource_edit: '/marketing-manager/featured-resource/edit',

    marketing_manager_thematic_list: '/marketing-manager/thematic/list',
    marketing_manager_thematic_add: '/marketing-manager/thematic/add',
    marketing_manager_thematic_get: '/marketing-manager/thematic/{id}',
    marketing_manager_thematic_edit: '/marketing-manager/thematic/{id}',
    marketing_manager_thematic_delete: '/marketing-manager/thematic/{id}',

    marketing_manager_thematic_thematic_formation_list: '/marketing-manager/thematic/{id}/thematic-formation/list',
    marketing_manager_thematic_thematic_formation_add: '/marketing-manager/thematic/{id}/thematic-formation/add',
    marketing_manager_thematic_thematic_formation_delete: '/marketing-manager/thematic/{id}/thematic-formation/{formation}',
    
    marketing_manager_thematic_thematic_formation_group_list: '/marketing-manager/thematic/{id}/thematic-formation-group/list',
    marketing_manager_thematic_thematic_formation_group_add: '/marketing-manager/thematic/{id}/thematic-formation-group/add',
    marketing_manager_thematic_thematic_formation_group_delete: '/marketing-manager/thematic/{id}/thematic-formation-group/{formationGroup}',
    
    marketing_manager_thematic_thematic_library_resource_list: '/marketing-manager/thematic/{id}/thematic-library-resource/list',
    marketing_manager_thematic_thematic_library_resource_add: '/marketing-manager/thematic/{id}/thematic-library-resource/add',
    marketing_manager_thematic_thematic_library_resource_delete: '/marketing-manager/thematic/{id}/thematic-library-resource/{libraryResource}',

    marketing_manager_library_resource_type_list: '/marketing-manager/library-resource-type/list',
    marketing_manager_library_resource_type_get: '/marketing-manager/library-resource-type/{id}',
    marketing_manager_library_resource_type_add: '/marketing-manager/library-resource-type/add',
    marketing_manager_library_resource_type_edit: '/marketing-manager/library-resource-type/{id}',
    marketing_manager_library_resource_type_delete: '/marketing-manager/library-resource-type/{id}',
    
    marketing_manager_library_resource_list: '/marketing-manager/library-resource/list',
    marketing_manager_library_resource_get: '/marketing-manager/library-resource/{id}',
    marketing_manager_library_resource_add: '/marketing-manager/library-resource/add',
    marketing_manager_library_resource_edit: '/marketing-manager/library-resource/{id}',
    marketing_manager_library_resource_delete: '/marketing-manager/library-resource/{id}',


    // Financial Management
    financial_management_billing_session_formation_list: '/financial-management/billing/session-formation/list',
    financial_management_billing_session_formation_get: '/financial-management/billing/session-formation/{id}',
    financial_management_billing_session_formation_edit: '/financial-management/billing/session-formation/{id}',
    financial_management_billing_session_formation_billable: '/financial-management/billing/session-formation/{id}/billable',
    financial_management_billing_session_formation_invoice: '/financial-management/billing/session-formation/{id}/invoice',
    financial_management_billing_session_formation_download_participation_certificate: '/financial-management/billing/session-formation/{id}/download/participation-certificate',
    financial_management_billing_session_formation_send_participation_certificate: '/financial-management/billing/session-formation/{id}/send/participation-certificate',
    financial_management_billing_session_formation_download_tracking_connected_certificate: '/financial-management/billing/session-formation/{id}/download/tracking-connected-certificate',
    financial_management_billing_session_formation_download_follow_up_certificate: '/financial-management/billing/session-formation/{id}/download/follow-up-certificate',
    financial_management_billing_session_formation_download_kit_participation: '/financial-management/billing/session-formation/{id}/download/kit-participation',
    financial_management_billing_invoice_download: '/financial-management/billing/invoice/{id}/download',
    financial_management_billing_invoice_credit: '/financial-management/billing/invoice/{id}/credit',
    financial_management_billing_credit_download: '/financial-management/billing/credit/{id}/download',
    financial_management_billing_registration_time_balancing: '/financial-management/billing/registration/{id}/time-balancing',

    financial_management_billing_fifpl_session_formation_list: '/financial-management/billing-fifpl/session-formation/list',
    financial_management_billing_fifpl_session_formation_get: '/financial-management/billing-fifpl/session-formation/{id}',
    financial_management_billing_fifpl_registration_time_balancing: '/financial-management/billing-fifpl/registration/{id}/time-balancing',
    financial_management_billing_fifpl_cpf_session_formation_list: '/financial-management/billing-fifpl-cpf/session-formation/list',
    financial_management_billing_fifpl_session_formation_download_follow_up_certificate: '/financial-management/billing-fifpl/session-formation/{id}/download/follow-up-certificate',

    financial_management_billing_cpf_session_formation_list: '/financial-management/billing-cpf/session-formation/list',
    financial_management_billing_cpf_session_formation_get: '/financial-management/billing-cpf/session-formation/{id}',
    financial_management_billing_cpf_registration_session_cpf: '/financial-management/billing-cpf/registration/{id}/session-cpf',
    financial_management_billing_cpf_registration_time_balancing: '/financial-management/billing-cpf/registration/{id}/time-balancing',
    financial_management_billing_cpf_cpf_session_formation_list: '/financial-management/billing-cpf-cpf/session-formation/list',
    financial_management_billing_cpf_registration_download_follow_up_certificate: '/financial-management/billing-cpf/registration/{id}/download/follow-up-certificate',

    financial_management_payment_follow_invoice_list: '/financial-management/payment-follow/invoice/list',
    financial_management_payment_follow_invoice_acquitted: '/financial-management/payment-follow/invoice/{id}/acquitted',
    financial_management_relaunch_registration_list: '/financial-management/relaunch/registration/list',
    financial_management_relaunch_registration_phone: '/financial-management/relaunch/registration/{id}/phone',
    financial_management_relaunch_registration_mail: '/financial-management/relaunch/registration/{id}/mail',
    financial_management_statistics_export: '/financial-management/statistics/export',
    financial_management_relaunch_prospect_registration_cancel : '/financial-management/relaunch/prospect/{prospect}/registration/{registration}/cancel',

    // Administrator
    administrator_user_list: '/administrator/user/list',
    administrator_user_add: '/administrator/user/add',
    administrator_user_get: '/administrator/user/{id}',
    administrator_user_edit: '/administrator/user/{id}',
    administrator_user_delete: '/administrator/user/{id}',

    administrator_organism_list: '/administrator/organism/list',
    administrator_organism_add: '/administrator/organism/add',
    administrator_organism_get: '/administrator/organism/{id}',
    administrator_organism_edit: '/administrator/organism/{id}',
    administrator_organism_delete: '/administrator/organism/{id}',

    administrator_role_list: '/administrator/role/list',
    administrator_role_add: '/administrator/role/add',
    administrator_role_get: '/administrator/role/{id}',
    administrator_role_edit: '/administrator/role/{id}',
    administrator_role_delete: '/administrator/role/{id}',
    administrator_role_users: '/administrator/role/users',
    administrator_role_users_add: '/administrator/role/users-add',
    administrator_role_user_delete: '/administrator/role/{role}/user/{user}/delete',

    administrator_pole_list: '/administrator/pole/list',
    administrator_pole_add: '/administrator/pole/add',
    administrator_pole_get: '/administrator/pole/{id}',
    administrator_pole_edit: '/administrator/pole/{id}',
    administrator_pole_delete: '/administrator/pole/{id}',
    administrator_pole_users_list: '/administrator/pole/users-list',
    administrator_pole_users: '/administrator/pole/users',
    administrator_pole_users_edit: '/administrator/pole/users-edit',

    administrator_user_hotline_type_list: '/administrator/user-hotline-type/list',
    administrator_user_hotline_type_add: '/administrator/user-hotline-type/add',
    administrator_user_hotline_type_edit: '/administrator/user-hotline-type/{id}',
    administrator_user_hotline_type_delete: '/administrator/user-hotline-type/{id}',

    administrator_prospect_hotline_type_list: '/administrator/prospect-hotline-type/list',
    administrator_prospect_hotline_type_add: '/administrator/prospect-hotline-type/add',
    administrator_prospect_hotline_type_edit: '/administrator/prospect-hotline-type/{id}',
    administrator_prospect_hotline_type_delete: '/administrator/prospect-hotline-type/{id}',

    administrator_session_list: '/administrator/session/list',
    administrator_session_add: '/administrator/session/add',
    administrator_session_get: '/administrator/session/{id}',
    administrator_session_edit: '/administrator/session/{id}',
    administrator_session_delete: '/administrator/session/{id}',
    administrator_session_formation_list: '/administrator/session/formation/list',
    administrator_session_session_formation_list: '/administrator/session/{id}/session-formation/list',
    administrator_session_formations_relation: '/administrator/session/{id}/formations/relation',

    administrator_formation_group_property_list: '/administrator/formation-group-property/list',
    administrator_formation_group_property_add: '/administrator/formation-group-property/add',
    administrator_formation_group_property_get: '/administrator/formation-group-property/{id}',
    administrator_formation_group_property_edit: '/administrator/formation-group-property/{id}',
    administrator_formation_group_property_delete: '/administrator/formation-group-property/{id}',

    // community
    community_prospect_list: '/community/prospect/list',
    community_prospect_banned: '/community/prospect/banned/{id}',

    community_group_private_list: '/community/group-private/list',
    community_group_private_add: '/community/group-private/add',
    community_group_private_get: '/community/group-private/{id}',
    community_group_private_edit: '/community/group-private/{id}',
    community_group_private_delete: '/community/group-private/{id}',

    community_group_private_prospect_list: '/community/group-private/{groupPrivate}/prospect/list',
    community_group_private_prospect_add: '/community/group-private/{groupPrivate}/prospect/add',
    community_group_private_prospect_get: '/community/group-private/{groupPrivate}/prospect/{id}',
    community_group_private_prospect_edit: '/community/group-private/{groupPrivate}/prospect/{id}',
    community_group_private_prospect_banned: '/community/group-private/{groupPrivate}/prospect/banned/{id}',
    community_group_private_prospect_delete: '/community/group-private/{groupPrivate}/prospect/{id}',

    community_group_private_post_list: '/community/group-private/{groupPrivate}/post/list',
    community_group_private_post_add: '/community/group-private/{groupPrivate}/post/add',
    community_group_private_post_get: '/community/group-private/{groupPrivate}/post/{id}',
    community_group_private_post_edit: '/community/group-private/{groupPrivate}/post/{id}',
    community_group_private_post_delete: '/community/group-private/{groupPrivate}/post/{id}',

    community_group_private_post_comment_list: '/community/group-private/{groupPrivate}/post/{post}/comment/list',
    community_group_private_post_comment_add: '/community/group-private/{groupPrivate}/post/{post}/comment/add',
    community_group_private_post_comment_get: '/community/group-private/{groupPrivate}/post/{post}/comment/{id}',
    community_group_private_post_comment_edit: '/community/group-private/{groupPrivate}/post/{post}/comment/{id}',
    community_group_private_post_comment_delete: '/community/group-private/{groupPrivate}/post/{post}/comment/{id}',


    community_group_formation_list: '/community/group-formation/list',
    community_group_formation_add: '/community/group-formation/add',
    community_group_formation_get: '/community/group-formation/{id}',
    community_group_formation_edit: '/community/group-formation/{id}',
    community_group_formation_delete: '/community/group-formation/{id}',

    community_group_formation_prospect_list: '/community/group-formation/{groupFormation}/prospect/list',
    community_group_formation_prospect_add: '/community/group-formation/{groupFormation}/prospect/add',
    community_group_formation_prospect_get: '/community/group-formation/{groupFormation}/prospect/{id}',
    community_group_formation_prospect_edit: '/community/group-formation/{groupFormation}/prospect/{id}',
    community_group_formation_prospect_banned: '/community/group-formation/{groupFormation}/prospect/banned/{id}',
    community_group_formation_prospect_delete: '/community/group-formation/{groupFormation}/prospect/{id}',

    community_group_formation_post_list: '/community/group-formation/{groupFormation}/post/list',
    community_group_formation_post_add: '/community/group-formation/{groupFormation}/post/add',
    community_group_formation_post_get: '/community/group-formation/{groupFormation}/post/{id}',
    community_group_formation_post_edit: '/community/group-formation/{groupFormation}/post/{id}',
    community_group_formation_post_delete: '/community/group-formation/{groupFormation}/post/{id}',

    community_group_formation_post_comment_list: '/community/group-formation/{groupFormation}/post/{post}/comment/list',
    community_group_formation_post_comment_add: '/community/group-formation/{groupFormation}/post/{post}/comment/add',
    community_group_formation_post_comment_get: '/community/group-formation/{groupFormation}/post/{post}/comment/{id}',
    community_group_formation_post_comment_edit: '/community/group-formation/{groupFormation/post/{post}/comment/{id}',
    community_group_formation_post_comment_delete: '/community/group-formation/{groupFormation/post/{post}/comment/{id}',

    // Support
    support_hotline_dashboard_info_hotline: '/support/hotline-dashboard/info-hotline',
    support_hotline_dashboard_info_hotline_type: '/support/hotline-dashboard/info-hotline-type',
    support_hotline_dashboard_no_treated: '/support/hotline-dashboard/no-treated',
    support_prospect_hotline_list: '/support/prospect-hotline/list',
    support_prospect_hotline_edit: '/support/prospect-hotline/{id}',
    support_user_hotline_list: '/support/user-hotline/list',
    support_user_hotline_add: '/support/user-hotline/add',
    support_user_hotline_edit: '/support/user-hotline/{id}',

    // Former classroom
    former_virtual_classroom: '/former/virtual-classroom/{id}',
    former_virtual_classroom_list: '/former/virtual-classroom/list',

    // Select
    select_roles: '/select/roles',
    select_organisms: '/select/organisms',
    select_formers: '/select/formers',
    select_formations: '/select/formations',
    select_poles: '/select/poles',
    select_campaign_types: '/select/campaign-types',
    select_registration_types: '/select/registration-types',
    select_prospect_states: '/select/prospect-states',
    select_commercial_task_types: '/select/commercial-task-types',
    select_formation_groups: '/select/formation-groups',
    select_prospect_hotline_types: '/select/prospect-hotline-types',
    select_user_hotline_types: '/select/user-hotline-types',
    select_user_hotline: '/select/user-hotline',
    select_course_type: '/select/course-types',
    select_resource_type: '/select/resource-types',
    select_courses: '/select/courses',
    select_chapters: '/select/chapters',
    select_resources: '/select/resources',
    select_resource_groups: '/select/resource-groups',
    sessions_formations_state: '/select/sessions-formations-state',
    select_finance_types: '/select/finance-types',
    select_registration_origins: '/select/registration-origins',
    select_library_resource: '/select/library-resource',
    select_library_resource_types: '/select/library-resource-types',

    // Leaner
    learner_timer_tic: '/learner/timer/tic',
    learner_my_info_get: '/learner/my-info/get',
    learner_my_info_edit: '/learner/my-info/edit',
    learner_home_time_processing: '/learner/home/time-processing',
    learner_home_library_resource_types: '/learner/home/library-resource-types',

    learner_library_resource_list: '/learner/library-resource/list',
    learner_resource_list: '/learner/resource/list',
    learner_resource_library_resource_types: '/learner/resource/library-resource-types',

    learner_home_info: '/learner/home/info',
    learner_document_info: '/learner/document/info',
    learner_download_participation_certificate: '/learner/document/download/participation-certificate',
    learner_catalog_list: '/learner/catalog/list',
    learner_catalog_formation_get: '/learner/catalog/formation/{id}',
    learner_catalog_formation_session_formation_list: '/learner/catalog/formation/{formation}/session-formation/list',
    learner_catalog_register: '/learner/catalog/register',

    learner_community_group_list: '/learner/community/group/list',
    learner_community_group_get: '/learner/community/group/{communityGroup}',
    learner_community_group_add: '/learner/community/group/add',
    learner_community_group_delete: '/learner/community/group/{id}',

    learner_community_post_get: '/learner/community/group/{communityGroup}/post/{communityPost}',
    learner_community_post_edit: '/learner/community/group/{communityGroup}/post/{communityPost}',
    learner_community_post_list: '/learner/community/group/{communityGroup}/post/list',
    learner_community_post_add: '/learner/community/group/{communityGroup}/post/add',

    learner_community_comment_list: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/list',
    learner_community_comment_add: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/add',
    learner_community_comment_get: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/{id}',
    learner_community_comment_edit: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/{id}',
    learner_community_comment_delete: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/{id}',

    learner_registration_get: '/learner/registration/{id}',
    learner_registration_survey_satisfaction_get: '/learner/registration/{registration}/survey-satisfaction/get',
    learner_registration_survey_satisfaction_add: '/learner/registration/{registration}/survey-satisfaction/add',
    learner_registration_survey_pre_post_get: '/learner/registration/{registration}/survey-pre-post/{type}/get',
    learner_registration_survey_pre_post_add: '/learner/registration/{registration}/survey-pre-post/add',
    learner_registration_survey_epp_get: '/learner/registration/{registration}/survey-epp/{type}/get',
    learner_registration_survey_epp_score :'/learner/registration/{registration}/survey-epp/{type}/score',
    learner_registration_survey_epp_add: '/learner/registration/{registration}/survey-epp/add',
    learner_registration_survey_epp_download: '/learner/registration/{registration}/survey-epp/download/{type}',
    learner_registration_course_get: '/learner/registration/{registration}/course/{course}',
    learner_registration_course_view: '/learner/registration/{registration}/course/{course}/view',
    learner_registration_course_score: '/learner/registration/{registration}/course/{course}/score',
    learner_registration_virtual_classroom_get: '/learner/registration/{registration}/virtual-classroom/{virtualClassroom}/get',
    learner_registration_virtual_classroom_add: '/learner/registration/{registration}/virtual-classroom/add',
    learner_registration_virtual_classroom_replay_get: '/learner/registration/{registration}/virtual-classroom-replay/{virtualClassroom}/get',

    // Enrollment
    enrollment_register_create: '/enrollment/register/create',
    enrollment_register_formation_list: '/enrollment/register/formation/list',
    enrollment_register_formation_get: '/enrollment/register/formation/{formation}/get',

};
const cacheConfig = {};

function Api() {
    let cache = {};

    this.host = function () {
        return window.location.protocol + '//' + window.location.host;
    };
    this.route = function (options) {
        let route = routeApi[options.route];

        if (!route) {
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                } else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        return host + route;
    };
    this.get = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({status: 404});
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                } else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        if (cache[route] && cache[route].age.isAfter(moment())) {
            let data = cloneJson(cache[route].data);
            callback(data);
        } else {
            axios.get(host + route, {withCredentials: true})
                .then(function (response) {
                    if (cacheConfig[options.route]) {
                        if (options.route in cacheConfig) {
                            if (!cache[route]) {
                                cache[route] = {
                                    data: cloneJson(response.data),
                                    age: moment().add(cacheConfig[options.route], 's'),
                                };
                                callback(cloneJson(response.data));
                            }
                        }
                    } else {
                        callback(response.data);
                    }
                })
                .catch(err => {
                    console.log(options.route);
                    console.log(route);
                    console.log(err);
                    if (err.response.status === 401) {
                        dispatch(AuthenticationLogout())
                    } else {
                        if (err.response.data.status === 403 && err.response.data.error.message) {
                            dispatch(
                                SnackbarOpen({
                                    text: err.response.data.error.message,
                                    variant: 'error',
                                })
                            );
                        }
                        callback(err.response.data);
                    }
                });
        }
    };
    this.post = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        } else {
            data = JSON.stringify(options.data);
        }

        axios.post(host + route, data, {withCredentials: true})
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                console.log(err.response.data.error.message);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.delete = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        axios.delete(host + route, {withCredentials: true})
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });

    };
    this.postDownload = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        } else {
            data = JSON.stringify(options.data);
        }

        axios.post(host + route, data, {withCredentials: true, responseType: 'blob'})
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({status: 200});
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.getDownload = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({status: 404});
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (!options.data) {
            options.data = {download: 1};
        } else {
            options.data.download = 1;
        }

        route += '?';
        for (let index in options.data) {
            let dataGet = options.data[index] === null ? '' : options.data[index];
            dataGet = dataGet === undefined ? '' : dataGet;

            if (Array.isArray(dataGet)) {
                for (let index1 in dataGet) {
                    route += index + "[]=" + dataGet[index1] + "&";
                }
            } else {
                if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
            }
        }
        route = route.slice(0, -1);

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        axios.get(host + route, {withCredentials: true, responseType: 'blob'})
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({status: 200});
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    console.log(err.response.data);
                }
            });
    };
}
const api = new Api();

export default api;

export const learnerHomeFormationImageBanner = host + '/learner/home/formation-image/';
export const contentManagerFormationImageBanner = host + '/content-manager/formation/image/';
export const enrollmentRegisterFormationImageBanner = host + '/enrollment/register/formation-image/';
export const resource = host + '/resource/';

export const LibraryResourceImage = host + '/resource/library-resource-image/';
export const LibraryResourceTypeImage = host + '/resource/library-resource-type-image/';
export const LibraryResourceTypeIcon = host + '/resource/library-resource-type-icon/';
