import React from 'react';
import {Grid, LinearProgress, Step, StepLabel} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import arrowDown from "react-useanimations/lib/arrowDown";
import alertTriangle from "react-useanimations/lib/alertTriangle";
import {useDispatch, useSelector} from "react-redux";
import {getRoutePathname} from "../../../Config/Route";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api, {learnerHomeFormationImageBanner} from "../../../Api";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import Stepper from '@material-ui/core/Stepper';
import {makeStyles} from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import {Done, Lock, LockOpen} from "@material-ui/icons";
import moment from "moment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LockIcon from "@material-ui/icons/Lock";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import {hourBySecond} from "../../../Tool/convert";
import {theme} from "../../../App";
import MediaLinkComponent from "../../../Component/MediaLinkComponent";
import Countdown from "./Component/Countdown";
import ButtonComponent from "../../../Component/ButtonComponent";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';


function Registration(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(false);
    const [moduleEppPdf, setModuleEppPdf] = React.useState(false);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [registration, setRegistration] = React.useState({formation: {}, chapters: [], resourceGroups: []});
    const [countdownFinish, setCountdownFinish] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});


    const goCourse = (course) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_course', {registration: props.match.params.id, course: course.id}),
                state: {courseType: course.type}
            });
        }, 800);
    };
    const goSurveySatisfaction = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_satisfaction', {registration: props.match.params.id})
            });
        }, 800);
    };
    const goSurveyPrePost = (type) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_pre_post', {registration: props.match.params.id, type: type})
            });
        }, 800);
    };

    const goSurveyEpp = (type) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_epp', {registration: props.match.params.id, type: type})
            });
        }, 800);
    };

    const goVirtualClassroom = (joinUrl, virtualClassroom) => {
        saveCompletedVirtualClassroom(virtualClassroom);
        window.open(joinUrl, "_blank");
        /*
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_virtual_classroom', {registration: props.match.params.id, virtualClassroom: virtualClassroom})
            });
        }, 800);*/
    };
    const goVirtualClassroomReplay = (ReplayUrl, virtualClassroom) => {
        saveCompletedVirtualClassroom(virtualClassroom);
        window.open(ReplayUrl, "_blank");
        /*
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_virtual_classroom_replay', {registration: props.match.params.id, virtualClassroom: virtualClassroom})
            });
        }, 800);*/
    };

    React.useEffect(() => {

        if (props.match.params.id) {

            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                    route: 'learner_registration_get',
                    params: {id: props.match.params.id}
                },
                (response) => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        if (response.data && response.data.formation && response.data.formation.description) {
                            response.data.formation.description = response.data.formation.description.replace(/\n/g, "<br/>");
                        }

                        setRegistration(response.data);

                        setBreadcrumbs({
                            currentPage: response.data.formation.name,
                            links: [
                                {path: getRoutePathname('learner_home'), label: 'Tableau de bord'}
                            ]
                        });

                        // TODO a supp prochainement
                        if (response.data.hasSurveyEpp && response.data.isFinishPreEpp === false) {
                            if (response.data.pathPreEpp || response.data.pathPostEpp) {
                                if (response.data.courseDoneCount >= 1 || !(response.data.state === 5 || response.data.state === 3)) {
                                    setModuleEppPdf(false)
                                }
                                else {
                                    setModuleEppPdf(true)
                                }

                            }
                        }

                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_home'));
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);
    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    let disabled = !(registration.state === 1 || registration.state === 3 || registration.state === 5); // TODO A voir

    const saveCompletedVirtualClassroom = (id) => {

        Api.post({
            route: 'learner_registration_virtual_classroom_add',
            data: {"id": id},
            params: {registration: props.match.params.id}
        }, (response) => {
            if (response.status === 200) {
                //TODO ? Valid progress bar virtualClassroom
            }
            else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: 'Erreur',
                        variant: 'error',
                    })
                );
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });

    };
    const countdownFinishCallback = (childData) => {
        setCountdownFinish(childData);
    };

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <br/>
            <div style={{textAlign: 'center', maxWidth: 1240, margin: 'auto'}}>
                {
                    loading ?
                        <Fade in={true} {...{timeout: 1000}}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35}/>
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <div>
                            <Fade in={show} {...{timeout: 1000}}>
                                <div style={{width: isSmallView ? '90%' : '80%', margin: 'auto'}}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                            {!load && <img style={{width: (screenReducer.screen === 'XS') ? '50%' : '100%'}} src={'/asset/images/preload_image.png'} alt={'Image formation'}/>}
                                            <img style={{width: (screenReducer.screen === 'XS') ? '50%' : '100%'}} src={registration.formation && learnerHomeFormationImageBanner + registration.formation.image} alt={'Image formation'} onLoad={() => setLoad(true)}/>
                                        </Grid>
                                        <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                            <h1 style={{marginTop: 20, textAlign: 'left'}}>{registration.formation.name}</h1>
                                            <br/>
                                            <div style={{textAlign: 'left'}}>
                                                <span style={{fontWeight: 700}}>Statut</span> :
                                                {(registration.state === 1) && <>
                                                    <span className={classes.cardStateText}>Disponible</span>
                                                    <div style={{backgroundColor: '#28a745'}} className={classes.cardState}><CheckCircleOutlineIcon className={classes.cardStateIcon}/></div>
                                                </>}
                                                {(registration.state === 2) && <>
                                                    <span className={classes.cardStateText}>Bientôt disponible</span>
                                                    <div style={{backgroundColor: '#17a2b8'}} className={classes.cardState}><LockIcon className={classes.cardStateIcon}/></div>
                                                </>}
                                                {(registration.state === 3) && <>
                                                    <span className={classes.cardStateText}>Terminée</span>
                                                    <div style={{backgroundColor: '#6c757d'}} className={classes.cardState}><EmojiEventsIcon className={classes.cardStateIcon}/></div>
                                                </>}
                                                {(registration.state === 4) && <>
                                                    <span className={classes.cardStateText}>ANDPC en attente</span>
                                                    <div style={{backgroundColor: '#ffc107'}} className={classes.cardState}><HourglassEmptyIcon className={classes.cardStateIcon}/></div>
                                                </>}
                                                {(registration.state === 5) && <>
                                                    <span className={classes.cardStateText}>Terminée</span>
                                                    <div style={{backgroundColor: '#6c757d'}} className={classes.cardState}><EmojiEventsIcon className={classes.cardStateIcon}/></div>
                                                </>}
                                                {(registration.state === 6) && <>
                                                    <span className={classes.cardStateText}>Financement en attente</span>
                                                    <div style={{backgroundColor: '#ffc107'}} className={classes.cardState}><HourglassEmptyIcon className={classes.cardStateIcon}/></div>
                                                </>}
                                            </div>
                                            {
                                                registration.startAt &&
                                                <p style={{textAlign: 'left'}}>
                                                    <span style={{fontWeight: 700}}>Session</span> : {moment(registration.startAt).format('ll')} au {moment(registration.endAt).format('ll')}
                                                </p>
                                            }
                                        </Grid>

                                        <br/>

                                        <div style={{display: 'flex'}}>
                                            <p className={classes.whiteBox}>{registration.formation.duration / 3600}<br/><span style={{fontSize: 13, fontWeight: 200}}>Heures</span></p>

                                            <p className={classes.whiteBox}>{registration.formation.financeType}<br/><span style={{fontSize: 13, fontWeight: 200}}>Financement</span></p>
                                        </div>

                                        <br/>

                                        {registration.formation.description &&
                                        <div style={{textAlign: 'left', fontSize: 13}}>
                                            <h3 style={{marginTop: 10}}>Introduction</h3>
                                            <div dangerouslySetInnerHTML={{__html: registration.formation.description}}/>
                                        </div>}

                                        {registration.formation.objective &&
                                        <div style={{textAlign: 'left', fontSize: 13}}>
                                            <h3 style={{marginTop: 10}}>Objectifs pédagogiques</h3>
                                            <div dangerouslySetInnerHTML={{__html: registration.formation.objective}}/>
                                        </div>}
                                    </Grid>
                                    <br/>
                                    <br/>
                                    {
                                        (registration.state === 1 || registration.state === 3 || registration.state === 5) ?

                                            <div style={{background: '#fff', boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',}}>
                                                <h3 style={{textAlign: 'left', padding: '15px 0px 0px 20px', margin: 0}}>Votre progression</h3>
                                                <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly'}}>
                                                    <div className={classes.progression}>
                                                        <LinearProgress style={{width: '100%', padding: 2, margin: '7px 0'}} variant="determinate" value={registration.courseDoneCount * 100 / registration.courseCount}/>
                                                        <span style={{fontWeight: 700}}>Cours terminés </span> : {registration.courseDoneCount} / {registration.courseCount}
                                                    </div>
                                                    <div className={classes.progression}>
                                                        <LinearProgress style={{width: '100%', padding: 2, margin: '7px 0'}} variant="determinate" value={registration.timeSpent * 100 / registration.formation.duration >= 100 ? 100 : registration.timeSpent * 100 / registration.formation.duration}/>
                                                        <span style={{fontWeight: 700}}>Temps passé </span> : {registration.timeSpent > 0 ? hourBySecond(registration.timeSpent) : 0} sur {hourBySecond(registration.formation.duration)}
                                                    </div>
                                                </div>
                                                <br/>

                                                {
                                                    (registration.hasVirtualClassroom && registration.unlockVirtualClassroom) &&
                                                    Object.keys(registration.virtualClassroomsInfos).map((id, index) => (
                                                        registration.virtualClassroomsInfos[id].active ?
                                                            <div className={classes.virtualClassroom} key={index}>
                                                                {
                                                                    !registration.virtualClassroomsInfos[id].meetingFinish ?
                                                                        countdownFinish ?
                                                                            <>
                                                                                <p className={classes.virtualClassroomIntro}>Rejoindre la classe vitruelle :</p>
                                                                                <div className={classes.buttonGroup}>

                                                                                    <ButtonComponent label={'Rejoindre'} onClick={() => {
                                                                                        goVirtualClassroom(registration.virtualClassroomsInfos[id].joinUrl, registration.virtualClassroomsInfos[id].id)
                                                                                    }} disabled={loading}/>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <Countdown callback={countdownFinishCallback} unixEndDate={moment(registration.virtualClassroomsInfos[id].meetingAt).valueOf() / 1000}/>
                                                                        :
                                                                        <>
                                                                            <p className={classes.virtualClassroomIntro}>La classe virtuelle s'est déjà tenue.</p>
                                                                            {
                                                                                registration.virtualClassroomsInfos[id].replayUrl != '' ?
                                                                                    <>
                                                                                        <p>Pour rejouer cette classe virtuelle, cliquez sur le bouton ci-dessous :</p>

                                                                                        <ButtonComponent label={'Visionner'} onClick={() => {
                                                                                            goVirtualClassroomReplay(registration.virtualClassroomsInfos[id].replayUrl, registration.virtualClassroomsInfos[id].id)
                                                                                        }} disabled={loading}/>
                                                                                    </>
                                                                                    :
                                                                                    <p>La rediffusion de cette classe virtuelle sera prochainement disponible.</p>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                            :
                                                            <></>
                                                    ))
                                                }
                                                {
                                                    (registration.isFinishBeforeDeploymentEpp && registration.pathPreEpp) ?
                                                        <>
                                                            <div className={classes.chapterEPP}>EPP temps 1</div>
                                                            <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                                <Step active={true} completed={true}>
                                                                    <StepLabel StepIconComponent={StepIcon}>
                                                                        <MediaLinkComponent
                                                                            style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                                                                            path={registration.pathPreEPP}
                                                                            type={1}
                                                                            disabled={false}
                                                                        >EPP temps 1
                                                                        </MediaLinkComponent>
                                                                    </StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </>
                                                        :
                                                        (registration.hasSurveyEpp && registration.hasQuestionEpp) &&
                                                        <>
                                                            <div className={classes.chapterEPP}>Évaluation des pratiques professionnelles : temps 1</div>

                                                            {
                                                                (registration.inProgressFormation && !registration.isFinishPreEpp) &&
                                                                <div style={{margin: '20px 10px 0 10px', padding: '10px', border: '1px', borderRadius: '20px', color: 'white', background: '#ffc107'}}>
                                                                    <p style={{margin: 0, textAlign: 'center', color: '#000'}}><b>Nouvelle règlementation</b></p>
                                                                    <UseAnimations animation={alertTriangle} size={80} wrapperStyle={{margin: 'auto'}}/>

                                                                    <p style={{margin: 0, padding: '10px', color: '#000'}}>
                                                                        À la suite de la nouvelle réglementation concernant le questionnaire des critères d’évaluation des pratiques professionnelles (EPP),
                                                                        nous vous invitons à le refaire pour reprendre votre formation là où vous en étiez.
                                                                    </p>
                                                                    <p style={{color: '#000'}}>
                                                                        <UseAnimations animation={arrowDown} size={30} wrapperStyle={{display: 'inline-block', verticalAlign: 'middle'}}/>
                                                                        Cliquez sur le lien ci-dessous
                                                                        <UseAnimations animation={arrowDown} size={30} wrapperStyle={{display: 'inline-block', verticalAlign: 'middle'}}/>
                                                                    </p>
                                                                </div>
                                                            }
                                                            <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                                <Step active={registration.unlockSurveyPreEpp} completed={registration.unlockSurveyPreEpp && registration.isFinishPreEpp}>
                                                                    <StepLabel StepIconComponent={StepIcon}>
                                                                        {registration.unlockSurveyPreEpp ? <span className={classes.linkRegistration} onClick={() => goSurveyEpp("pre")}>
                                                                            Audit clinique : grille d’évaluation 1
                                                                        </span> : <span style={{color: '#999'}}>
                                                                            Audit clinique : grille d’évaluation 1
                                                                        </span>
                                                                        }
                                                                    </StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </>
                                                }
                                                {
                                                    registration.hasSurveyPre &&
                                                    <>
                                                        <div className={classes.chapter}>Bilan pré-formation</div>
                                                        <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                            <Step active={registration.unlockSurveyPre} completed={registration.unlockSurveyPre && registration.isFinishPre}>
                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                    {registration.unlockSurveyPre ? <span className={classes.linkRegistration} onClick={() => goSurveyPrePost("pre")}>
                                                                        Questionnaire pré-formation
                                                                    </span> : <span style={{color: '#999'}}>
                                                                        Questionnaire pré-formation
                                                                    </span>
                                                                    }
                                                                </StepLabel>
                                                            </Step>
                                                        </Stepper>
                                                    </>
                                                }
                                                {registration.chapters.map((chapter) => (
                                                    <div key={chapter.id}>
                                                        <div className={classes.chapter}>{chapter.name}</div>
                                                        <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                            {chapter.courses.map((course) => (
                                                                <Step key={course.id} active={(course.unlock || course.forcedUnlock)} completed={course.done}>
                                                                    <StepLabel StepIconComponent={StepIcon}>
                                                                        {(course.unlock || course.forcedUnlock) ? <span className={classes.linkRegistration} onClick={() => goCourse(course)}>
                                                                            {course.name}
                                                                        </span> : <span style={{color: '#999'}}>
                                                                            {course.name}
                                                                        </span>
                                                                        }
                                                                        <span style={{float: 'right', color: '#999'}}>
                                                                            {course.score > 0 && '(Score : ' + course.score + ' %)'}
                                                                        </span>
                                                                    </StepLabel>
                                                                </Step>
                                                            ))}
                                                        </Stepper>
                                                    </div>
                                                ))}
                                                {
                                                    registration.hasSurveyPost &&
                                                    <>
                                                        <div className={classes.chapter}>Bilan post-formation</div>
                                                        <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                            <Step active={registration.unlockSurveyPost} completed={registration.unlockSurveyPost && registration.isFinishPost}>
                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                    {registration.unlockSurveyPost ? <span className={classes.linkRegistration} onClick={() => goSurveyPrePost("post")}>
                                                                        Questionnaire post-formation
                                                                    </span> : <span style={{color: '#999'}}>
                                                                        Questionnaire post-formation
                                                                    </span>
                                                                    }
                                                                </StepLabel>
                                                            </Step>
                                                        </Stepper>
                                                    </>
                                                }
                                                {
                                                    (registration.isFinishBeforeDeploymentEpp && registration.pathPostEpp) ?
                                                        <>
                                                            <div className={classes.chapterEPP}>EPP temps 2</div>
                                                            <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                                <Step active={true} completed={true}>
                                                                    <StepLabel StepIconComponent={StepIcon}>
                                                                        <MediaLinkComponent
                                                                            style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                                                                            path={registration.pathPostEpp}
                                                                            type={1}
                                                                            disabled={false}
                                                                        >EPP temps 2
                                                                        </MediaLinkComponent>
                                                                    </StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </>
                                                        :
                                                        (registration.hasSurveyEpp && registration.hasQuestionEpp) &&
                                                        <>
                                                            <div className={classes.chapterEPP}>Évaluation des pratiques professionnelles : temps 2</div>
                                                            <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                                <Step active={registration.unlockSurveyPostEpp} completed={registration.unlockSurveyPostEpp && registration.isFinishPostEpp}>
                                                                    <StepLabel StepIconComponent={StepIcon}>
                                                                        {registration.unlockSurveyPostEpp ? <span className={classes.linkRegistration} onClick={() => goSurveyEpp("post")}>
                                                                            Audit clinique : grille d’évaluation 2
                                                                        </span> : <span style={{color: '#999'}}>
                                                                            Audit clinique : grille d’évaluation 2
                                                                        </span>
                                                                        }
                                                                    </StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </>
                                                }
                                                {
                                                    registration.pathPostEPP &&
                                                    <>
                                                        <div className={classes.chapterEPP}>Questionnaire de satisfaction</div>
                                                        <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                            <Step active={registration.unlockSurveySatisfaction} completed={registration.unlockSurveySatisfaction && registration.isFinishSurveySatisfaction}>
                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                    {registration.unlockSurveySatisfaction
                                                                        ? <span className={classes.linkRegistration} onClick={() => goSurveySatisfaction()}>
                                                                            Questionnaire de satisfaction
                                                                        </span> : <span style={{color: '#999'}}>
                                                                            Questionnaire de satisfaction
                                                                        </span>
                                                                    }
                                                                </StepLabel>
                                                            </Step>
                                                        </Stepper>
                                                    </>
                                                }
                                                {
                                                    registration.pathHonorCertificate &&
                                                    <>
                                                        <div className={classes.chapterEPP}>Attestation sur l'honneur</div>
                                                        <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                            <Step active={true} completed={true}>
                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                    <MediaLinkComponent
                                                                        style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                                                                        path={registration.pathHonorCertificate}
                                                                        type={1}
                                                                        disabled={false}
                                                                    >Attestation sur l'honneur
                                                                    </MediaLinkComponent>
                                                                </StepLabel>
                                                            </Step>
                                                        </Stepper>
                                                    </>
                                                }
                                                {
                                                    registration.hasSurveySatisfaction &&
                                                    <>
                                                        <div className={classes.chapter}>Questionnaire de satisfaction</div>
                                                        <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector}/>}>
                                                            <Step active={registration.unlockSurveySatisfaction} completed={registration.unlockSurveySatisfaction && registration.isFinishSurveySatisfaction}>
                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                    {registration.unlockSurveySatisfaction
                                                                        ? <span className={classes.linkRegistration} onClick={() => goSurveySatisfaction()}>
                                                                            Questionnaire de satisfaction
                                                                        </span> : <span style={{color: '#999'}}>
                                                                            Questionnaire de satisfaction
                                                                        </span>
                                                                    }
                                                                </StepLabel>
                                                            </Step>
                                                        </Stepper>
                                                    </>
                                                }
                                            </div>
                                            :
                                            <div>
                                                <p className={classes.link} onClick={() => {
                                                    window.open("https://www.santeformapro.com/contact/", "_blank")
                                                }}>Contacter notre équipe</p>
                                                <p className={classes.link} onClick={() => {
                                                    window.open("https://www.santeformapro.com/comment-sinscrire-a-une-formation-financee-par-landpc/", "_blank")
                                                }}>Procédure d'inscription</p>
                                            </div>
                                    }
                                    <br/>

                                    {(registration.resourceGroups.length > 0 && (registration.state === 1 || registration.state === 3 || registration.state === 5)) &&
                                    <>
                                        <h3>Ressources de la formation</h3>
                                        <Grid container spacing={2}>
                                            {registration.resourceGroups.map((resourceGroup, index) => (
                                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                                                    <p style={{fontWeight: 700, marginBottom: 10, textAlign: 'left'}}>{resourceGroup.name}</p>
                                                    {resourceGroup.resources.map((resource, i) => (
                                                        <div key={i} style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ? {color: '#bbb', fontSize: 14, lineHeight: '20px', textAlign: 'left'} : {color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', textAlign: 'left'}}>
                                                            <MediaLinkComponent
                                                                style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ? {color: '#bbb', fontSize: 14, lineHeight: '20px'} : {color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                                                                path={resource.path}
                                                                type={resource.type}
                                                                disabled={disabled || (registration.state !== 5 && resourceGroup.id === 1)}
                                                            >
                                                                {resource.name}
                                                            </MediaLinkComponent>
                                                        </div>
                                                    ))}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>}
                                </div>
                            </Fade>
                        </div>
                }
            </div>
        </>

    );
}

const useStyles = (theme) => makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
            {
                margin: 'auto',
                width: '15% !important'
            }
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    },
    chapterEPP: {
        color: '#e62469',
        padding: 8,
        fontSize: 18,
        paddingLeft: 15,
        textAlign: 'left',
        '& + div': {
            background: '#fff',
            textAlign: 'left'
        }
    },
    chapter: {
        color: '#4AA3A2',
        padding: 8,
        fontSize: 18,
        paddingLeft: 15,
        textAlign: 'left',
        '& + div': {
            background: '#fff',
            textAlign: 'left'
        }
    },
    stepConnector: {
        background: '#fff',
        padding: 0,
        marginLeft: 16,
        height: 18,
        '& .MuiStepConnector-line': {
            height: 5,
            width: 1,
            border: 0,
            backgroundColor: '#c0c0c3',
            borderRadius: 1,
        }
    },
    cardState: {
        color: '#FFFFFF',
        padding: '3px 4px',
        borderRadius: 30,
        display: 'initial',
        marginLeft: 5
    },
    cardStateText: {
        fontWeight: 700,
        padding: '3px 5px',
        fontSize: 14,
        display: 'initial',
    },
    cardStateIcon: {
        fontSize: 19,
        width: 17,
        height: 19,
        verticalAlign: 'bottom'
    },
    linkRegistration: {
        cursor: 'pointer',
        color: theme.colorPrimary,
        '&:hover': {
            color: '#3f51b5',
        }
    },
    virtualClassroom: {
        marginBottom: '20px',
        padding: '10px',
        background: '#f8f8f8',
        borderRadius: '5px',
        textAlign: 'center'
    },
    virtualClassroomIntro: {
        margin: 0,
        fontWeight: 'bold'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    whiteBox: {
        padding: '25px 50px',
        background: '#fff',
        border: '1px solid #F2F2F2',
        borderRadius: 10,
        margin: '10px 10px 10px 0px',
        fontSize: 17,
        fontWeight: 600
    },
    progression: {
        fontSize: 15,
        width: '45%',
        '& .MuiLinearProgress-root': {
            height: 8,
            borderRadius: 10
        },
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: '#c8dfe5'
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#4f93a5'
        }
    },
    link: {
        cursor: 'pointer',
        fontSize: 13,
        textAlign: 'left',
        '&:hover': {
            opacity: 0.6
        }
    }
});
const useStepIconStyles = (theme) => makeStyles(({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 32,
        height: 32,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        '& svg': {
            fontSize: 18,
        }
    },
    active: {
        backgroundImage: 'linear-gradient(136deg, ' + theme.colorPrimary + ' 0%, ' + theme.colorPrimary + ' 40%, ' + theme.colorPrimary + ' 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    lock: {
        backgroundImage: 'linear-gradient(136deg, #8C8C8C 0%, #8C8C8C 40%, #8C8C8C 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(136deg, #6FD08C 0%, #6FD08C 40%, #28a745 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }
}));
const StepIcon = (props) => {
    const classes = useStepIconStyles(theme)();
    const {active, completed} = props;
    const icons = [<Lock/>, <LockOpen/>, <Done/>];
    let icon;

    if (active && completed) {
        icon = icons[2];
    }
    else if (active) {
        icon = icons[1];
    }
    else {
        icon = icons[0];
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.lock]: !active,
                [classes.completed]: completed,
            })}
        >
            {icon}
        </div>
    );
}

export default Registration;
