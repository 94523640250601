import React from 'react';
import {useDispatch} from "react-redux";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import {MediaShow} from "../Action/MediaAction";
import {resource} from "../Api";

function MediaLinkComponent(props) {
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = React.useState(false);

    let isAbsolute;

    try {
        let url = new URL(props.path);
        isAbsolute = true;
    } catch (_) {
        isAbsolute = false;
    }

    const action = () => {
        if (props.disabled) return;
        if (props.type === 1) { // PDF
            if (props.isLibrary) {
                dispatch(MediaShow({
                    type: props.type,
                    path: isAbsolute ? props.path : resource + 'library/pdf/' + props.path
                }));
            }
            else {
                dispatch(MediaShow({
                    type: props.type,
                    path: isAbsolute ? props.path : resource + 'pdf/' + props.path
                }));
            }
        }
        else if (props.type === 2) { // Course

        }
        else if (props.type === 3) { // Youtube
            dispatch(MediaShow({
                type: props.type,
                path: isAbsolute ? props.path : 'https://www.youtube.com/embed/' + props.path
            }));
        }
        else if (props.type === 4) { // Image
            if (props.isLibrary) {
                dispatch(MediaShow({
                    type: props.type,
                    path: isAbsolute ? props.path : resource + 'library/image/' + props.path
                }));
            }
            else {
                dispatch(MediaShow({
                    type: props.type,
                    path: isAbsolute ? props.path : resource + 'image/' + props.path
                }));
            }
        }
        else if (props.type === 5) { // MP3
            if (props.isLibrary) {
                dispatch(MediaShow({
                    type: props.type,
                    path: isAbsolute ? props.path : resource + 'library/mp3/' + props.path
                }));
            }
            else {
                dispatch(MediaShow({
                    type: props.type,
                    path: isAbsolute ? props.path : resource + 'mp3/' + props.path
                }));
            }
        }
        else if (props.type === 6) { // Lien
            window.open(props.path, "_blank");
        }
        else {
            setShowAlert(true);
        }
    }

    return (
        <>
            <span style={props.style ? props.style : {}} onClick={action}>
                {props.children}
            </span>
            <SweetAlert
                show={showAlert}
                title="Ressource indisponible..."
                html
                text="Toutes nos excuses pour la gêne occasionné, nous faisons notre possible pour rendre cette ressource disponible"
                onConfirm={() => {
                    setShowAlert(false)
                }}
            />
        </>

    );
}

export default MediaLinkComponent;
