import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import PaginationComponent from './PaginationComponent';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from '@material-ui/lab/ToggleButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Refresh } from "@material-ui/icons";
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import { theme } from "../App";
import { Box, InputAdornment, TextField } from '@material-ui/core';

export default function FilterPaginationComponent(props = {
    data: null,
    dataKey: null,
    pageNumber: null,
    viewList: null,
    title: null,
    icon: null,
    loading: null,
    loadData: null,
    filter: [],
    style: '',
    error: '',
    backgroundColor: '',
    className: '',
}) {
    const classes = useStyles(theme)();
    const data = Object.values(props.data);
    const [isFilter, setIsFilter] = React.useState(false);
    const [formatFilters, setFormatFilters] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [items, setItems] = React.useState(() => []);
    const [searchText, setSearchText] = React.useState("");
    const RefisFilter = React.useRef();

    const loadData = () => {
        return (
            <>
                {props.loading ? <><CircularProgress size={20} className={classes.circularProgress} /></>
                    :
                    <IconButton style={{ padding: 5, margin: '0 6px' }} onClick={() => { props.loadData(); }}>
                        <Refresh />
                    </IconButton>
                }
            </>
        )
    }

    const onFieldFilter = (event) => {
        setSearchText(event.target.value)
    }
    const textFieldFilter = () => {
        return (
            <TextField
                id="input-with-icon-textfield"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={onFieldFilter}
                InputProps={{
                    'aria-label': 'description',
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{ color: 'rgb(94, 110, 130)' }} />
                        </InputAdornment>
                    ),
                }}
            />
        )
    }

    const menuItems = (menuItem) => {
        const menuItems = [...new Set(data.map((value) => {
            if (menuItem.type === 'button' && value[menuItem.data]) {
                if (typeof value[menuItem.data] === 'string') return value[menuItem.data].toString()
                const arrayFilter = Object.values(value[menuItem.data])
                if (typeof arrayFilter === 'object' && arrayFilter.length != 0) {
                    for (var item in arrayFilter) return arrayFilter[item]
                }
            }
        }))];
        return (
            (menuItems.length >= 2) &&
            menuItems.map((value, id) => {
                if (value) {
                    return (
                        <ToggleButton style={{ fontSize: 10, margin: "4px", border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: 4, padding: '6px 11px' }} value={value} aria-label={value} key={id}>
                            {value}
                        </ToggleButton>
                    )
                }
            })
        )
    }
    const handleItems = (event, curcat) => {
        setItems(curcat);
    };
    const viewFilter = () => {
        return (
            <Box ref={RefisFilter}>
                <Box style={{ height: 'auto', width: '100%', margin: '0px auto 0px auto', padding: '10px 0px 18px 0', textAlign: 'initial' }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ margin: 0, padding: '2px 15px 2px 0', fontWeight: 'bold' }}>- Recherche -</p>
                        {<Box>{textFieldFilter()}</Box>}
                    </Box>
                    <Box className={classes.boxFilter}>
                        {filters.map((value, id) => {
                            return (
                                <Box style={{ textAlign: 'left', display: 'inline-block', padding: '0' }} key={id}>
                                    {(value.title && menuItems(value).length >= 2) && <p style={{ margin: 0, padding: 5 }}><b>|</b> {value.title}</p>}
                                    <ToggleButtonGroup
                                        value={items}
                                        onChange={handleItems}
                                        aria-label="text formatting"
                                    >
                                        {menuItems(value)}
                                    </ToggleButtonGroup>
                                </Box>
                            )
                        })}

                    </Box>
                </Box>
            </Box>
        )
    }

    const onIsFilter = () => {
        isFilter === false ? setIsFilter(true) : setIsFilter(false)
    }
    
    React.useEffect(() => {
        const filterButton = data.filter(newValue => {
            if (props.filter) {
                for (let value = 0; props.filter.length > value; value++) {
                    let valueFilter = newValue[props.filter[value].data];
                    if (props.filter[value] && props.filter[value].type === 'button') {
                        if (typeof valueFilter === 'string')
                            if (items.includes(valueFilter)) return true;
                        if (typeof valueFilter === 'object' && valueFilter.length != 0)
                            for (let item in valueFilter) if (items.includes(valueFilter[item])) return true;
                    }
                }
            }
        });

        if (filterButton.length != 0) {
            const dataFilterButton = filterButton.filter(newValue => {
                if (props.filter) {
                    for (let value = 0; props.filter.length > value; value++) {
                        let valueFilter = newValue[props.filter[value].data];
                        if (props.filter[value] && props.filter[value].type === 'textField')
                            if (valueFilter.toLowerCase().includes(searchText.toLowerCase())) return true;
                    }
                }
            })
            setFormatFilters(dataFilterButton);
        } else {
            const dataFilter = data.filter(newValue => {
                if (props.filter) {
                    for (let value = 0; props.filter.length > value; value++) {
                        let valueFilter = newValue[props.filter[value].data];
                        if (props.filter[value] && props.filter[value].type === 'textField')
                            if (valueFilter.toLowerCase().includes(searchText.toLowerCase())) return true;
                    }
                }
            })
            setFormatFilters(dataFilter);
        }
        
    }, [searchText, items ]);

    React.useEffect(() => {
        setFormatFilters(Object.values(props.data));
    }, [props.data]);

    React.useEffect(() => {
        setFilters(props.filter)
    }, [props.filter]);
    return (
        <Box className={props.className ? props.className : classes.PaginationComponent} style={{ overflow: "hidden", color: '#5E6E82' }} >
            <Grid container spacing={3} className={classes.boxGroup} style={{ background: 'rgba(' + props.backgroundColor + ')', }}>
                <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 0px 17px' }}>
                        <Box style={{ padding: '5px', transform: 'translate(0,6%)' }}>{props.icon ? props.icon : ''}</Box>
                        <p className={classes.titleGroup}>{props.title ? props.title : ''}</p>
                    </Box>
                    {(props.loadData) && loadData()}
                    {props.filter && <IconButton style={{ padding: 5, margin: '0 25px 0 auto' }} onClick={onIsFilter} ><FilterListIcon /></IconButton>}
                </Box>
                {(props.filter) && <hr align="left" style={{ color: 'rgba(' + props.backgroundColor + ')', width: "100%", margin: "auto auto 0px auto", opacity: 0.5 }} />}
                {(filters && props.filter) &&
                    <>
                        <Zoom in={isFilter} style={{ transitionProperty: 'all', transitionDuration: '.3s', overflow: 'hidden', height: isFilter ? RefisFilter.current.offsetHeight + 'px' : '0px', opacity: isFilter ? '1' : '0' }}>
                            <Box>{viewFilter()}</Box>
                        </Zoom>
                    </>
                }
            </Grid>
            <Grid container spacing={3} className={classes.boxGroup} style={{ background: 'rgba(' + props.backgroundColor + ',0.5)' }}>
                <PaginationComponent
                    dataList={formatFilters}
                    pageNumber={props.pageNumber}
                    viewList={props.viewList}
                    error={props.error}
                    statutData={items}
                    backgroundColor={'rgba(' + props.backgroundColor + ')'}
                />
            </Grid>
        </Box>
    )
}
const useStyles = (theme) => makeStyles(({
    circularProgress: {
        margin: 10,
        color: theme.colorPrimary
    },
    boxTitle: {},
    span: {
        margin: '0 10px',
        fontSize: 10,
        height: 10,
        display: 'inline-block',
        verticalAlign: 'bottom',
        padding: '18px 0',
        color: '#5E6E82'
    },
    titleGroup: {
        fontWeight: 600,
        textAlign: "left",
        margin: 0,
        padding: "12px 0px",
        fontSize: '1rem',
    },
    boxGroup: {
        margin: 0,
        width: '100%',
    },
    boxFilter: {
        margin: 0,
        width: '100%',
    },
    PaginationComponent: {
        '& .MuiButton-text': {
            color: '#5E6E82',
        },
        '& .MuiToggleButtonGroup-root': {
            display: 'initial',
        },
        '& .MuiToggleButton-root': {
            color: theme.colorPrimary,
            backgroundColor: "#FFF",
            transition: 'all .3s'
        },
        '& .MuiToggleButton-root.Mui-selected': {
            color: "#FFF",
            backgroundColor: theme.colorPrimary,
            transition: 'all .3s'
        },
        '& .MuiToggleButton-label': {
            zIndex: 1,
        },
        '&  .MuiToggleButton-root.Mui-selected:hover': {
            color: "#FFF",
            textDecoration: 'none',
            backgroundColor: theme.colorPrimary + '99',
        },
        '&  .MuiToggleButton-root:hover': {
            color: "#FFF",
            textDecoration: 'none',
            backgroundColor: theme.colorPrimary + '99',
        }
    },
    root: {
        '& > *': {
            margin: 0,
            width: '25ch',
        },
    },
}))