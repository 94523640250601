import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { TableReload } from "../../../Action/TableAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Edit } from "@material-ui/icons";
import { inArray } from "../../../Tool/ArrayCommon";
import { getRoutePathname } from "../../../Config/Route";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import Checkbox from "@material-ui/core/Checkbox";
import EditorComponent from '../../../Component/EditorComponent';

function GroupPrivatePostList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isEdition, setIsEdition] = React.useState(false);
    const [postId, setPostId] = React.useState({});

    // FORM
    const [form, setForm] = React.useState({
        title: {
            name: 'title',
            label: 'Titre',
            textHelper: 'Saisissez un titre.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        subTitle: {
            name: 'subTitle',
            label: 'Sous titre',
            textHelper: 'Saisissez un sous titre.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        isLock: {
            name: 'isLock',
            label: 'Afficher le groupe',
            textHelper: '',
            type: 'boolean',
            defaultValue: false
        },
        isComment: {
            name: 'isComment',
            label: 'Commentaire',
            textHelper: '',
            type: 'boolean',
            defaultValue: false
        }
    });

    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);

    const save = (id) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                let datas = handler.getData();
                Api.post({
                    route: 'community_group_private_post_edit',
                    data: datas,
                    params: { groupPrivate: props.match.params.id, id: id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Poste modifiée.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('community_group_private_post_list'));
                        setDialog(false);
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'community_group_private_post_add',
                    params: { groupPrivate: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Groupe enregistré.',
                                variant: 'success',
                            })
                        );
                        props.history.push(getRoutePathname('community_group_private_post', { id: response.data.id, groupPrivateId: props.match.params.id }));
                        setDialog(false);
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'title',
            label: 'Titre',
            options: { filter: true, sort: true }
        },
        {
            name: 'author',
            label: 'Créer par',
            options: { filter: true, sort: true }
        },
        {
            name: 'numComment',
            label: 'Nombres de commentaire',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: 'Date de création',
            options: { filter: true, sort: true }
        },
        {
            name: 'active',
            label: 'Afficher',
            options: { filter: true, sort: true }
        },
        {
            name: 'isComment',
            label: 'Commentaire',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    React.useEffect(handler.start, []);

    const editAction = (id) => {
        if (id) {
            setIsEdition(true);
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'community_group_private_post_get',
                params: { id: id, groupPrivate: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                        setPostId(data.id);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            setIsEdition(false);
        }
    }

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('community_group_private_post', { id: row.id, groupPrivateId: props.match.params.id }));
                    }}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Edit'} placement="left">
                    <IconButton onClick={() => {
                        editAction(row.id);
                        handler.reset();
                        setDialog(true);
                        setIsEdition(true);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'order', direction: 'desc' }}
                id={'community_group_private_post_list'}
                title={'Discussion lié aux groupe suivants : '}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une discussion',
                    onClick: () => {
                        handler.reset();
                        setDialog(true);
                        setIsEdition(false);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'community_group_private_post_list',
                            params: { groupPrivate: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).format('lll');
                                    data[index].subTitle = data[index].subTitle ? data[index].subTitle : '-';
                                    data[index].action = getAction(data[index]);
                                    data[index].active = <Checkbox key={data[index].id} checked={data[index].isLock} disabled />;
                                    data[index].isComment = <Checkbox key={data[index].id} checked={data[index].isComment} disabled />;
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialog} className={classes.dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15 }}>{isEdition ? <>Édite un poste</> : <>Ajouter un poste</>}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'title'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <EditorComponent name={'subTitle'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <CheckboxComponent name={'isLock'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <CheckboxComponent name={'isComment'} handler={handler} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save(postId)} disabled={loading} />
                </DialogActions>
            </Dialog>
        </>

    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    dialog: {
        '& .MuiFormControl-root ': {
            width: '100%'
        },
        '& .rdw-editor-main': {
            minHeight: 150,
        }
    }
});

export default GroupPrivatePostList;
