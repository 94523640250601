import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import {getRoutePathname} from "../../../Config/Route";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {theme} from "../../../App";
import {ScreenFull} from "../../../Action/ScreenAction";
import Fade from "@material-ui/core/Fade";
import {makeStyles} from "@material-ui/core";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import IconButton from "@material-ui/core/IconButton";
import {Fullscreen, FullscreenExit} from "@material-ui/icons";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

function RegistrationVirtualClassroomReplay(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(null);
    const [replay, setReplay] = React.useState(null);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isSmallBack, setSmallBack] = React.useState(false);

    const goBack = () => {
        setShow(false);
    };

    // Iframe Scale
    const [iFrameScale, setIFrameScale] = React.useState(1);
    const generateIFrameScale = () => {

        if (!isScale) return;

        let orientation = window.screen.orientation && window.screen.orientation.angle === 90;
        setSmallBack(orientation);

        if (screenReducer.full) {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (screenReducer.full ? 0 : 58)) / iFrameHeight;

            if (orientation) {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            } else {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            }
        } else {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (screenReducer.full ? 0 : 58)) / iFrameHeight;

            if (orientation) {
                if (window.innerHeight < iFrameHeight) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(1);
                }
            } else {
                if (window.innerWidth < iFrameWidth) {
                    setIFrameScale(ratioWidth);
                } else if (window.innerHeight < iFrameHeight) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(1);
                }
            }
        }
    };
    React.useEffect(generateIFrameScale, [screenReducer.full, screenReducer.size, screenReducer.orientation]);
    React.useEffect(() => {
        if (screenReducer.orientation && isScale) {
            setTimeout(() => dispatch(ScreenFull(true)), 50);
        }
    }, [screenReducer.orientation]);

    let isScale = true;
    let styleContent = {};
    let styleIframe = {};
    let iFrameWidth = 0;
    let iFrameHeight = 0;


    iFrameWidth = 1240;
    iFrameHeight = '100%';
    styleContent = {maxWidth: iFrameWidth, width: '100%', height: '100%', zIndex: 1, position: 'relative'};
    styleIframe = {border: 0, height: 'calc(100vh - 150px)', width: '100%', marginTop: 90};
    isScale = false;

    React.useEffect(() => {
        console.log("la");
        setTimeout(() => setLoading(true), 50);
        if (props.match.params.registration && props.match.params.virtualClassroom) {
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                    route: 'learner_registration_virtual_classroom_replay_get',
                    params: {
                        registration: props.match.params.registration,
                        virtualClassroom: props.match.params.virtualClassroom
                    }
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    console.log(response);
                    if (response.status === 200) {
                        let data = response.data;
                        setReplay(data);
                        setLoading(false);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        if (replay) {
            setLoading(false);
        }
    }, [replay]);
    return (
        <div style={{textAlign: 'center', maxWidth: 1040, margin: 'auto'}}>
            {
                loading ?
                    <Fade in={true} {...{timeout: 1000}}>
                        <div className={classes.loader}>
                            <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                <ShadowBoxComponent>
                                    <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                    <div className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={35}/>
                                    </div>
                                </ShadowBoxComponent>
                            </div>
                        </div>
                    </Fade>
                    :
                    <div>
                        <Fade in={show} {...{timeout: 1000}}>
                            <div style={{textAlign: 'center', margin: 'auto', alignItems: 'center', justifyContent: 'center', height: '100%', display: "flex", position: 'relative'}}>

                                <div style={{width: '100%', position: 'absolute', top: 0, left: 0, textAlign: 'center', zIndex: 2}}>
                                    <div style={{maxWidth: iFrameWidth, margin: 'auto'}}>
                                        <ShadowBoxComponent className={classes.shadowBoxBack} onClick={goBack} style={isSmallView ? {width: isSmallBack ? 42 : 'calc(100% - 30px)', marginLeft: 15} : {}}>
                                            <ArrowLeftIcon className={classes.shadowBoxBackIcon}/>
                                            {!isSmallBack && <span className={classes.shadowBoxBackText}>Revenir au sommaire de la formation</span>}
                                        </ShadowBoxComponent>
                                    </div>
                                </div>

                                {screenReducer.full && <div style={{height: '100%', width: '100%', backgroundColor: '#000', position: 'absolute', zIndex: 100}}/>}

                                <div style={styleContent}>
                                    {
                                        isScale && <>
                                            {
                                                screenReducer.full ?
                                                    <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(false))}>
                                                        <FullscreenExit className={classes.btnFullScreenIcon}/>
                                                    </IconButton> :
                                                    <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(true))}>
                                                        <Fullscreen className={classes.btnFullScreenIcon}/>
                                                    </IconButton>
                                            }
                                        </>
                                    }

                                    <iframe
                                        id={'Iframe'}
                                        className={classes.shadowBox}
                                        style={styleIframe}
                                        src={"https://zoom.us/rec/share/o74QKtNAX6F7i3516CbR9agMAXMatvyOnecRBJ3I-SWZxRW37YpvDm1VhjcWgtJV.lVRUs8mTGW3VNrnD?startTime=1641751392000"}
                                    />
                                </div>
                            </div>
                        </Fade>
                    </div>
            }
        </div>
    );
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
            {
                margin: 'auto',
                width: '15% !important'
            }
    },
    shadowBox: {
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
    },
    shadowBoxBack: {
        height: 40,
        width: 290,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer',
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    btnFullScreen: {
        right: 60,
        top: 0,
        position: 'absolute',
        padding: 10,
        margin: 0,
        marginRight: 12,
        color: '#FFFFFF',
        zIndex: 3
    },
    btnFullScreenIcon: {
        fontSize: 30
    }
});

export default RegistrationVirtualClassroomReplay;
