import React from 'react';
import { Fade, makeStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import LockIcon from '@material-ui/icons/Lock';
import { theme } from "../../../../App";
import { OrganismIndex, Organisms } from "../../../../Organism";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

function CardFormation(props) {
    const classes = useStyles(theme)();
    const [load, setLoad] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const checkRegistrationOrganism = () => {
        let organism = '';
        let logo = '';
        if (props.organism) organism = OrganismIndex[props.organism];
        if (Organisms[organism]) {
            logo = Organisms[organism].logo;
        }
        return logo;
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
        if ((screenReducer.screen === 'XS' || screenReducer.screen === 'SM')) {
            setLoad(false);
        }
    }, [screenReducer.screen]);

    return (
        <Fade in={props.show} {...{ timeout: 500 }}>
            <div style={{ margin: 10, background: '#fff', width: isSmallView ? '100%' : '90%', position: 'relative', borderRadius: 10 }}>
                <div className={isSmallView ? classes.boxSmall : classes.box}>
                    {(props.image && !isSmallView) && <img src={props.image} className={classes.cardImage} alt={'Image formation'} onLoad={() => { setLoad(false) }} />}
                    {load ?
                        <div style={{ width: '100%', position: 'relative' }}>
                            <Skeleton animation="wave" variant="rect" style={{ position: 'absolute', top: 0, left: -100, width: 100, height: 100 }} />
                            <Skeleton style={{ position: 'absolute', top: 15, left: 10, width: '50%' }} animation="wave" />
                            <Skeleton style={{ position: 'absolute', bottom: 40, left: 10, width: '35%' }} animation="wave" />
                            <Skeleton style={{ position: 'absolute', bottom: 10, left: 10, width: '25%' }} animation="wave" />
                            <Skeleton style={{ position: 'absolute', top: 5, right: 10, width: '25%', height: 30 }} animation="wave" />
                            <Skeleton style={{ position: 'absolute', bottom: 30, right: 10, width: '40%' }} animation="wave" />
                        </div>
                        :
                        <div className={isSmallView ? classes.contentSmall : classes.content}>
                            <Typography className={classes.cardText} style={{ margin: 5, fontSize: isSmallView ? 11 : 13, height: '35%', width: isSmallView ? '70%' : '80%', paddingTop: isSmallView ? 15 : 0 }} variant="subtitle2" component="p">
                                {props.title && props.title}
                            </Typography>
                            <Typography style={{ margin: 5, fontSize: 11, fontStyle: 'italic' }} variant="subtitle2" component="p">
                                {props.textSession && props.textSession}
                            </Typography>
                            {props.hours ? <div style={{ left: isSmallView ? 0 : -100 }} className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}

                            {(props.state === 1) && <div style={{ backgroundColor: '#28a745' }} className={classes.cardState}><CheckCircleOutlineIcon className={classes.cardStateIcon} /> Disponible </div>}
                            {(props.state === 2) && <div style={{ backgroundColor: '#17a2b8' }} className={classes.cardState}><LockIcon className={classes.cardStateIcon} /> Bientôt disponible</div>}
                            {(props.state === 3) && <div style={{ backgroundColor: '#6c757d' }} className={classes.cardState}><EmojiEventsIcon className={classes.cardStateIcon} /> Terminée</div>}
                            {(props.state === 4) && <div style={{ backgroundColor: '#ffc107' }} className={classes.cardState}><HourglassEmptyIcon className={classes.cardStateIcon} /> ANDPC en attente</div>}
                            {(props.state === 5) && <div style={{ backgroundColor: '#6c757d' }} className={classes.cardState}><EmojiEventsIcon className={classes.cardStateIcon} /> Terminée</div>}
                            {(props.state === 6) && <div style={{ backgroundColor: '#ffc107' }} className={classes.cardState}><HourglassEmptyIcon className={classes.cardStateIcon} /> Financement en attente</div>}

                            {(props.state === 1) && <p onClick={() => props.onClick()} className={classes.textLink}>Continuer<ArrowRightAltIcon /></p>}
                            {(props.state === 2) && <p onClick={() => props.onClick()} className={classes.textLink}>Validez l'inscription<ArrowRightAltIcon /></p>}
                            {(props.state === 3 || props.state === 5) && <p onClick={() => props.onClick()} className={classes.textLink}>Revoir la formation<ArrowRightAltIcon /></p>}
                            {(props.state === 4) && <p onClick={() => props.onClick()} className={classes.textLink}>Validez l'inscription<ArrowRightAltIcon /></p>}
                            {(props.state === 6) && <p onClick={() => props.onClick()} className={classes.textLink}>Validez l'inscription<ArrowRightAltIcon /></p>}

                            {props.organism && <img src={'/asset/images/' + checkRegistrationOrganism()} alt="logo" className={classes.logoOrganism} style={{ width: isSmallView ? '30%' : '20%' }} />}
                        </div>
                    }
                </div>
            </div>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles(({
    box: {
        padding: 0,
        height: 100,
        display: 'flex',
    },
    boxSmall: {
        padding: 5,
        height: 140,
        display: 'flex',
    },
    cardImage: {
        height: 100,
        width: 100,
        borderRadius: '10px 0px 0px 10px',
    },
    cardState: {
        position: 'absolute',
        fontWeight: 700,
        color: '#FFFFFF',
        padding: '0px 5px',
        borderRadius: 20,
        margin: 5,
        fontSize: 11,
        lineHeight: '15px',
        bottom: 0,
        left: 0
    },
    content: {
        height: 100,
        position: 'relative',
        width: '100%'
    },
    contentSmall: {
        height: 140,
        position: 'relative',
        width: '100%'
    },
    textLink: {
        position: 'absolute',
        bottom: 18,
        right: 20,
        margin: 0,
        color: theme.colorPrimary,
        fontSize: 14,
        cursor: 'pointer',
        '& > svg': {
            verticalAlign: 'middle'
        }
    },
    logoOrganism: {
        position: 'absolute',
        right: 5,
        top: 10,
        opacity: 0.5
    },
    cardText: {
        color: '#228B9F',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    cardStateIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },

    cardHour: {
        position: 'absolute',
        fontWeight: 700,
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF',
        padding: '2px 4px',
        borderRadius: '10px 0 10px 0',
        margin: 0,
        fontSize: 11,
        top: 0,
    },
    cardHourIcon: {
        fontSize: 13,
        verticalAlign: 'bottom'
    },
}));

export default CardFormation;
