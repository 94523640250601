import React from 'react';
import { Box, Button, Chip, Fade, IconButton, LinearProgress as LinearProgressMUI, makeStyles, Tooltip, Typography } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import LockIcon from "@material-ui/icons/Lock";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { theme } from "../../../../App";
import { useSelector } from "react-redux";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import moment from "moment";
import Popover from '@material-ui/core/Popover';
import { Edit } from '@material-ui/icons';

function CardCommunityFormation(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [sizeView, setSizeView] = React.useState('');
    const [load, setLoad] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    React.useEffect(() => {
        setSizeView(screenReducer.screen);
    }, [screenReducer.screen]);

    return (
        <Fade in={props.show} {...{ timeout: 500 }}>
            <div>
                <ShadowBoxComponent className={classes.shadowBox} style={{ position: " relative", overflow: "initial", height: sizeView === 'XS' ? 180 : 150, border: '1px solid rgba(0,0,0, 15%)', boxShadow: 'initial', filter: props.data.isLock ? "initial" : "grayscale(100%)", opacity: props.data.isLock ? 1 : 0.7 }} >

                    <Grid container spacing={0} style={{ height: '100%' }}>
                        <Grid item xs={12} sm={3} md={3} lg={4} xl={4}>
                            {sizeView !== 'XS' &&
                                <div style={{ position: 'relative', height: '100%' }}>
                                    {(!load && !props.image)&& <img src="/asset/images/preload_image.png" className={classes.cardImagePreload} alt={'Image formation'} />}
                                    <img src={props.image} className={classes.cardImage} alt={'Image formation'} onLoad={() => setLoad(true)} />
                                </div>
                            }
                        </Grid>
                        <Grid item xs={12} sm={7} md={8} lg={8} xl={8} >
                            <div style={{ padding: "0px 6px", textAlign: "left", height: "100%", position: "relative" }}>
                                <p style={{ fontWeight: 700, fontSize: 14, margin: "0", padding: "10px 0 0 0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{props.title}</p>
                                <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                    <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> Groupe créer le {moment(props.data.createdAt).format("lll")}
                                </p>
                                <p style={{ fontWeight: 400, fontSize: 14, margin: "0", padding: "10px 0" }}>{props.data.numGroup > 99 ? '99+' : props.data.numGroup} <span>membres,</span> {props.data.numPost > 99 ? '99+' : props.data.numPost} <span>postes</span></p>
                                {props.data.isLock ?
                                    <ButtonComponent
                                        small={true}
                                        color='#03a9f4'
                                        className={classes[props.styleButton]}
                                        label={props.titleButton} onClick={() => props.onClick()}
                                    />
                                    :
                                    <LockIcon className={classes.LockIcon} />
                                }
                            </div>

                            {props.data.organismImg.length != 0 &&
                                <div className={classes.boxOrganism}>
                                    <Tooltip title={'Organisme'} placement="center">
                                        <IconButton onClick={handleClick}>
                                            <GroupWorkIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        className={classes.popover}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Typography>
                                            {Object.keys(props.data.organismImg).map((id, index) =>
                                                <img key={index} src={props.data.organismImg[id] && "/asset/images/" + props.data.organismImg[id]} className={id != 2 ? classes.iconOrganism : classes.iconOrganism_500x500} style={{ padding: "5px",display: 'block', margin:'auto', position: "relative" }} />
                                            )}
                                        </Typography>
                                    </Popover>
                                </div>
                            }
                        </Grid>
                    </Grid>

                </ShadowBoxComponent>
            </div>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles(({
    chipBox: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxOrganism:{
        position: "absolute", 
        bottom: 4, 
        right: 10,
        '& .MuiSvgIcon-root':{
            width: 18, 
            height: 18
        },
        '& .MuiButtonBase-root':{
            padding: 4, 
            color: 'rgb(123, 123, 123)',
             margin: '0 5px',
        },
    },
    popover:{
        '& .MuiPaper-elevation8':{
            boxShadow: '0px 1px 5px rgba(0,0,0,0.2)',
        },
        '& .MuiTypography-root':{
            padding: 7,
        }
    },
    chip: {
        margin: '3px 3px 3px 1px',
        fontSize: 7,
        fontWeight: 700,
        color: '#FFFFFF',
        backgroundColor: '#03a9f4',
        '&.MuiChip-sizeSmall': {
            height: 15
        }
    },
    shadowBox: {
        padding: 0,
        textAlign: 'center',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
    },
    cardImagePreload: {
        height: 130,
        width: '100%',
        maxWidth: 130,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        borderRadius: '4px',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    cardImage: {
        height: 130,
        width: '100%',
        maxWidth: 130,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        borderRadius: '4px',
        top: '50%',
        left: '50%',
        zIndex: 0
    },
    cardText: {
        color: '#228B9F',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    cardHour: {
        position: 'absolute',
        fontWeight: 700,
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF',
        padding: '5px 5px',
        borderRadius: '0 0 5px 0',
        margin: 0,
        fontSize: 12,
        top: 0,
        left: 0,
        zIndex: 1
    },
    cardHourIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    cardState: {
        position: 'absolute',
        fontWeight: 700,
        color: '#FFFFFF',
        padding: '3px 5px',
        borderRadius: 20,
        margin: 5,
        fontSize: 12,
        bottom: 0,
        right: 0
    },
    cardStateIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    buttonV1: {
        bottom: 10,
        left: 5,
        position: "absolute",
        textTransform: "initial",
        boxShadow: "none",
        fontSize: 11,
        padding: "3px 11px",
        backgroundColor: "rgb(79, 147, 165) !important",
    },
    buttonV2: {
        bottom: "10px",
        left: "5px",
        position: "absolute",
        textTransform: "initial",
        fontSize: 11,
        padding: "3px 11px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)"
    },
    LockIcon: {
        left: 5,
        color: "rgb(79, 147, 165)",
        border: "solid 1px rgb(79, 147, 165)",
        bottom: 10,
        padding: "3px 11px",
        position: "absolute",
        boxShadow: "none",
        textTransform: "initial",
        backgroundColor: "transparent !important",
        height: 19,
        width: 43,
        borderRadius: 5,
    },
    iconOrganism_500x500: {
        width: 55,
        height: "auto",
    },
    iconOrganism: {
        width: 119,
        height: "auto",
    }
}));

export default CardCommunityFormation;
