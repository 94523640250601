import React from 'react';
import { Box, Chip, Avatar, Fade, LinearProgress as LinearProgressMUI, makeStyles } from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Grid from "@material-ui/core/Grid";
import { theme } from "../../../App";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditorComponent from "../../../Component/EditorComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { useDispatch } from "react-redux";
import { Edit } from "@material-ui/icons";
import Slide from '@material-ui/core/Slide';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import CommentIcon from '@material-ui/icons/Comment';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Badge from '@material-ui/core/Badge';


function CommunityComment(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const data = props.data;
    const containerRef = React.useRef(null);
    const [loading, setLoading] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState('');
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Écrire un commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const save = (id) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                Api.post({
                    route: 'learner_community_comment_edit',
                    data: handler.getData(),
                    params: {
                        id: id,
                        communityGroup: props.params.communityGroup,
                        communityPost: props.params.communityPost
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        props.loadData();
                        handler.reset();
                        onOpen();
                        dispatch(
                            SnackbarOpen({
                                text: 'Votre commentaire a bien été modifier.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'learner_community_comment_add',
                    data: { comment: handler.getData().comment, communityComment: id },
                    params: {
                        communityGroup: props.params.communityGroup,
                        communityPost: props.params.communityPost
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        props.loadData()
                        handler.reset();
                        onOpen();
                        dispatch(
                            SnackbarOpen({
                                text: 'Vous avez répondu au commentaire.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }
    const editAction = (id) => {
        if (id && props.params.communityPost) {
            setIsEdition(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'learner_community_comment_get',
                params: {
                    id: id,
                    communityGroup: props.params.communityGroup,
                    communityPost: props.params.communityPost
                }
            },
                (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data[0]);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            setIsEdition(false);
        }
    }


    const onOpen = () => {
        isOpen == false ? setIsOpen(true) : setIsOpen(false);
    }

    const buttom = (data) => {
        return (
            <>
                {data.isCommentProspect ?
                    <Tooltip title={'Modifier'} placement="center" style={{ padding: 4, color: 'rgb(123, 123, 123)', margin: '0 5px' }}>
                        <IconButton onClick={() => {
                            editAction(data.id);
                            setIsEdition(true);
                            setLoading(true);
                            handler.reset();
                            onOpen()
                        }}>
                            <Edit style={{ width: 18, height: 18 }} />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={'Répondre'} placement="center" style={{ padding: 4, color: 'rgb(123, 123, 123)', margin: '0 5px' }}>
                        <IconButton onClick={() => {
                            handler.reset();
                            setLoading(false);
                            onOpen()
                        }}>
                            <CommentIcon style={{ width: 18, height: 18 }} />
                        </IconButton>
                    </Tooltip>

                }
            </>
        )
    }

    const boxForm = (data, isOpen) => {
        return (
            <Box sx={{ transitionProperty: 'all', transitionDuration: '.3s', width: "100%",display: 'flex', overflow:"hidden" }} >
                <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.contnaireForm} ref={containerRef}>
                            <Grid container spacing={2} className={classes.boxComponent} style={{ textAlign: 'center' }} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p style={{ color: "#5E6E82", fontSize: 20, margin: "auto 45px", fontWeight: "bold", borderLeft: "10px solid", padding: " 10px 30px", textAlign: "left", }}>
                                        {isEdition ? 'Modifier votre commentaire' : 'Répondre au commentaire'}</p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <EditorComponent name={'comment'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonComponent className={classes.buttonComment} label={'Répondre'} onClick={() => { save(data.id) }} disabled={loading} />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Slide>
            </Box>
         
        )
    }
    const viewResponse = (data) => {
        return (
            <div className={classes.boxCommentResponse}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                    {data.status === 'Admin' ?
                        <Badge color="secondary" badgeContent={'A'} showZero >
                            <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar>
                        </Badge> :
                        <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar>
                    }
                    <div style={{ padding: '0 10px', width: "100%" }}>
                        <p className={classes.pseudo}><span>{data.firstname.charAt().toUpperCase()}.{data.lastname}</span></p>
                        <p className={classes.stats}><KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> commentaire ajouter le {data.createdAt}</p>
                        <p className={classes.subTitle} dangerouslySetInnerHTML={{ __html: data.comment }} />
                        <p className={classes.isUpdatedAt}>{data.isUpdatedAt && 'Commentaire modifier'}</p>
                    </div>
                </Grid>
            </div>
        )
    }
    React.useEffect(handler.start, []);
    return (
        <div className={classes.boxComment}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                {data.status === 'Admin' ?
                    <Badge color="secondary" badgeContent={'A'} showZero >
                        <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar>
                    </Badge> :
                    <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar>
                }
                <div style={{ padding: '0 10px', width: "100%" }}>
                    <p className={classes.pseudo}><span>{data.firstname.charAt().toUpperCase()}.{data.lastname}</span><sup>{props.isComment && buttom(data)}</sup></p>
                    <p className={classes.stats}><KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> commentaire ajouter le {data.createdAt}</p>
                    {data.responsProspect && viewResponse(data.responsProspect)}
                    <p className={classes.subTitle} dangerouslySetInnerHTML={{ __html: data.comment }} />
                    <p className={classes.isUpdatedAt}>{data.isUpdatedAt && 'Commentaire modifier'}</p>
                </div>
            </Grid>
            {boxForm(data, isOpen)}
        </div>
    );
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        margin: 0,
        padding: "10px 0",
        '& p,h1,h2,h3': { margin: 0 }
    },
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
    },
    boxCommentResponse: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
        margin: 16,
    },
    isUpdatedAt: {
        textAlign: 'right',
        margin: 0,
        fontSize: 10
    },
    contnaireForm: {
        margin: 0,
        fontSize: 12,
        color: "#7B7B7B",
        padding: 10,
        '& .rdw-editor-main': {
            minHeight: 158
        }
    },
    buttonComment: {
        borderRadius: "5px",
        padding: "0px 59px",
        fontSize: 15,
        textTransform: "initial",
        fontWeight: "bolder",
        background: "#c8dfe5 !important",
        boxShadow: "0 0",
        border: "4px solid #c8dfe5",
        color: "#5E6E82"
    },
    boxComment: {
        color: "#5E6E82",
        borderBottom: "7px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: '22px 0px',
        width: "93%",
        margin: "auto",
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    pseudo: {
        margin: 0,
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#495666"
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    stats: {
        margin: 0,
        fontSize: 11,
        fontWeight: "bold"
    }
}));

export default CommunityComment;
