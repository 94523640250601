import React from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import ShadowBoxComponent from './ShadowBoxComponent';
import { dispatch, theme } from '../App';
import Api, { LibraryResourceImage } from '../Api';
import { formHandlerInit } from '../Tool/FormHandlerCommon';
import SelectComponent from './SelectComponent';
import ButtonComponent from './ButtonComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../Action/LinearProgressMainLayoutAction';
import { cloneJson } from '../Tool/CloneCommon';
import { SnackbarOpen } from '../Action/SnackbarAction';
import Skeleton from '@material-ui/lab/Skeleton';
import MediaLinkComponent from './MediaLinkComponent';

function FeaturedResourceComponent(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [info, setInfo] = React.useState([]);
    const [load, setLoad] = React.useState(false);
    const [isLock, setIsLock] = React.useState(true);
    const [form, setForm] = React.useState({
        order1: {
            name: 'order1',
            label: 'Block 1',
            textHelper: 'Choisissez la ressource pour le block 1',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        order2: {
            name: 'order2',
            label: 'Block 2',
            textHelper: 'Choisissez la ressource pour le block 2',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        order3: {
            name: 'order3',
            label: 'Block 3',
            textHelper: 'Choisissez la ressource pour le block 3',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);
    const [optionLibraryResource, setOptionLibraryResource] = React.useState([]);

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        if (props.organism && props.formationGroup) {
            Api.get({
                route: 'marketing_manager_featured_resource_info',
                data: {
                    organismId: props.organism,
                    formationGroupId: props.formationGroup
                }
            },
                (response) => {
                    if (response.status === 200) {
                        setInfo(response.data);
                        handler.setValue('order1', response.data['1'] ? response.data['1'].id : '');
                        handler.setValue('order2', response.data['2'] ? response.data['2'].id : '');
                        handler.setValue('order3', response.data['3'] ? response.data['3'].id : '');
                        dispatch(LinearProgressMainLayoutDeactivate());

                        if (props.preview || (response.data['1'] || response.data['2'] || response.data['3'])) {
                            setIsLock(false);
                        }
                        setTimeout(() => {
                            setLoad(true);
                        }, 1000);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, [props.organism]);
    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        if (props.preview) {
            Api.get({
                route: 'select_library_resource',
            },
                (response) => {
                    if (response && response.data) {
                        setOptionLibraryResource(response.data);
                    }
                });
        }
    }, []);

    const save = () => {
        dispatch(LinearProgressMainLayoutActivate());
        let blocks = [];
        if (info[1]) { blocks.push({ resourceId: info[1].id, order: 1 }) }
        if (info[2]) { blocks.push({ resourceId: info[2].id, order: 2 }) }
        if (info[3]) { blocks.push({ resourceId: info[3].id, order: 3 }) }

        Api.post({
            route: 'marketing_manager_featured_resource_edit',
            data: {
                organismId: props.organism,
                formationGroupId: props.formationGroup,
                blocks: blocks
            },
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Enregistrement des ressources à la une a été un succès.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
            } else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    const handlerChange = (resourceId, inputName) => {

        dispatch(LinearProgressMainLayoutActivate());
        let order = inputName.split('order')[1];
        let infoTemp = cloneJson(info);

        if (!resourceId) {
            delete infoTemp[order];
            setInfo(infoTemp);
            dispatch(LinearProgressMainLayoutDeactivate());
        }
        else {
            Api.get({
                route: 'marketing_manager_featured_resource_resource_get',
                params: { id: parseInt(resourceId) }
            },
                (response) => {
                    if (response.status === 200) {
                        handler.setValue(inputName, response.data.id);
                        infoTemp[order] = response.data;
                        setInfo(infoTemp);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                });
        }
    };

    if (isLock) {
        return (<></>)
    }
    else {
        return (
            <>
                {props.preview &&
                    <>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <SelectComponent name={'order1'} handler={handler} options={optionLibraryResource} onChange={handlerChange} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <SelectComponent name={'order2'} handler={handler} options={optionLibraryResource} onChange={handlerChange} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <SelectComponent name={'order3'} handler={handler} options={optionLibraryResource} onChange={handlerChange} />
                            </Grid>
                        </Grid>
                    </>
                }

                {props.preview ?
                    <p style={{ textAlign: 'center', width: '80%', margin: '10px auto' }}>
                        {(info[1] || info[2] || info[3]) ? 'Apreçu de la vue :' : 'Ce groupe de formation ne possède pas de ressources ...'}
                    </p>
                    :
                    <p style={{ textAlign: 'left', width: '80%', margin: '10px auto' }}>{(info[1] || info[2] || info[3]) ? 'Suggestion du mois' : ''}</p>
                }

                {load
                    ?
                    <Grid container spacing={2} justifyContent="flex-end" style={{ width: '80%', margin: 'auto' }}>
                        {Object.values(info).map((data, index) => (
                            <Grid key={index} item xs={12} sm={12} md={(Object.keys(info).length < 3) ? 12 : 6} lg={(Object.keys(info).length < 3) ? 12 : 6} xl={(Object.keys(info).length < 3) ? 12 : 6}>
                                <MediaLinkComponent
                                    path={data.link}
                                    type={data.format}
                                    isLibrary={true}
                                >
                                    <ShadowBoxComponent
                                        className={classes.box}
                                        style={{
                                            height: (data.order === 1 && ((screenReducer.screen === 'LG' || screenReducer.screen === 'XL') && Object.keys(info).length === 3)) ? '214%' : '',
                                            backgroundImage: data.image ? 'linear-gradient(0deg, rgba(1,4,49,.5) 0%, rgba(1,4,49,.5) 100%), url(' + LibraryResourceImage + data.image : '',
                                            backgroundColor: '#00000096',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        {data.type && <div className={classes.type}>{data.type}</div>}
                                        {data.name && <div className={classes.name}>{data.name}</div>}
                                        {data.description && <div className={classes.description}>{data.description}</div>}
                                    </ShadowBoxComponent>
                                </MediaLinkComponent>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <div style={{ width: '80%', height: '100%', margin: 'auto', }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Skeleton animation="wave" variant="rect" style={{ height: screenReducer.screen === 'XS' ? 100 : 220, marginBottom: screenReducer.screen === 'XS' ? -5 : 0 }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Skeleton animation="wave" variant="rect" style={{ height: screenReducer.screen === 'XS' ? 100 : 105, marginBottom: 10 }} />
                                <Skeleton animation="wave" variant="rect" style={{ height: screenReducer.screen === 'XS' ? 100 : 105 }} />
                            </Grid>
                        </Grid>
                    </div>
                }

                {props.preview &&
                    <>
                        <br />
                        <ButtonComponent
                            style={{ float: 'right', margin: 5 }}
                            label={'Enregistrer'}
                            onClick={save}
                        />
                    </>
                }
            </>
        );
    }

}

const useStyles = (theme) => makeStyles(({
    box: {
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 25%)'
        }
    },
    type: {
        color: '#fff',
        textAlign: 'left',
        width: 'fit-content',
        background: theme.colorPrimary,
        padding: 3,
        fontSize: 10,
    },
    name: {
        color: '#fff',
        textAlign: 'left',
        fontWeight: 600,
        fontSize: 14,
        paddingBottom: 5
    },
    description: {
        color: '#fff',
        textAlign: 'left',
        fontSize: 12,
        padding: '10px 0px 40px 0px'
    }
}));

export default FeaturedResourceComponent;
