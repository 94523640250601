import React from 'react';
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";

function CheckboxComponent(props = {
    name: '',
    direction: 'left',
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    }
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(data.value ? data.value : false);
    const disabled = data.loading || data.disabled || props.disabled;
    const onChange = (event) => {
        const changedValue = event.target.checked;
        setValue(changedValue);

        if (props.onChange) {
            props.onChange(changedValue);
        }

        let newForm = {...props.handler.form};
        newForm[props.name].value = changedValue;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        props.handler.set(newForm);
    }

    React.useEffect(() => {
        if (props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <FormControl variant="outlined" className={`${classes.checkboxLine} ${classes.checkbox} ${value && !disabled ? classes.checkboxValid : ''}`}>
            <div>
                {(!props.direction || props.direction === 'left') && <FormLabel component="legend">{data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}</FormLabel>}
                <Checkbox checked={value} disabled={disabled} onChange={onChange}/>
                {props.direction === 'right' && <FormLabel style={{cursor: 'pointer'}} onClick={() => onChange({target: {checked: !value}}) } component="legend">{data.label}{((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}</FormLabel>}
            </div>
        </FormControl>
    );
}

const useStyles = makeStyles({
    checkbox: {
        width: '100%',
        marginTop: 0,
        '& legend': {
            padding: '10px 0',
            textAlign: 'initial'
        },
        '& .MuiFormHelperText-root': {
            margin: '0 14px',
            fontSize: 10,
            height: 10,
            lineHeight: '10px'
        }
    },
    checkboxValid: {
        '& legend': {
            color: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        }
    },
    checkboxLine: {
        '& > div': {
            flexDirection: 'row',
            display: 'flex',
            '& legend': {
                padding: '13px 12px 12px 2px',
            }
        }
    }
});

export default CheckboxComponent;
