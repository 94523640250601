import React from 'react';
import Grid from "@material-ui/core/Grid";
import { theme } from "../../../../App";
import TitleComponent from '../../../../Component/TitleComponent';
import { Avatar, Box, makeStyles } from '@material-ui/core';

function PrivatePostProspectList(props) {
    const classes = useStyles(theme)();
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        setData(props.data)
    }, [props.data]);
    return (
        <Box className={classes.boxComponent}>
            <TitleComponent title={'Membres'} />
            <Box style={{ maxHeight: '39vw', overflow: 'scroll', }}>
                {data.length >= 0 ?
                    <p className={classes.textError}><b>Aucun Membres</b></p>
                    :
                    Object.keys(data).map((dataList, index) =>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', margin: 'auto', padding: 10 }}>
                            <Avatar className={classes.avatar}>{data[dataList].firstname.charAt().toUpperCase()}{data[dataList].lastname.charAt().toUpperCase()}</Avatar>
                            <p className={classes.boxName}>{data[dataList].firstname} {data[dataList].lastname}</p>
                        </Grid>
                    )
                }
            </Box>
        </Box >
    );
}

const useStyles = (theme) => makeStyles(({
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
    },
    textError: {
        textAlign: 'center',
        color: "#666",
        textTransform: "uppercase",
        fontSize: 20,
        opacity: 0.1,
        width: "100%"
    },
    boxName: {
        margin: '0px 10px',
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#495666",
        alignItems: 'center',
        display: 'flex'
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
}));

export default PrivatePostProspectList;
