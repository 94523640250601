import React from 'react';
import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CountUp from 'react-countup';
import Zoom from "@material-ui/core/Zoom";

function RadioComponent(props = {
    name: '',
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {},
    },
    options: [],
    line: false,
    theme: 'default',
    scoreFeedback: '',
    CountUp: false
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(data.value ? data.value : '');
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const [indication, setIndication] = React.useState(false);
    const [isReadyContent, setReadyContent] = React.useState(true);
    const disabled = data.loading || data.disabled || props.disabled;


    const onChange = (event) => {
        setValue(event.target.value);
        let newForm = { ...props.handler.form };
        newForm[props.name].value = event.target.value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onBlur = () => {
        setFocus(false);
    }
    const onFocus = () => {
        setFocus(true);
    }
    const onIndication = () => {
        indication == false ? setIndication(true) : setIndication(false);
    }
    const RefIndication = React.useRef();
    React.useEffect(() => {
        setError(props.handler.form[props.name].error)
    }, [props.handler.form[props.name]].error);

    React.useEffect(() => {
        if (props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);


    return (
        <>
            {
                props.theme === 'table' ?
                    <TableRow
                        key={1111}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className={`
                                    ${classes.checkbox}
                                    ${isFocus ? classes.checkboxFocus : error ? classes.checkboxError : value.length > 0 && !disabled ? classes.checkboxValid : ''}
                                `}
                    >
                        <TableCell component="th" scope="row" >
                            {data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}
                            <Tooltip style={{ textAlign: 'right' }} title={error && !isFocus ? error : data.textHelper}>
                                <IconButton style={{ float: 'right' }}>
                                    {error ? <ErrorIcon color="error" /> : <InfoIcon color="primary" />}
                                </IconButton>
                            </Tooltip>
                        </TableCell >
                        {
                            props.options.map(data => (
                                <TableCell align="center">
                                    <FormControlLabel
                                        key={data.value}
                                        control={<Radio checked={value.includes(data.value)}
                                            value={data.value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            disabled={disabled}
                                        />}
                                        label={data.label}
                                    />
                                </TableCell>
                            ))
                        }
                    </TableRow>
                    :
                    <FormControl variant="outlined"
                        className={`
                                    ${classes.checkbox}
                                    ${props.line ? classes.checkboxLine : ''}
                                    ${isFocus ? classes.checkboxFocus : error ? classes.checkboxError : value.length > 0 && !disabled ? classes.checkboxValid : ''}
                                `}
                    >
                        <div>
                            <FormLabel component="legend"><span>{data.orderBy + ') ' + data.label}</span> {(data.options && data.options.validation && data.options.validation.indexOf('required') > -1 && !data.indication) ? ' *' :
                                (data.indication) ? <sup><button style={{ border: '1px solid rgba(0, 0, 0, 0.54)', borderRadius: '10px', padding: '0 6px', color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }} onClick={onIndication}>i</button></sup> : ''}</FormLabel>
                            <FormGroup row={props.line}>
                                {
                                    props.options.map(data => (
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <FormControlLabel
                                                key={data.value}
                                                control={<Radio
                                                    checked={value.includes(data.value)}
                                                    value={data.value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    onFocus={onFocus}
                                                    disabled={disabled}
                                                />}
                                                label={data.label}
                                            />
                                            {props.scoreFeedback != undefined ? <p style={{ margin: '0px 10px' }} >{props.CountUp ? <><CountUp separator=" " end={props.scoreFeedback['score'][data.value]} />% des utilisateurs ont choisi cette réponse</> : ''}</p> : null}
                                        </div>
                                    ))
                                }

                            </FormGroup>

                            <Zoom in={indication} style={{ transitionProperty: 'all', transitionDuration: '.3s', overflow: 'hidden', height: indication ? RefIndication.current.offsetHeight + 'px' : '0px', opacity: indication ? '1' : '0' }}>
                                <div style={{ background: '#ffffff', borderRadius: '10px', border: 'solid #5E6E82 1px' }}>
                                    <p ref={RefIndication} style={{ margin: '0', padding: '20px' }}> <><b>Indicateur : </b> {data.indication}</></p>
                                </div>
                            </Zoom>
                        </div>
                        <FormHelperText>{error && !isFocus ? error : data.textHelper}</FormHelperText>
                    </FormControl>
            }
        </>
    );

}

const useStyles = makeStyles({
    checkbox: {
        width: '100%',
        marginTop: 0,
        '& legend': {
            padding: '10px 0',
            textAlign: 'initial'
        },
        '& .MuiFormHelperText-root': {
            margin: '0 14px',
            fontSize: 10,
            height: 10,
            lineHeight: '10px'
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '& .MuiFormGroup-root': {
            display: 'initial'
        },
        '& .MuiFormLabel-root': {
            lineHeight: '25px'
        }
    },
    checkboxLine: {
        '& > div': {
            flexDirection: 'row',
            display: 'flex',
            '& legend': {
                padding: '12px 12px 12px 2px',
            }
        }
    },
    checkboxFocus: {
        '& legend': {
            color: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& .MuiTableCell-body': {
            color: '#3F51BF !important'
        }
    },
    checkboxValid: {
        '& legend': {
            color: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        },
        '& .MuiTableCell-body': {
            color: '#008000 !important'
        }
    },
    checkboxError: {
        '& legend': {
            color: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        },
        '& .MuiTableCell-body': {
            color: '#982525 !important'
        }
    }
});

export default RadioComponent;
