import React from 'react';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { Refresh } from "@material-ui/icons";
import PaginationComponent from '../../../Component/PaginationComponent';
import { Avatar, Box, Drawer as DrawerMUI, makeStyles } from "@material-ui/core";
import { getRoutePathname } from "../../../Config/Route";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import CommentIcon from '@material-ui/icons/Comment';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import EditorComponent from "../../../Component/EditorComponent";
import Grid from "@material-ui/core/Grid";
import { NavigateBefore } from "@material-ui/icons";
import Badge from '@material-ui/core/Badge';
import CommunityComment from "./CommunityComment";
import ButtonComponent from "../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import IconButton from "@material-ui/core/IconButton";
import { theme } from "../../../App";
import moment from "moment";

function CommunityPost(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const [commentList, setCommentList] = React.useState([]);
    const [communityPost, setCommunityPost] = React.useState([]);
    const [isComment, setIsComment] = React.useState(false);

    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Écrire un commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoad(true)
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'learner_community_comment_add',
                data: handler.getData(),
                params: {
                    communityGroup: props.match.params.communityGroup,
                    communityPost: props.match.params.communityPost,
                }
            }, (response) => {
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 201) {
                    loadData()
                    handler.reset();
                    dispatch(
                        SnackbarOpen({
                            text: 'Commentaire enregistrer.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const loadData = () => {
        setLoad(true)
        dispatch(LinearProgressMainLayoutActivate());
        if (props.match.params.communityPost) {
            Api.get({
                route: 'learner_community_comment_list',
                params: {
                    communityGroup: props.match.params.communityGroup,
                    communityPost: props.match.params.communityPost
                }
            },
                (response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        for (let index in data) {
                            data[index].createdAt = moment(data[index].createdAt).format("lll");
                            if (data[index].responsProspect)
                                data[index].responsProspect.createdAt = moment(data[index].responsProspect.createdAt).format("lll");
                        }
                        setCommentList(data);
                        setLoad(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_community'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        setCommentList([]);
                    }
                });
        } else {
            setCommentList([]);
        }
        if (props.match.params.communityGroup && props.match.params.communityPost) {
            Api.get({
                route: 'learner_community_post_get',
                params: {
                    communityGroup: props.match.params.communityGroup,
                    communityPost: props.match.params.communityPost
                }
            },
                (response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        data.createdAt = moment(data.createdAt).format("lll");
                        setCommunityPost(data);
                        setIsComment(data.isComment)
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_community'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                });
        } else {
            setCommunityPost([]);
        }
    }


    const viewFormComment = (id) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <Box className={classes.boxComponent} style={{ textAlign: 'center' }}>
                    <Grid container spacing={2} style={{width:'90%', margin:'auto'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className={classes.titleForm}>Répondre au poste</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <EditorComponent name={'comment'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ButtonComponent className={classes.buttonComment} label={'Commenter'} onClick={() => { save(id) }} disabled={load} />
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        )
    }
    const viewList = (dataList) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <>
                    <CommunityComment
                        data={dataList}
                        loadData={loadData}
                        isComment={isComment}
                        params={{
                            communityGroup: props.match.params.communityGroup,
                            communityPost: props.match.params.communityPost
                        }}
                    />
                </>
            </Fade>
        )
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        loadData();
        setBreadcrumbs({
            currentPage: 'Post',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_community'), label: 'Comunauté' },
                { path: getRoutePathname('learner_community_group', { communityGroup: props.match.params.communityGroup }), label: 'Groupe' },
            ]
        });
    }, []);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <Box style={{ textAlign: 'center', maxWidth: 1000, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader}>
                                <Box className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <Box className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </Box>
                                    </ShadowBoxComponent>
                                </Box>
                            </Box>
                        </Fade>
                        :
                        <Box>
                            <br />
                            <Box>
                                <ShadowBoxComponent className={classes.shadowBoxBack} onClick={() => { props.history.push(getRoutePathname('learner_community_group', { communityGroup: props.match.params.communityGroup })); }} style={isSmallView ? { width: 'calc(100% - 30px)', marginLeft: 15 } : {}}>
                                    <NavigateBefore className={classes.shadowBoxBackIcon} />
                                    <span className={classes.titleGroup} >Retour</span>
                                </ShadowBoxComponent>
                                <br />
                                <Box style={{ color: "#5E6E82", borderRadius: 5, width: "fit-content", backgroundColor: "#FFFFFF", border: "1px solid rgb(223, 223, 223)", textAlign: "left", padding: "0px 22px" }}>
                                    {load ? <><CircularProgress size={20} className={classes.circularProgress} /> <span className={classes.span}>Mise à jour des données.</span></>
                                        :
                                        <Box style={{ fontSize: 10 }}>
                                            <IconButton style={{ padding: 5, margin: 3 }} onClick={() => { loadData(); }}>
                                                <Refresh />
                                            </IconButton>
                                            Recharger les commentaires
                                        </Box>
                                    }
                                </Box>
                                <br />
                                <Fade in={true} {...{ timeout: 500 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box className={classes.boxComponent}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={12} lg={10} xl={10} style={{ display: 'flex' }}>
                                                    {communityPost.status === 'Admin' ?
                                                        <Badge color="secondary" badgeContent={'A'} showZero >
                                                            <Avatar className={classes.avatar}>{communityPost.firstname.charAt().toUpperCase()}{communityPost.lastname.charAt().toUpperCase()}</Avatar>
                                                        </Badge> :
                                                        <Avatar className={classes.avatar}>{communityPost.firstname.charAt().toUpperCase()}{communityPost.lastname.charAt().toUpperCase()}</Avatar>
                                                    }
                                                    <Box style={{ padding: '0 10px' }}>
                                                        <p style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", color: "#495666" }}>{communityPost.title}</p>
                                                        <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                                            <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> post créer par <span className={classes.nameProspect}>{communityPost.firstname.charAt().toUpperCase()}.{communityPost.lastname}</span> le {communityPost.createdAt}
                                                        </p>
                                                        <p className={classes.subTitle} style={{ margin: 0, padding: "10px 0" }} dangerouslySetInnerHTML={{ __html: communityPost.subTitle }} />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                    <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "5px 0 0 0" }} >
                                                        <p style={{ margin: 0, textAlign: "right" }}><CommentIcon style={{ transform: "translate(-18%,41%)" }} />{communityPost.numComment > 99 ? '99+' : communityPost.numComment} Commentaires</p>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <br />
                                    </Grid>
                                </Fade>
                            </Box>
                            <br />
                            <PaginationComponent
                                dataList={commentList}
                                pageNumber={10}
                                viewList={viewList}
                                error={'Aucun commentaire'}
                            />
                            <br />
                            {isComment && viewFormComment()}
                            <br />
                        </Box>
                }
            </Box>
        </>
    );
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    title: {
        margin: 0,
        fontSize: 21,
        fontWeight: "bold",
        color: "#495666"
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBox: {
        padding: 0,
        textAlign: 'center',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    buttonV1: {
        textTransform: "initial",
        fontSize: 15,
        padding: "5px 33px",
        boxShadow: "initial",
        borderRadius: "0px 5px 5px 0px",
        border: "1px solid rgb(3, 169, 244)"
    },
    buttonV2: {
        textTransform: "initial",
        fontSize: 15,
        padding: "5px 33px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(3, 169, 244)",
        color: "rgb(3, 169, 244)"
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(3, 169, 244)",
        color: "rgb(3, 169, 244)",
        margin: "0px 39px"
    },
    sweetAlert: {
        '& .sa-icon': {
            margin: '0px auto !important',
        },
        '& .sweet-alert h2': {
            lineHeight: '23px !important',
            margin: "0 !important"
        }
    },
    shadowBoxBack: {
        height: 40,
        width: "max-content",
        fontSize: 14,
        textAlign: 'start',
        marginTop: 0,
        padding: 8,
        cursor: 'pointer',
        boxShadow: "0 0 0 0",
        background: "transparent"
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle',
        fontSize: "1.8rem",
        transform: "translate(0, -10%)"
    },
    titleGroup: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: "left",
    },
    buttonComment: {
        borderRadius: "5px",
        padding: "0px 59px",
        fontSize: 15,
        textTransform: "initial",
        fontWeight: "bolder",
        background: "#c8dfe5 !important",
        boxShadow: "0 0",
        border: "4px solid #c8dfe5",
        color: "#5E6E82"
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
    circularProgress: {
        margin: 10,
        color: theme.colorPrimary
    },
    span: {
        margin: '0 10px',
        fontSize: 10,
        height: 10,
        display: 'inline-block',
        verticalAlign: 'bottom',
        padding: '18px 0',
        color: '#5E6E82'
    },
    nameProspect: {
        color: theme.colorPrimary
    },
    BoxComment: {
        color: "#5E6E82",
        borderBottom: "7px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: '22px 0px',
        width: "93%",
        margin: "auto"
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    titleForm: {
        color: "#5E6E82",
        fontSize: 20,
        margin: "auto",
        fontWeight: "bold",
        borderLeft: "10px solid",
        padding: " 10px 30px",
        textAlign: "left",
    },
}));

export default CommunityPost;