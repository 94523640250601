import {hasRight} from "../Tool/UserTool";
import {getRouteByPathname} from "../Config/Route";
import Api from "../Api";

let ticTimeout;
const sendTic = () => {
    let params = window.location.href.split('/');
    let parser = document.createElement('a');
    parser.href = window.location.href;
    let route = (getRouteByPathname(parser.pathname));

    if ('learner_registration' === route.id) {
        Api.post({route: 'learner_timer_tic', data: {registration: params[params.length - 1]}}, () => {});
    } else if ('learner_registration_course' === route.id) {
        Api.post({route: 'learner_timer_tic', data: {registration: params[params.length - 3]}}, () => {});
    } else {
        Api.post({route: 'learner_timer_tic'}, () => {});
    }
}

export default function tic(authenticationReducer) {
    if (ticTimeout) clearInterval(ticTimeout);
    if (hasRight(authenticationReducer, 'ROLE_LEARNER')) {
        sendTic();
        ticTimeout = setInterval(sendTic, 1000 * 30);
    }
}

