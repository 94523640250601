import Api from "./Api";

export const Organisms = {
    'santeformapro': {
        id: 1,
        logo: 'logo_santeformapro.png',
        colorPrimary: '#E7157B',
        colorPrimaryOpacity: '#E7157B4a',
        favicon: 'favicon_santeformapro.png',
        phone: '04 42 17 90 82',
        mail: 'contact@santeformapro.com',
        twitter: 'https://twitter.com/sante_formapro',
        facebook: 'https://www.facebook.com/santeformaproofficiel',
        linkedin: 'https://www.linkedin.com/in/santé-formapro-80b435153',
        website: 'https://www.santeformapro.com/',
        faq: 'https://www.santeformapro.com/faq/',
        termsConditions: Api.host() + '/asset/pdfs/tc_santeformapro.pdf',
        dataProtection: Api.host() + '/asset/pdfs/dp_santeformapro.pdf'
    },
    'larevuedupraticien-dpc': {
        id: 2,
        logo: 'logo_revuedupraticiendpc.png',
        colorPrimary: '#69ABAA',
        colorPrimaryOpacity: '#69ABAA4a',
        favicon: 'favicon_revuedupraticiendpc.png',
        phone: '01 55 62 68 48',
        mail: 'dpc@larevuedupraticien.fr',
        twitter: 'https://twitter.com/RevuePraticien',
        facebook: 'https://www.facebook.com/larevuedupraticien',
        linkedin: 'https://www.linkedin.com/showcase/la-revue-du-praticien/',
        website: 'https://www.larevuedupraticien-dpc.fr/',
        faq: 'https://www.larevuedupraticien-dpc.fr/aide',
        termsConditions: Api.host() + '/asset/pdfs/cg_larevuedupraticiendpc.pdf',
        dataProtection: Api.host() + '/asset/pdfs/cg_larevuedupraticiendpc.pdf'
    },
    'timelia': {
        id: 4,
        logo: 'logo_timelia.png',
        colorPrimary: '#E73E01',
        colorPrimaryOpacity: '#E73E014a',
        favicon: 'favicon_timelia.png',
        phone: '04 66 62 00 29',
        mail: 'contact@timelia.fr',
        twitter: '#',
        facebook: 'https://www.facebook.com/timeliaformation/',
        linkedin: '#',
        website: 'https://timelia.fr/',
        faq: 'https://timelia.fr/faq',
        termsConditions: Api.host() + '/asset/pdfs/cg_timelia.pdf',
        dataProtection: Api.host() + '/asset/pdfs/cg_timelia.pdf'
    }
};
export const OrganismIndex = {
    1: 'santeformapro',
    2: 'larevuedupraticien-dpc',
    4: 'timelia'
};
