import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { getRoutePathname } from "../../../Config/Route";
import { useDispatch } from "react-redux";
import SweetAlert from "sweetalert-react";
import { TableReload } from "../../../Action/TableAction";
import { Checkbox } from '@material-ui/core';
import { inArray } from '../../../Tool/ArrayCommon';
import { SnackbarOpen } from '../../../Action/SnackbarAction';

function ProspectList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    let isBanAllGroupChecked = [];

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "organism",
            label: "Organisme",
            options: { filter: true, sort: true }
        },
        {
            name: "isActive",
            label: 'Bannir',
            options: { filter: true, sort: true }
        },
    ];

    const save = (id, checked) => {
        Api.post({
            route: 'community_prospect_banned',
            params: { id : id },
            data: { isBanAllGroup: checked }
        },
            (response) => {
                if (response.status === 201) {
                    dispatch(TableReload('community_prospect_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.data.isBanGroup ? 'Cet presonne a était banni.' : 'Cet presonne a était débanni.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    const getActionCheck = (id, checked) => {
        return (
            <Checkbox
                key={id + '-' + new Date().getTime()}
                checked={checked}
                onChange={() => {
                    if (inArray(id, isBanAllGroupChecked)) {
                        let index = isBanAllGroupChecked.indexOf(id);
                        if (index > -1) {
                            isBanAllGroupChecked.splice(index, 1);
                            save(id, false)
                        }
                    } else {
                        isBanAllGroupChecked.push(id);
                        save(id, true);
                    }
                }}
            />
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des membres',
            context: 'Membres',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableComponent
                        id={'community_prospect_list'}
                        title={'Membres'}
                        columns={columns}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'community_prospect_list'
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        if (data[index].isBanAllGroup === true) isBanAllGroupChecked.push(data[index].id);
                                        data[index].isActive = getActionCheck(data[index].id, data[index].isBanAllGroup);
                                    }
                                    resolve(data);
                                });
                        }}
                    />
                    <br/>
                </Grid>
            </Grid>
            <br />

        </ContentViewComponent>
    );
}

export default ProspectList;