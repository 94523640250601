import React, { Fragment } from 'react';
import Fade from "@material-ui/core/Fade";
import ButtonComponent from "../../../Component/ButtonComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import UseAnimations from "react-useanimations";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { Avatar, Box, Drawer as DrawerMUI, makeStyles } from "@material-ui/core";
import activity from "react-useanimations/lib/activity";
import PaginationComponent from '../../../Component/PaginationComponent';
import FilterPaginationComponent from '../../../Component/FilterPaginationComponent';
import Badge from '@material-ui/core/Badge';
import PeopleIcon from '@material-ui/icons/People';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ForumIcon from '@material-ui/icons/Forum';
import { useDispatch, useSelector } from "react-redux";
import { getRoutePathname } from "../../../Config/Route";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Delete, Edit } from "@material-ui/icons";
import CardCommunityFormation from "./Component/CardCommunityFormation";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import { theme } from "../../../App";
import moment from "moment";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { TableReload } from '../../../Action/TableAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';

function Community(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [communityGroup, setCommunityGroup] = React.useState([]);
    const [communityAllGroup, setCommunityAllGroup] = React.useState([]);
    const [postByCommentProspect, setpostByCommentProspect] = React.useState([]);
    const [isBanAllGroup, setIsBanAllGroup] = React.useState(false);

    const filter = [
        {
            title: 'Groupe',
            data: 'group',
            type: 'button'
        },
        {
            data: 'idLockbutton',
            type: 'button'
        },
        {
            title: 'Thématique',
            data: 'thematic',
            type: 'button'
        },
        {
            data: 'name',
            type: 'textField'
        },
    ];

    const viewPostList = (postByCommentProspect) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box style={{ color: "#5E6E82", borderBottom: "7px solid rgb(223, 223, 223)", textAlign: "left", padding: '22px 0px' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                                {postByCommentProspect.status === 'Admin' ?
                                    <Badge color="secondary" badgeContent={'A'} showZero >
                                        <Avatar className={classes.avatar}>{postByCommentProspect.firstname.charAt().toUpperCase()}{postByCommentProspect.lastname.charAt().toUpperCase()}</Avatar>
                                    </Badge> :
                                    <Avatar className={classes.avatar}>{postByCommentProspect.firstname.charAt().toUpperCase()}{postByCommentProspect.lastname.charAt().toUpperCase()}</Avatar>
                                }

                                <Box style={{ padding: '0 10px' }}>
                                    <p style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", color: "#495666" }}>{postByCommentProspect.title}</p>
                                    <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                        <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> post créer par <span className={classes.nameProspect}>{postByCommentProspect.firstname.charAt().toUpperCase()}.{postByCommentProspect.lastname}</span> le {moment(postByCommentProspect.createdAt).format("lll")}
                                    </p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "0px", display: 'flex' }} >
                                    <p style={{ margin: 0, textAlign: "left", alignContent: 'center', display: 'grid' }}>{postByCommentProspect.numComment > 99 ? '99+' : postByCommentProspect.numComment} Commentaires</p>

                                    <ButtonComponent
                                        small={true}
                                        color='#03a9f4'
                                        style={{ margin: "10px  56px", display: "block" }}
                                        className={classes.buttonPost}
                                        label={'Ouvrir la discussion'}
                                        onClick={() => { props.history.push(getRoutePathname('learner_community_post', { communityGroup: postByCommentProspect.communityGroupId, communityPost: postByCommentProspect.id })) }}
                                    />

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <br />
                </Grid>
            </Fade>
        )
    }

    const viewProspectGroupList = (communityGroup) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CardCommunityFormation
                    show={true}
                    titleButton={'Ouvrir'}
                    styleButton={'buttonV2'}
                    image={learnerHomeFormationImageBanner + communityGroup.image}
                    data={communityGroup}
                    title={communityGroup.name}
                    onClick={() => { props.history.push(getRoutePathname('learner_community_group', { communityGroup: communityGroup.id })); }}
                />
            </Grid>
        )
    }

    const viewAllGroupList = (communityAllGroup) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CardCommunityFormation
                    show={true}
                    titleButton={'Visualiser'}
                    styleButton={'buttonV1'}
                    image={learnerHomeFormationImageBanner + communityAllGroup.image}
                    data={communityAllGroup}
                    title={communityAllGroup.name}
                    onClick={() => { props.history.push(getRoutePathname('learner_community_group', { communityGroup: communityAllGroup.id })); }}
                />
            </Grid>
        )
    }
    const loadData = () => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoad(true);
        Api.get({
            route: 'learner_community_group_list'
        },
            (response) => {
                setLoad(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    setCommunityGroup(response.data.formationGroupProspect);
                    let data = response.data.formationAllGroup;
                    for (let index in data) {
                        data[index].idLockbutton = data[index].isLock === true ? 'Ouvert' : 'Fermer';
                    }
                    setCommunityAllGroup(data);
                    setpostByCommentProspect(response.data.postByCommentProspect)
                    setIsBanAllGroup(response.data.isBanAllGroup)
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        document.querySelector('#root').style.filter = isBanAllGroup ? 'blur(3px)' : 'blur(0px)';
    }, [isBanAllGroup]);
    React.useEffect(() => {
        loadData()
        setBreadcrumbs({
            currentPage: 'Forum',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });
    }, []);
    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <Box style={{ textAlign: 'center', maxWidth: 1000, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader}>
                                <Box className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <Box className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </Box>
                                    </ShadowBoxComponent>
                                </Box>
                            </Box>
                        </Fade>
                        :
                        <Box>
                            {postByCommentProspect.length != 0 &&
                                <>
                                    <br />
                                    <FilterPaginationComponent
                                        data={postByCommentProspect}
                                        title={'Discussion dans lesquelles vous avez intéragit'}
                                        icon={<ForumIcon />}
                                        pageNumber={3}
                                        viewList={viewPostList}
                                        backgroundColor={'0, 0, 0, 0'}
                                        className={classes.postByCommentProspect}
                                    />
                                </>
                            }
                            {communityGroup && communityGroup.length != 0 &&
                                <>
                                    <br />
                                    <FilterPaginationComponent
                                        data={communityGroup}
                                        title={'Groupe dont vous faîtes parti'}
                                        filter={filter}
                                        icon={<PeopleIcon />}
                                        error={'Aucun groupe'}
                                        pageNumber={6}
                                        viewList={viewProspectGroupList}
                                    />
                                </>
                            }
                            <br />
                            <br />
                            <FilterPaginationComponent
                                data={communityAllGroup}
                                title={'Trouver un groupe'}
                                filter={filter}
                                icon={<GroupAddIcon />}
                                pageNumber={6}
                                viewList={viewAllGroupList}
                                loading={load}
                                loadData={loadData}
                                error={'Aucun groupe'}
                            />
                            <br />
                            <br />
                            <Fragment>
                                <SweetAlert
                                    show={isBanAllGroup}
                                    title={'Vous avais été banni'}
                                    text={'Vous faite l\'objet d\'un bannissement pour la raison suivante :\n Mauvais comportement'}
                                    type={'warning'}
                                    confirmButtonText={'Retour au tableau de bord'}
                                    onConfirm={() => {
                                        setIsBanAllGroup(false)
                                        props.history.push(getRoutePathname('learner_home'));
                                    }}
                                />
                            </Fragment>
                        </Box>
                }
            </Box>
        </>
    );
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    titleGroup: {
        fontWeight: 600,
        textAlign: "left",
        margin: 0,
        background: theme.colorPrimary,
        padding: 12,
        fontSize: '1rem',
    },
    boxGroup: {
        background: '#E7157B4a',
        margin: 0,
        width: '100%',
    },
    boxComponent: {
        border: "1px solid #A1A2A9",
        borderRadius: 8,
        overflow: "hidden"
    },
    nameProspect: {
        color: theme.colorPrimary
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#fff"
        },
        "& .MuiPaginationItem-page:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.04)"
        },
        "& .MuiPaginationItem-outlined": {
            border: '1px solid rgba(255, 255, 255, 0.23)'
        },
        "& .MuiPaginationItem-page.Mui-selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        "& .MuiPaginationItem-page.Mui-selected.Mui-focusVisible": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        }
    },
    postByCommentProspect: {
        color: '#5E6E82',
        border: "0px !important",
        borderRadius: 8,
        overflow: "hidden",
        '& .MuiGrid-spacing-xs-3': {
            background: "transparent !important"
        },
        '& .MuiGrid-grid-lg-12': {
            background: "transparent !important"
        },
        '& .MuiButton-text': {
            color: '#5E6E82F',
        },
        '& .MuiGrid-item': {
            padding: '0px 0px'
        },
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        }
    },
    subMenu: {
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: 600,
        }
    },
}));

export default Community;
