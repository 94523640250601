import React from 'react';
import Api from "../../../Api";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import TitleComponent from '../../../Component/TitleComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import SelectComponent from '../../../Component/SelectComponent';
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Fade";
import FilterListIcon from '@material-ui/icons/FilterList';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { TableReload } from '../../../Action/TableAction';
import { inArray } from "../../../Tool/ArrayCommon";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Card from "./Component/Card";
import {hasRight} from "../../../Tool/UserTool";
import Button from "@material-ui/core/Button";


const SurveySatisfaction = (props) => {

    const [numberSatisfactions, setNumberSatisfactions] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const currentYear = new Date().getFullYear();
    const [renderTable, setRenderTable] = React.useState(false);
    const [table, setTable] = React.useState([]);
    const [loadDownload, setLoadDownload] = React.useState(false);
    const [isTall, setIsTall] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'email',
            label: 'Email du contact',
            options: { filter: true, sort: true }
        },
        {
            name: 'name',
            label: 'Nom de la formation',
            options: { filter: true, sort: true }
        },
        {
            name: 'commercial',
            label: 'nom du commercial',
            options: { filter: true, sort: true }
        },
        {
            name: 'comment',
            label: 'Commentaire',
            options: { filter: true, sort: true }
        },
        {
            name: 'estimateDuration',
            label: 'Durée estimée',
            options: { filter: true, sort: true }
        },
        {
            name: 'qualityInformation',
            label: 'Qualité des informations',
            options: { filter: true, sort: true }
        },
        {
            name: 'teachingMethod',
            label: 'Méthodes pédagogiques',
            options: { filter: true, sort: true }
        },
        {
            name: 'interactivity',
            label: 'Animations et interactivité',
            options: { filter: true, sort: true }
        },
        {
            name: "progression",
            label: "Progression",
            options: { filter: true, sort: true }
        },
        {
            name: "satisfaction",
            label: "Appréciation générale",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "crée le",
            options: { filter: true, sort: true }
        }
    ];
    const [formSearch, setFormSearch] = React.useState({
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['reporting_pedagogic_survey_satisfaction_list'] && cacheTableServer['reporting_pedagogic_survey_satisfaction_list'].organism ? cacheTableServer['reporting_pedagogic_survey_satisfaction_list'].organism : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['reporting_pedagogic_survey_satisfaction_list'] && cacheTableServer['reporting_pedagogic_survey_satisfaction_list'].year ? cacheTableServer['reporting_pedagogic_survey_satisfaction_list'].year : currentYear,
            options: {}
        },
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].commercial ? cacheTableServer['crm_prospect_list'].commercial : 'all',
            options: {}
        },
    });
    const formationGroups = [
        { index: 'med', text: 'Médecin' },
        { index: 'inf', text: 'Idel' },
        { index: 'dent', text: 'Dentiste' },
        { index: 'kin', text: 'Kinésithérpeute' },
    ];
    const getRenderSatisfaction = (value) => {
        if (value >= 0) {
            if (value >= 50) {
                return <span style={{ color: '#008000' }}>{value}%</span>;
            } else {
                return <span style={{ color: '#dc3545' }}>{value}%</span>;
            }
        } else {
            return 'N/A';
        }
    };
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        dispatch(TableReload('reporting_pedagogic_survey_satisfaction_list'))
    };

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Taux et Enquête de satisfaction',
            context: 'Reporting Pédagogique',
            description: ''
        });

        // Year
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        // Organism
        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });
            // commercial_list
            Api.get({
                route: 'crm_commercial_list'
            },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([...[{value: 'all', label: 'Tous'}], ...response.data]);
                }
            });
    }, []);

    // Get Info Satisfaction
    let datas = handlerSearch.getData();
    const getInfo = () => {

        let filters = {
            year: datas.year ?? currentYear,
            organism: (datas.organism === 'all') ? '' : datas.organism,
            commercial: (datas.commercial === 'all' || !datas.commercial) ? '' : datas.commercial,
        };
        Api.get({
            route: 'investor_dashboard_info',
            data: filters,
        },
            (response) => {
                if (response && response.data) {
                    setNumberSatisfactions(response.data.numberSatisfactions ? response.data.numberSatisfactions : {});
                }
                setReadyContent(true);
            });
    };

    React.useEffect(getInfo, [datas.organism, datas.year, datas.commercial]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                        <Grid container spacing={1}>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                <SelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercials} onChange={(val) => inputSearchChange('commercial', val)}/>
                            </Grid>
                        </Grid>
                        {(hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_REPORTING_PEDAGOGIC')) &&
                        <div style={isTall ? {height: 35, marginTop: 10} : {marginTop: 10}}>
                            <Button
                                    disabled={loadDownload}
                                    variant="contained"
                                    color="primary"
                                    style={
                                        isTall ?
                                            {float: 'right', marginLeft: 10, fontSize: 12, opacity: 0.9} :
                                            {width: 'calc(100% - 20px)', marginLeft: 10, fontSize: 12, opacity: 0.9, marginTop: 10}
                                    }                                onClick={() => {
                                    let data = handlerSearch.getData();
                                    setLoadDownload(true);
                                    Api.getDownload({
                                        route: 'reporting_pedagogic_survey_satisfaction_list', filename: 'export_survey_satisfaction.xls', data: {
                                            commercial: (data.commercial === 'all' || !data.commercial) ? '' : data.commercial,
                                            organism: (data.organism === 'all') ? '' : data.organism,
                                            year: data.year,
                                        }
                                    }, (response) => {
                                        if (response.status === 200) {
                                            setLoadDownload(false)
                                        }})
                                    }}>
                                    Télécharger au format Excel
                            </Button>
                        </div>}
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br />

            {(numberSatisfactions && numberSatisfactions.total) &&
                <div>
                    <Zoom in={true} {...{ timeout: 1500 }}>
                        <ShadowBoxComponent>

                            <IconButton
                                className={classes.iconToggle}
                                onClick={() => { setRenderTable(!renderTable) }}
                            >
                                {renderTable ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                Taux de satisfaction par Profession
                            </IconButton>
                            <TableContainer style={{ visibily: renderTable ? 'visible' : 'hidden', height: !renderTable ? 0 : 220, overflow: !renderTable && 'hidden', transition: renderTable ? 'all .4s' : 'all .4s ease .3s' }}>
                                <Slide direction="left" in={renderTable} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.thSize}>Profession</TableCell>
                                                <TableCell className={classes.th}>Janvier</TableCell>
                                                <TableCell className={classes.th}>Février</TableCell>
                                                <TableCell className={classes.th}>Mars</TableCell>
                                                <TableCell className={classes.th}>Avril</TableCell>
                                                <TableCell className={classes.th}>Mai</TableCell>
                                                <TableCell className={classes.th}>Juin</TableCell>
                                                <TableCell className={classes.th}>Juillet</TableCell>
                                                <TableCell className={classes.th}>Août</TableCell>
                                                <TableCell className={classes.th}>Septembre</TableCell>
                                                <TableCell className={classes.th}>Octobre</TableCell>
                                                <TableCell className={classes.th}>Novembre</TableCell>
                                                <TableCell className={classes.th}>Décembre</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formationGroups.map((formationGroup, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['01'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['02'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['03'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['04'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['05'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['06'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['07'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['08'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['09'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['10'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['11'][formationGroup['index']])}</TableCell>
                                                    <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['12'][formationGroup['index']])}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Slide>
                            </TableContainer>
                        </ShadowBoxComponent>
                    </Zoom>
                </div>}

            <br />
            <Card repCard={table} />
            <br />

            <TableComponent
                id={'reporting_pedagogic_survey_satisfaction_list'}
                title={'Enquête de satisfaction par Client'}
                columns={columns}
                promiseData={(resolve) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        year: datas.year ?? currentYear,
                        organism: (datas.organism === 'all') ? '' : datas.organism,
                        commercial: (datas.commercial === 'all' || !datas.commercial) ? '' : datas.commercial,
                    };
                    Api.get({
                        route: 'reporting_pedagogic_survey_satisfaction_list',
                        data: filters,
                    },
                        (response) => {
                            let data = response.data.satisfactions;
                            let repCard = response.data.moyenne;
                            setTable(repCard);
                            for (let index in data) {
                                data[index].commercial = [data[index].firstname, ' ', data[index].lastname]
                                data[index].createdAt = moment(data[index].createdAt.date).format('ll');
                                switch (data[index].estimateDuration) {
                                    case 1:
                                        data[index].estimateDuration = 'Très mauvais';
                                        break;
                                    case 2:
                                        data[index].estimateDuration = 'Passable';
                                        break;
                                    case 3:
                                        data[index].estimateDuration = 'Bien';
                                        break;
                                    case 4:
                                        data[index].estimateDuration = 'Très bien';
                                        break;
                                    case 5:
                                        data[index].estimateDuration = 'Excellent';
                                        break;
                                }
                                switch (data[index].qualityInformation) {
                                    case 1:
                                        data[index].qualityInformation = 'Très mauvais';
                                        break;
                                    case 2:
                                        data[index].qualityInformation = 'Passable';
                                        break;
                                    case 3:
                                        data[index].qualityInformation = 'Bien';
                                        break;
                                    case 4:
                                        data[index].qualityInformation = 'Très bien';
                                        break;
                                    case 5:
                                        data[index].qualityInformation = 'Excellent';
                                        break;
                                }
                                switch (data[index].progression) {
                                    case 1:
                                        data[index].progression = 'Très mauvais';
                                        break;
                                    case 2:
                                        data[index].progression = 'Passable';
                                        break;
                                    case 3:
                                        data[index].progression = 'Bien';
                                        break;
                                    case 4:
                                        data[index].progression = 'Très bien';
                                        break;
                                    case 5:
                                        data[index].progression = 'Excellent';
                                        break;
                                }
                                switch (data[index].teachingMethod) {
                                    case 1:
                                        data[index].teachingMethod = 'Très mauvais';
                                        break;
                                    case 2:
                                        data[index].teachingMethod = 'Passable';
                                        break;
                                    case 3:
                                        data[index].teachingMethod = 'Bien';
                                        break;
                                    case 4:
                                        data[index].teachingMethod = 'Très bien';
                                        break;
                                    case 5:
                                        data[index].teachingMethod = 'Excellent';
                                        break;
                                }
                                switch (data[index].interactivity) {
                                    case 1:
                                        data[index].interactivity = 'Très mauvais';
                                        break;
                                    case 2:
                                        data[index].interactivity = 'Passable';
                                        break;
                                    case 3:
                                        data[index].interactivity = 'Bien';
                                        break;
                                    case 4:
                                        data[index].interactivity = 'Très bien';
                                        break;
                                    case 5:
                                        data[index].interactivity = 'Excellent';
                                        break;
                                }
                                switch (data[index].satisfaction) {
                                    case 1:
                                        data[index].satisfaction = 'Très mauvais';
                                        break;
                                    case 2:
                                        data[index].satisfaction = 'Passable';
                                        break;
                                    case 3:
                                        data[index].satisfaction = 'Bien';
                                        break;
                                    case 4:
                                        data[index].satisfaction = 'Très bien';
                                        break;
                                    case 5:
                                        data[index].satisfaction = 'Excellent';
                                        break;
                                }
                            }
                            resolve(data);
                        });
                }}
            />
            <br />
        </ContentViewComponent>
    );
};

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    td: { width: '8%', fontSize: 12, padding: 12 },
    th: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    thSize: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    up: { fontSize: 16, verticalAlign: 'bottom', color: '#008000' },

    iconToggle: { marginRight: 10, padding: 0, fontSize: '1.1rem', color: '#5E6E82', cursor: 'default',
    "&:hover": {
        backgroundColor: '#fff',
    }, }
});

export default SurveySatisfaction;
