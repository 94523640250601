import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import { Fade, Dialog, DialogActions, DialogContent, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import Grid from "@material-ui/core/Grid";
import SelectComponent from "../../../Component/SelectComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import TitleComponent from '../../../Component/TitleComponent';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LazyBackground from '../Catalog/Component/LazyBackground';

const Register = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isTall, setIsTall] = React.useState(true);
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);
    const [activeFinanceType, setActiveFinanceType] = React.useState();
    const [formationSelected, setFormationSelected] = React.useState();
    const [form, setForm] = React.useState({
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const [registerSuccess, setRegisterSuccess] = React.useState({
        isSuccess: '',
        formationName: '',
        duration: '',
        session: '',
        financeType: ''
    });
    const handler = formHandlerInit(form, setForm);

    const loadSession = (formationId, financeType) => {
        setOptionsSessionFormation([]);
        handler.reset();
        handler.setFormLoading(true);
        setLoadingForm(true);
        Api.get({
            route: 'learner_catalog_formation_session_formation_list',
            params: { formation: formationId },
            data: { financeType: financeType.id }
        },
            (response) => {
                handler.setFormLoading(false);
                setLoadingForm(false);
                if (response && response.data) {
                    setOptionsSessionFormation(response.data);
                }
            });
    };
    const register = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoadingForm(true);

            let data = handler.getData();

            Api.post({
                route: 'learner_catalog_register',
                data: { ...data, formation: formationSelected.id },
            },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoadingForm(false);

                    if (response.status === 201) {
                        window.dataLayer.push({
                            'event': 'eec.add',
                            'items': {
                                'numero_formation': formationSelected.andpc,
                                'nom_formation': formationSelected.name,
                                'duree_formation': formationSelected.hours
                            },
                            'add_type': "réinscription"
                        })
                        setRegisterSuccess({
                            isSuccess: true,
                            formationName: formationSelected.name,
                            duration: formationSelected.hours,
                            session: response.data.session,
                            financeType: response.data.financeType
                        })
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        setBreadcrumbs({
            currentPage: 'Inscription',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_catalog'), label: 'Catalogue de formations' },
            ]
        });

        Api.get({
            route: 'learner_catalog_formation_get',
            params: { id: props.match.params.id }
        },
            (response) => {
                setTimeout(() => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setFormationSelected(response.data);
                    setActiveFinanceType(response.data.financeTypes[0].id);
                    loadSession(response.data.id, response.data.financeTypes[0]);
                    if (response.data.description) {
                        response.data.description = response.data.description.replace(/\n/g, "<br/>")
                    }
                    if (response.data.objectives) {
                        response.data.objectives = response.data.objectives.replace(/\n/g, "<br/>")
                    }
                }, 1000);
            });
    }, []);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{ textAlign: 'center', maxWidth: 1240, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            <div style={{ padding: 15, width: isSmallView ? '90%' : '80%', margin: 'auto' }}>
                                <TitleComponent title={formationSelected ? formationSelected.name : ''} />
                                <div className={classes.container}>
                                    {formationSelected && <LazyBackground classe={classes.circle} src={learnerHomeFormationImageBanner + formationSelected.image} />}
                                    {formationSelected &&
                                        <div style={{ textAlign: 'justify' }}>
                                            {formationSelected.description ?
                                                <p className={classes.dialogText}>
                                                    <span className={classes.boxTitle}>Description : </span><br />
                                                    <span dangerouslySetInnerHTML={{ __html: formationSelected.description }} />
                                                </p> : <></>}
                                            {formationSelected.objectives ?
                                                <p className={classes.dialogText}>
                                                    <span className={classes.boxTitle}>Objectifs : </span><br />
                                                    <span dangerouslySetInnerHTML={{ __html: formationSelected.objectives }} />
                                                </p> : <></>}
                                            {!!formationSelected.hours && <p className={classes.dialogText} style={{ textAlign: 'center' }}><span className={classes.boxTitle} >Temps de formation</span><br />{formationSelected.hours} heures</p>}
                                        </div>}
                                    {(activeFinanceType === '1' && formationSelected.code) &&
                                        <p className={classes.dialogText} style={{ textAlign: 'center' }}>
                                            <span className={classes.boxTitle}>Code ANDPC</span><br />
                                            <span>{formationSelected.code}</span>
                                            <Tooltip title="Copier le code">
                                                <IconButton aria-label="copy" size="small"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(formationSelected.code);
                                                        dispatch(
                                                            SnackbarOpen({
                                                                text: 'Code copié dans le presse papier',
                                                                variant: 'success',
                                                            })
                                                        );
                                                    }}>
                                                    <FileCopyIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </p>
                                    }

                                    <div className={classes.boxForm} style={{ width: '100%', background: '#fff' }}>
                                        <p className={classes.boxTitle}>Choisir un mode de financement :</p>
                                        <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            {formationSelected && formationSelected.financeTypes.map((financeType) =>
                                                <ShadowBoxComponent
                                                    key={financeType.id}
                                                    className={financeType.id === activeFinanceType ? classes.buttonFinanceActive : classes.buttonFinance}
                                                    style={{ width: isTall ? 100 : 70, padding: !isTall && 6 }}
                                                    onClick={() => {
                                                        setActiveFinanceType(financeType.id);
                                                        loadSession(formationSelected.id, financeType);
                                                    }}>
                                                    <span>{financeType.label}</span>
                                                </ShadowBoxComponent>
                                            )}
                                        </div>
                                        <p className={classes.boxTitle}>Choisir une session :</p>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation} nullable={true} />
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <div style={{ width: '95%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <ButtonComponent className={classes.button} color={'#5E6E82'} label={'Revenir au catalogue'} onClick={() => { props.history.push(getRoutePathname('learner_catalog')) }} disabled={loading} />
                                            <ButtonComponent className={classes.button} color={theme.colorPrimary} label={'Je m\'inscris'} onClick={register} disabled={loadingForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Dialog
                                open={registerSuccess.isSuccess}
                                onClose={() => {
                                    setRegisterSuccess({
                                        isSuccess: false,
                                        formationName: '',
                                        duration: '',
                                        session: ''
                                    });
                                    props.history.push(getRoutePathname('learner_catalog'));
                                }}>
                                <DialogContent>
                                    <TitleComponent title={'Félicitation pour votre inscription'} />
                                    <p>Votre inscription à la formation <span className={classes.boxTitle}>{registerSuccess.formationName}</span> d'une durée de {registerSuccess.duration} heures à été validé.</p>
                                    {registerSuccess.financeType !== 4 && <p>Cette formation sera disponible {registerSuccess.session} sur votre page principale.</p>}
                                    <p className={classes.boxTitle}>Santeformapro vous souhaite une bonne formation</p>
                                </DialogContent>
                                <DialogActions>
                                    <ButtonComponent className={classes.button} color={'#5E6E82'} label={'Revenir au catalogue'}
                                        onClick={() => {
                                            setRegisterSuccess({
                                                isSuccess: false,
                                                formationName: '',
                                                duration: '',
                                                session: '',
                                                financeType: ''
                                            });
                                            props.history.push(getRoutePathname('learner_catalog'));
                                        }} />
                                    <ButtonComponent className={classes.button} label={'Aller au tableau de bord'} onClick={() => { props.history.push(getRoutePathname('learner_home')) }} />
                                </DialogActions>
                            </Dialog>
                        </>
                }
            </div>
        </>
    );
};

const useStyles = (theme) => makeStyles(({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        textAlign: 'left',
        background: '#4f93a5',
        width: 'max-content',
        padding: '7px 20px'
    },
    boxTitle: {
        fontSize: 13,
        fontWeight: 700,
        color: '#5E6E82',
        textAlign: 'left'
    },
    dialogText: {
        color: '#5E6E82',
        fontSize: 12,
        width: '100%',
        margin: 8
    },
    buttonFinance: {
        margin: '0px 10px',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.colorPrimary,
            color: '#FFFFFF'
        }
    },
    buttonFinanceActive: {
        margin: '0px 10px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF'
    },
    dialogPaper: {
        borderRadius: 0,
        width: '100%',
        height: '100%'
    },
    boxForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'solid 1px #cccccc80',
        margin: 'auto',
        paddingBottom: 10,
        fontSize: 13,
    },
    container: {
        width: '100%',
        padding: 10,
        boxSizing: 'border-box',
    },
    circle: {
        width: 180,
        height: 180,
        borderRadius: 0,
        float: 'left',
        shapeOutside: 'margin-box',
        marginRight: 15,
        marginBottom: 7
    },
    button: {
        fontSize: 10,
        margin: 3
    }
}));

export default Register;