import React from 'react';

// Layouts
import MainLayout from "../Layout/MainLayout/MainLayout";
import AuthenticationLayout from "../Layout/AuthenticationLayout/AuthenticationLayout";
import LearnerLayout from "../Layout/LearnerLayout/LearnerLayout";
import LearnerLayoutV2 from "../Layout/LearnerLayoutV2/LearnerLayoutV2";

// Views
import InvestorDashboard from "../View/Investor/Dashboard/Dashboard";

import ReportingMarketingDashboard from "../View/ReportingMarketing/Dashboard/Dashboard";
import ReportingMarketingCampaign from "../View/ReportingMarketing/Campaign/Campaign";

import ReportingPedagogicSurveySatisfaction from "../View/ReportingPedagogic/SurveySatisfaction/SurveySatisfaction";
import ReportingPedagogicEppList from "../View/ReportingPedagogic/Epp/EppList";
import ReportingPedagogicEpp from "../View/ReportingPedagogic/Epp/Epp";

import CRMReporting from "../View/CRM/Reporting/Reporting";
import CRMDashboard from "../View/CRM/Dashboard/Dashboard";
import CRMProspectList from "../View/CRM/ProspectList/ProspectList";
import CRMProspect from "../View/CRM/Prospect/Prospect";
import CRMCommercialTask from "../View/CRM/CommercialTask/CommercialTask";
import CRMHotlineList from "../View/CRM/HotlineList/HotlineList";
import CRMRelaunchList from "../View/CRM/RelaunchList/RelaunchList";

import AdministratorUserList from "../View/Administrator/UserList/UserList";
import AdministratorUser from "../View/Administrator/User/User";
import AdministratorOrganismList from "../View/Administrator/OrganismList/OrganismList";
import AdministratorOrganism from "../View/Administrator/Organism/Organism";
import AdministratorRoleList from "../View/Administrator/RoleList/RoleList";
import AdministratorRole from "../View/Administrator/Role/Role";
import AdministratorPoleList from "../View/Administrator/PoleList/PoleList";
import AdministratorPole from "../View/Administrator/Pole/Pole";
import AdministratorSessionList from "../View/Administrator/SessionList/SessionList";
import AdministratorSession from "../View/Administrator/Session/Session";
import AdministratorFormationGroupPropertyList from "../View/Administrator/FormationGroupPropertyList/FormationGroupPropertyList";
import AdministratorFormationGroupProperty from "../View/Administrator/FormationGroupProperty/FormationGroupProperty";
import AdministratorUserHotlineTypeList from "../View/Administrator/HotlineTypeList/UserHotlineTypeList";
import AdministratorProspectHotlineTypeList from "../View/Administrator/HotlineTypeList/ProspectHotlineTypeList";

import ContentManagerFormationList from "../View/ContentManager/FormationList/FormationList";
import ContentManagerFormation from "../View/ContentManager/Formation/Formation";
import ContentManagerQuestion from "../View/ContentManager/Question/Question";
import ContentManagerChapterList from "../View/ContentManager/ChapterList/ChapterList";
import ContentManagerChapter from "../View/ContentManager/Chapter/Chapter";
import ContentManagerCourseList from "../View/ContentManager/CourseList/CourseList";
import ContentManagerCourse from "../View/ContentManager/Course/Course";
import ContentManagerResourceList from "../View/ContentManager/ResourceList/ResourceList";
import ContentManagerResource from "../View/ContentManager/Resource/Resource";
import ContentManagerResourceTypeList from '../View/ContentManager/ResourceTypeList/ResourceTypeList';
import ContentManagerResourceGroupList from '../View/ContentManager/ResourceGroupList/ResourceGroupList';
import ContentManagerVirtualClassroomList from "../View/ContentManager/VirtualClassroomList/VirtualClassroomList";
import ContentManagerVirtualClassroom from "../View/ContentManager/VirtualClassroom/VirtualClassroom";

import MarketingManagerthematicList from "../View/MarketingManager/ThematicList/ThematicList";
import MarketingManagerthematic from "../View/MarketingManager/Thematic/Thematic";
import MarketingManagerLibraryResourceList from "../View/MarketingManager/LibraryResourceList/LibraryResourceList";
import MarketingManagerLibraryResourceTypeList from "../View/MarketingManager/LibraryResourceTypeList/LibraryResourceTypeList";
import MarketingManagerFeaturedResource from "../View/MarketingManager/FeaturedResource/FeaturedResource";

import FinancialManagementBillingList from "../View/FinancialManagement/BillingList/BillingList";
import FinancialManagementBilling from "../View/FinancialManagement/Billing/Billing";
import FinancialManagementBillingFIFPLList from "../View/FinancialManagement/BillingFIFPLList/BillingFIFPLList";
import FinancialManagementBillingFIFPL from "../View/FinancialManagement/BillingFIFPL/BillingFIFPL";
import FinancialManagementBillingCPFList from "../View/FinancialManagement/BillingCPFList/BillingCPFLList";
import FinancialManagementBillingCPF from "../View/FinancialManagement/BillingCPF/BillingCPF";
import FinancialManagementPaymentFollowList from "../View/FinancialManagement/PaymentFollowList/PaymentFollowList";
import FinancialManagementRelaunchList from "../View/FinancialManagement/RelaunchList/RelaunchList";
import FinancialManagementStatistics from "../View/FinancialManagement/Statistics/Statistics";

import AboutUpdate from "../View/About/Update";
import AboutRoadmapSI from "../View/About/RoadmapSI";
import AboutRoadmapPed from "../View/About/RoadmapPed";

import FormerVirtualClassroomList from "../View/Former/FormerVirtualClassroomList/FormerVirtualClassroomList";
import FormerVirtualClassroom from "../View/Former/FormerVirtualClassroom/FormerVirtualClassroom";

import CommunityProspectList from "../View/Community/ProspectList/ProspectList";
import CommunityGroupPrivateList from "../View/Community/GroupPrivateList/GroupPrivateList";
import CommunityGroupPrivate from "../View/Community/GroupPrivate/GroupPrivate";
import CommunityGroupPrivatePost from "../View/Community/GroupPrivate/GroupPrivatePost/GroupPrivatePost";
import CommunityGroupFormationList from "../View/Community/GroupFormationList/GroupFormationList";
import CommunityGroupFormationPost from "../View/Community/GroupFormation/GroupFormationPost/GroupFormationPost";
import CommunityGroupFormation from "../View/Community/GroupFormation/GroupFormation";

import SupportProspectHotlineList from "../View/Support/ProspectHotlineList/ProspectHotlineList";
import SupportUserHotlineList from "../View/Support/UserHotlineList/UserHotlineList";
import SupportHotlineDashboard from "../View/Support/Dashboard/Dashboard";

import LearnerHome from "../View/LearnerV2/Home/Home";
import LearnerCommunity from "../View/LearnerV2/Community/Community";
import LearnerCommunityGroup from "../View/LearnerV2/Community/CommunityGroup";
import LearnerCommunityPost from "../View/LearnerV2/Community/CommunityPost";
import LearnerCatalog from "../View/LearnerV2/Catalog/Catalog";
import LearnerRegister from "../View/LearnerV2/Register/Register";
import LearnerResource from "../View/LearnerV2/Resource/Resource";
import LearnerLibraryResource from "../View/LearnerV2/LibraryResource/LibraryResource";
import LearnerMyInfo from "../View/LearnerV2/MyInfo/MyInfo";
import LearnerDocument from "../View/LearnerV2/Document/Document";
import LearnerRegistration from "../View/LearnerV2/Registration/Registration";
import LearnerRegistrationCourse from "../View/LearnerV2/RegistrationCourse/RegistrationCourse";
import LearnerRegistrationSurveySatisfaction from "../View/LearnerV2/RegistrationSurveySatisfaction/RegistrationSurveySatisfaction";
import LearnerRegistrationSurveyPrePost from "../View/LearnerV2/RegistrationSurveyPrePost/RegistrationSurveyPrePost";
import LearnerRegistrationSurveyEpp from "../View/LearnerV2/RegistrationSurveyEpp/RegistrationSurveyEpp";
import LearnerRegistrationVirtualClassroom from "../View/LearnerV2/RegistrationVirtualClassroom/RegistrationVirtualClassroom";
import LearnerRegistrationVirtualClassroomReplay from "../View/LearnerV2/RegistrationVirtualClassroomReplay/RegistrationVirtualClassroomReplay";

// import LearnerHome from "../View/Learner/Home/Home";
// import LearnerMyInfo from "../View/Learner/MyInfo/MyInfo";
// import LearnerCatalog from "../View/Learner/Catalog/Catalog";
// import LearnerDocument from "../View/Learner/Document/Document";
// import LearnerRegistration from "../View/Learner/Registration/Registration";
// import LearnerRegistrationCourse from "../View/Learner/RegistrationCourse/RegistrationCourse";
// import LearnerRegistrationSurveySatisfaction from "../View/Learner/RegistrationSurveySatisfaction/RegistrationSurveySatisfaction";
// import LearnerRegistrationSurveyPrePost from "../View/Learner/RegistrationSurveyPrePost/RegistrationSurveyPrePost";
// import LearnerRegistrationSurveyEpp from "../View/Learner/RegistrationSurveyEpp/RegistrationSurveyEpp";
// import LearnerRegistrationVirtualClassroom from "../View/Learner/RegistrationVirtualClassroom/RegistrationVirtualClassroom";
// import LearnerRegistrationVirtualClassroomReplay from "../View/Learner/RegistrationVirtualClassroomReplay/RegistrationVirtualClassroomReplay";

import EnrollmentRegister from "../View/Enrollment/Register/Register";

import Maintenance from "../View/Maintenance/Maintenance";
import Login from "../View/Authentication/Login/Login";
import Register from "../View/Authentication/Register/Register";


const routing = {
    // USER
    investor_dashboard: {path: '/investor/dashboard', view: InvestorDashboard, layout: MainLayout, roles: ['ROLE_INVESTOR']},

    reporting_marketing_dashboard: {path: '/reporting-marketing/dashboard', view: ReportingMarketingDashboard, layout: MainLayout, roles: ['ROLE_REPORTING_MARKETING']},
    reporting_marketing_campaign: {path: '/reporting-marketing/campaign', view: ReportingMarketingCampaign, layout: MainLayout, roles: ['ROLE_REPORTING_MARKETING']},

    reporting_pedagogic_survey_satisfaction: {path: '/reporting-pedagogic/survey-satisfaction', view: ReportingPedagogicSurveySatisfaction, layout: MainLayout, roles: ['ROLE_REPORTING_PEDAGOGIC']},
    reporting_pedagogic_epp_list: {path: '/reporting-pedagogic/epp/list', view: ReportingPedagogicEppList, layout: MainLayout, roles: ['ROLE_REPORTING_PEDAGOGIC']},
    reporting_pedagogic_epp: {path: '/reporting-pedagogic/epp/:id?', match: /\/reporting-pedagogic\/epp.*/, view: ReportingPedagogicEpp, layout: MainLayout, roles: ['ROLE_REPORTING_PEDAGOGIC']},

    crm_reporting: {path: '/crm/reporting', view: CRMReporting, layout: MainLayout, roles: ['ROLE_CRM_SUPERVISOR']},
    crm_prospect_list: {path: '/crm/prospect/list', view: CRMProspectList, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN']},
    crm_prospect: {path: '/crm/prospect/:id?', match: /\/crm\/prospect.*/, view: CRMProspect, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN']},
    crm_commercial_task: {path: '/crm/commercial-task', view: CRMCommercialTask, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN']},
    crm_dashboard: {path: '/crm/dashboard', view: CRMDashboard, layout: MainLayout, roles: ['ROLE_CRM']},
    crm_hotline: {path: '/crm/hotline', view: CRMHotlineList, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN']},
    crm_relaunch_list: {path: '/crm/relaunch/list', view: CRMRelaunchList, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN']},

    administrator_user_list: {path: '/administrator/user/list', view: AdministratorUserList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_user: {path: '/administrator/user/:id?', match: /\/administrator\/user.*/, view: AdministratorUser, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_organism_list: {path: '/administrator/organism/list', view: AdministratorOrganismList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_organism: {path: '/administrator/organism/:id?', match: /\/administrator\/organism.*/, view: AdministratorOrganism, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_role_list: {path: '/administrator/role/list', view: AdministratorRoleList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_role: {path: '/administrator/role/:id?', match: /\/administrator\/role.*/, view: AdministratorRole, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_pole_list: {path: '/administrator/pole/list', view: AdministratorPoleList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_pole: {path: '/administrator/pole/:id?', match: /\/administrator\/pole.*/, view: AdministratorPole, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_session_list: {path: '/administrator/session/list', view: AdministratorSessionList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_SESSIONS']},
    administrator_session: {path: '/administrator/session/:id?', match: /\/administrator\/session.*/, view: AdministratorSession, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_SESSIONS']},
    administrator_user_hotline_type_list: {path: '/administrator/user-hotline-type/list', view: AdministratorUserHotlineTypeList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_prospect_hotline_type_list: {path: '/administrator/prospect-hotline-type/list', view: AdministratorProspectHotlineTypeList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},


    administrator_formation_group_property_list: {path: '/administrator/formation-group-property/list', view: AdministratorFormationGroupPropertyList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},
    administrator_formation_group_property: {path: '/administrator/formation-group-property/:id?', match: /\/administrator\/formation-group-property.*/, view: AdministratorFormationGroupProperty, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR']},

    content_manager_formation_list: {path: '/content-manager/formation/list', view: ContentManagerFormationList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION']},
    content_manager_formation_question: {path: '/content-manager/formation/:formationId?/question/:id?', match: /\/content-manager\/formation-question.*/, view: ContentManagerQuestion, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION']},
    content_manager_formation: {path: '/content-manager/formation/:id?', match: /\/content-manager\/formation.*/, view: ContentManagerFormation, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION']},
    content_manager_chapter_list: {path: '/content-manager/chapter/list', view: ContentManagerChapterList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER']},
    content_manager_chapter: {path: '/content-manager/chapter/:id?', match: /\/content-manager\/chapter.*/, view: ContentManagerChapter, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER']},
    content_manager_course_list: {path: '/content-manager/course/list', view: ContentManagerCourseList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER']},
    content_manager_course: {path: '/content-manager/course/:id?', match: /\/content-manager\/course.*/, view: ContentManagerCourse, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER']},
    content_manager_resource_list: {path: '/content-manager/resource/list', view: ContentManagerResourceList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER']},

    content_manager_resource_type_list: {path: '/content-manager/resource/type/list', view: ContentManagerResourceTypeList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER']},
    content_manager_resource_type: {path: '/content-manager/resource/type/:id?', match: /\/content-manager\/resource\/type.*/, view: ContentManagerResourceTypeList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER']},

    content_manager_resource_group_list: {path: '/content-manager/resource/group/list', view: ContentManagerResourceGroupList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION']},
    content_manager_resource_group: {path: '/content-manager/resource/group/:id?', match: /\/content-manager\/resource\/group.*/, view: ContentManagerResourceGroupList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION']},

    content_manager_resource: {path: '/content-manager/resource/:id?', match: /\/content-manager\/resource.*/, view: ContentManagerResource, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION']},
    content_manager_virtual_classroom_list: {path: '/content-manager/virtual-classroom/list', view: ContentManagerVirtualClassroomList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_VIRTUAL_CLASSROOM_MANAGER']},
    content_manager_virtual_classroom: {path: '/content-manager/virtual-classroom/:id?', match: /\/content-manager\/virtual-classroom.*/, view: ContentManagerVirtualClassroom, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_VIRTUAL_CLASSROOM_MANAGER']},

    //marketing manager
    marketing_manager_thematic_list: {path: '/marketing-manager/thematic/list', view: MarketingManagerthematicList, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER']},
    marketing_manager_thematic: {path: '/marketing-manager/thematic/:id?', match: /\/marketing-manager\/thematic.*/, view: MarketingManagerthematic, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER']},

    marketing_manager_library_resource_list: {path: '/marketing-manager/library-resource/list', view: MarketingManagerLibraryResourceList, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER']},
    marketing_manager_library_resource_type_list: {path: '/marketing-manager/library-resource/type/list', view: MarketingManagerLibraryResourceTypeList, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER']},
    marketing_manager_featured_resource: {path: '/marketing-manager/featured-resource', view: MarketingManagerFeaturedResource, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER']},

    financial_management_billing_list: {path: '/financial-management/billing/list', view: FinancialManagementBillingList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_billing_fifpl_list: {path: '/financial-management/billing-fifpl/list', view: FinancialManagementBillingFIFPLList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_billing_cpf_list: {path: '/financial-management/billing-cpf/list', view: FinancialManagementBillingCPFList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_billing: {path: '/financial-management/billing/:id?', match: /\/financial-management\/billing.*/, view: FinancialManagementBilling, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_billing_fifpl: {path: '/financial-management/billing-fifpl/:id?', match: /\/financial-management\/billing-fifpl.*/, view: FinancialManagementBillingFIFPL, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_billing_cpf: {path: '/financial-management/billing-cpf/:id?', match: /\/financial-management\/billing-cpf.*/, view: FinancialManagementBillingCPF, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_payment_follow: {path: '/financial-management/payment-follow/list', view: FinancialManagementPaymentFollowList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_relaunch_list: {path: '/financial-management/relaunch/list', view: FinancialManagementRelaunchList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},
    financial_management_statistics: {path: '/financial-management/statistics', view: FinancialManagementStatistics, layout: MainLayout, roles: ['ROLE_ACCOUNTANT']},

    community_prospect_list: {path: '/community/prospect/list', view: CommunityProspectList, layout: MainLayout, roles: ['ROLE_COMMUNITY']},
    community_group_private_list: {path: '/community/group-private/list', view: CommunityGroupPrivateList, layout: MainLayout, roles: ['ROLE_COMMUNITY']},
    community_group_private_post: {path: '/community/group-private/:groupPrivateId?/post/:id?', match: /\/community\/group-private.*/, view: CommunityGroupPrivatePost, layout: MainLayout, roles: ['ROLE_COMMUNITY']},
    community_group_private: {path: '/community/group-private/:id?', match: /\/community\/group-private.*/, view: CommunityGroupPrivate, layout: MainLayout, roles: ['ROLE_COMMUNITY']},
    community_group_formation_list: {path: '/community/group-formation/list', view: CommunityGroupFormationList, layout: MainLayout, roles: ['ROLE_COMMUNITY']},
    community_group_formation_post: {path: '/community/group-formation/:groupFormationId?/post/:id?', match: /\/community\/group-formation.*/, view: CommunityGroupFormationPost, layout: MainLayout, roles: ['ROLE_COMMUNITY']},
    community_group_formation: {path: '/community/group-formation/:id?', match: /\/community\/group-formation.*/, view: CommunityGroupFormation, layout: MainLayout, roles: ['ROLE_COMMUNITY']},
    
    support_prospect_hotline: {path: '/support/prospect-hotline', view: SupportProspectHotlineList, layout: MainLayout, roles: ['ROLE_SUPPORT']},
    support_user_hotline: {path: '/support/user-hotline', view: SupportUserHotlineList, layout: MainLayout, roles: ['ROLE_SUPPORT']},
    support_hotline_dashboard: {path: '/support/hotline-dashboard', view: SupportHotlineDashboard, layout: MainLayout, roles: ['ROLE_SUPPORT']},


    former_virtual_classroom_list: {path: '/former/virtual-classroom/list', view: FormerVirtualClassroomList, layout: MainLayout, roles: ['ROLE_FORMER']},
    former_virtual_classroom: {path: '/former/virtual-classroom/:id?', match: /\/former\/virtual-classroom.*/, view: FormerVirtualClassroom, layout: MainLayout, roles: ['ROLE_FORMER']},

    about_update: {path: '/about/update', view: AboutUpdate, layout: MainLayout, roles: ['ROLE_USER']},
    about_roadmap_si: {path: '/about/roadmap-si', view: AboutRoadmapSI, layout: MainLayout, roles: ['ROLE_USER']},
    about_roadmap_ped: {path: '/about/roadmap-ped', view: AboutRoadmapPed, layout: MainLayout, roles: ['ROLE_USER']},

    // LEARNER
    learner_home: {path: '/learner/home', view: LearnerHome, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_registration_course: {path: '/learner/registration/:registration/course/:course?', match: /\/learner\/registration\/\d*\/course\/\d*/, view: LearnerRegistrationCourse, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_registration_survey_satisfaction: {path: '/learner/registration/:registration/survey-satisfaction', match: /\/learner\/registration\/\d*\/survey-satisfaction/, view: LearnerRegistrationSurveySatisfaction, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_registration_survey_pre_post: {path: '/learner/registration/:registration/survey-pre-post/:type?', match: /\/learner\/registration\/\d*\/survey-pre-post\/\w*/, view: LearnerRegistrationSurveyPrePost, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_registration_survey_epp: {path: '/learner/registration/:registration/survey-epp/:type?', match: /\/learner\/registration\/\d*\/survey-epp\/\w*/, view: LearnerRegistrationSurveyEpp, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_registration_virtual_classroom: {path: '/learner/registration/:registration/virtual-classroom/:virtualClassroom?', match: /\/learner\/registration\/\d*\/virtual-classroom\/\d*/, view: LearnerRegistrationVirtualClassroom, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_registration_virtual_classroom_replay: {path: '/learner/registration/:registration/virtual-classroom-replay/:virtualClassroom?', match: /\/learner\/registration\/\d*\/virtual-classroom-replay\/\d*/, view: LearnerRegistrationVirtualClassroomReplay, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_resource: {path: '/learner/resource/:id?', match: /\/learner\/resource.*/, view: LearnerResource, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_library_resource: {path: '/learner/library-resource', view: LearnerLibraryResource, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_catalog: {path: '/learner/catalog', view: LearnerCatalog, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_register: {path: '/learner/register/:id?', match: /\/learner\/register.*/, view: LearnerRegister, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_community: {path: '/learner/community', view: LearnerCommunity, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_community_group: {path: '/learner/community-group/:communityGroup?', match: /\/learner\/community-group\/\d*/, view: LearnerCommunityGroup, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_community_post: {path: '/learner/group/:communityGroup/post/:communityPost?', match: /\/learner\/community-group\/\d*\/post\/\w*/, view: LearnerCommunityPost, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_my_info: {path: '/learner/my-info', view: LearnerMyInfo, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_document: {path: '/learner/document', view: LearnerDocument, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},
    learner_registration: {path: '/learner/registration/:id?', match: /\/learner\/registration\/\d*$/, view: LearnerRegistration, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER']},

    // ENROLLMENT
    enrollment_register: {path: '/enrollment/register', match: /\/enrollment\/register/, view: EnrollmentRegister, layout: AuthenticationLayout, roles: []},

    // ANONYMOUS
    authentication_admin: {path: '/authentication/admin/:code?', view: Login, layout: AuthenticationLayout, roles: []},
    authentication_login: {path: '/authentication/login/:code?', view: Login, layout: AuthenticationLayout, roles: []},
    authentication_register: {path: '/authentication/register', view: Register, layout: AuthenticationLayout, roles: []},
    maintenance: {path: '/maintenance', view: Maintenance, layout: AuthenticationLayout, roles: []},
};

let routes = {};
let defaultPath = '';

// Security
export const updateRouting = (authenticationReducer, loginPage) => {
    let routesTmp = {};

    if (authenticationReducer && Object.entries(authenticationReducer).length > 0) { // Login
        for (let index in routing) {
            if (authenticationReducer.roles.includes('ROLE_SUPER_ADMIN') || authenticationReducer.roles.filter(value => routing[index].roles.includes(value)).length > 0) {
                routesTmp[index] = routing[index];
            }
        }

        delete routesTmp.authentication_login;
        delete routesTmp.authentication_admin;
        delete routesTmp.authentication_register;
        delete routesTmp.enrollment_register;
        delete routesTmp.maintenance;

        if (authenticationReducer.defaultRoute && routesTmp[authenticationReducer.defaultRoute]) {
            defaultPath = routesTmp[authenticationReducer.defaultRoute].path;
        } else {
            if (Object.keys(routesTmp)[0]) {
                defaultPath = routesTmp[Object.keys(routesTmp)[0]].path
            } else { // Logout
                routesTmp.authentication_login = routing['authentication_login'];
                routesTmp.authentication_admin = routing['authentication_admin'];
                routesTmp.authentication_register = routing['authentication_register'];
                routesTmp.enrollment_register = routing['enrollment_register'];
                routesTmp.maintenance = routing['maintenance'];
                defaultPath = '/authentication/login';
            }
        }
    } else { // Logout
        routesTmp.authentication_login = routing['authentication_login'];
        routesTmp.authentication_admin = routing['authentication_admin'];
        routesTmp.authentication_register = routing['authentication_register'];
        routesTmp.enrollment_register = routing['enrollment_register'];

        routesTmp.maintenance = routing['maintenance'];
        defaultPath = loginPage.page === 'admin' ? '/authentication/admin' : '/authentication/login';
    }

    routes = routesTmp;
};

export const getRoutes = () => {
    return routes;
};

// Dependency Security
export const getRoute = (id) => {
    let routes = getRoutes();
    let route = routes[id];
    return route ? {...route, id: id} : {};
};

export const getPathDefault = () => {
    return defaultPath;
};

export const getRoutePathname = (id, params = []) => {
    let route = getRoute(id);
    let path = route.path ?? '/';

    for (let index in params) {
        let regex = new RegExp('/:' + index + '[?]{0,1}', 'gi')
        path = path.replace(regex, params[index] ? '/' + params[index] : '');
    }

    return path;
};

export const getRouteByPathname = (pathname) => {
    let routes = getRoutes();

    for (let index in routes) {
        if (routes[index].match && routes[index].match.test(pathname)) {
            return {...routes[index], id: index};
        } else if (routes[index].path === pathname) {
            return {...routes[index], id: index};
        }
    }

    return {};
};

export const getRouterLayout = () => {
    let routes = getRoutes();
    let routerLayout = {};

    for (let index in routes) {
        if (!routerLayout[routes[index].layout.name]) {
            routerLayout[routes[index].layout.name] = [];
        }
        routerLayout[routes[index].layout.name].push(routes[index])
    }

    return routerLayout;
};
